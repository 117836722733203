import { useService } from '@price-for-profit/service-provider';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { QUERYKEY_SUBMISSION_HISTORY_AFTER_DATE } from 'shared/constants';

export function useGetSubmissionHistoryAfterDate(state: ServerSideState) {
    const { submissionHistoryService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const { data, isError, isLoading, isRefetching } = useQuery({
        queryKey: [QUERYKEY_SUBMISSION_HISTORY_AFTER_DATE + state.filterModel],
        queryFn: () => submissionHistoryService.getSubmissionHistoryAfterDate(180, state),
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Failed to fetch historical information.', { variant: 'error' });
        }
    }, [isError, enqueueSnackbar]);

    return { data, isLoading, isRefetching };
}
