import { forwardRef, useImperativeHandle, useState } from 'react';
import { IconButton, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridFilterItem, GridLogicOperator } from '@mui/x-data-grid-premium';
import { QuickFilterRef } from 'shared/components';
import { DropdownOption } from 'shared/types';

interface QuickMultiFilterProps {
    label: string;
    column: string;
    apiRef: React.MutableRefObject<GridApiPremium>;
    options: DropdownOption[];
}

export const WarningsQuickMultiFilter = forwardRef<QuickFilterRef, QuickMultiFilterProps>(
    ({ label, column, apiRef, options }, ref) => {
        const [value, setValue] = useState<string[]>([]);
        const [filterId, setFilterId] = useState<string | undefined>(undefined);

        const onChange = (event: SelectChangeEvent<any>) => {
            const currentFilters = apiRef.current.state?.filter?.filterModel;
            event.preventDefault();

            const {
                target: { value },
            } = event;

            setValue(value);
            const currentId = filterId || crypto.randomUUID();
            setFilterId(currentId);

            let filterItems = (value as string[]).map<GridFilterItem>((item: string) => ({
                field: column,
                value: item,
                id: currentId,
                operator: 'contains',
            }));

            if (currentFilters) {
                const otherFilters = currentFilters.items.filter(item => item.field !== column);
                filterItems = filterItems.concat(otherFilters);
            }

            apiRef.current.setFilterModel({ items: filterItems, logicOperator: GridLogicOperator.Or });
        };

        const resetFilter = () => {
            const filterItem: GridFilterItem = { id: filterId, field: column, value: value, operator: 'contains' }; //UNKNOWN
            apiRef.current.deleteFilterItem(filterItem);
            setValue([]);
            setFilterId(undefined);
        };

        const refResetFilter = () => {
            setValue([]);
            setFilterId(undefined);
        };

        useImperativeHandle(ref, () => ({
            refResetFilter: () => {
                refResetFilter();
            },
        }));

        return (
            <TextField
                select
                variant='outlined'
                label={label}
                size='small'
                sx={{
                    width: '200px',
                }}
                SelectProps={{
                    multiple: true,
                    value,
                    onChange,
                    endAdornment: (
                        <IconButton sx={{ display: value.length ? '' : 'none' }} onClick={resetFilter}>
                            <Clear />
                        </IconButton>
                    ),
                }}
            >
                {options.map(option => (
                    <MenuItem key={option.uniqueId} value={option.value}>
                        {option.value}
                    </MenuItem>
                ))}
            </TextField>
        );
    }
);
