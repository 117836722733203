import { Clear, Download } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { IconButton, LinearProgress, MenuItem, Paper, Portal, Stack, TextField } from '@mui/material';
import {
    DataGridPremium,
    GridColumnVisibilityModel,
    GridFilterModel,
    GridSlots,
    gridFilterModelSelector,
} from '@mui/x-data-grid-premium';
import { PAGE_SIZE_OPTIONS, useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { useService } from '@price-for-profit/service-provider';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CurrencyToggle, QuickFilter, QuickFilterRef, QuickMultiFilter } from 'shared/components';
import { WarningsQuickMultiFilter } from 'shared/components/warnings-quick-filter/warnings-quick-multi-filter';
import { QUERYKEY_PRICE } from 'shared/constants';
import { useSavePriceRows } from 'shared/hooks';
import { usePriceExcelExport } from 'shared/mutations';
import { useGetExchangeRates, useGetSubmissionHistoryMetadata, useGetWarningsFilter } from 'shared/queries';
import { DropdownOption, FormTypeFilter, PriceMetrics, PriceSummary, ProductTypeFilter } from 'shared/types';
import {
    formatSlashDateWithTime,
    getPriceMasterFilterModelInitialState,
    getPriceMasterPinnedColumnInitialState,
    getPriceSummaryColumnVisibilityInitialState,
} from 'shared/utility';
import { calculatePriceChangeCount } from 'shared/utility/calc-change-count';
import { PriceMasterMetrics } from './metrics';
import { PriceMasterColumns, PriceMasterTableToolbar } from './table-def';
import { useTrackGenericEvent } from '@price-for-profit/user-activity-tracking';

interface PriceMasterTableProps {
    user: drive.UserInfo;
    exportButtonContainerRef: React.MutableRefObject<HTMLDivElement | null>;
    metricsContainerRef: React.MutableRefObject<HTMLDivElement | null>;
    preferredExchangeRate: number;
    salesOfficeOptions: DropdownOption[];
    priceMetrics: PriceMetrics;
}

export function PriceMasterTable({
    user,
    exportButtonContainerRef,
    preferredExchangeRate,
    salesOfficeOptions,
    priceMetrics,
    metricsContainerRef,
}: PriceMasterTableProps) {
    const { priceService, submissionHistoryService, appConfig } = useService();
    const exportPriceMasterMutation = usePriceExcelExport();
    const [editEnabled, setEditEnabled] = useState(true);
    const exchangeRates = useGetExchangeRates().data;
    const productQuickFilterRef = useRef<QuickFilterRef>(null);
    const formQuickFilterRef = useRef<QuickFilterRef>(null);
    const salesOfficeQuickFilterRef = useRef<QuickFilterRef>(null);
    const warningsQuickFilterRef = useRef<QuickFilterRef>(null);
    const { warningOptions } = useGetWarningsFilter();
    const savePriceChanges = useSavePriceRows({ user });
    const trackGenericActionMutation = useTrackGenericEvent(appConfig.clientId);
    const [historyVersion, setHistoryVersion] = useState('');
    const [filteredRowsCount, setFilteredRowsCount] = useState(0);
    const submissionHistoryMetadata = useGetSubmissionHistoryMetadata().data;
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(() =>
        getPriceSummaryColumnVisibilityInitialState(user)
    );

    const priceMasterColumnInit = useMemo(() => {
        return {
            user,
            exchangeRate: preferredExchangeRate,
            readOnly: false,
        };
    }, [user, preferredExchangeRate]);

    const priceMasterColumns = useMemo(() => {
        return PriceMasterColumns(priceMasterColumnInit);
    }, [priceMasterColumnInit]);

    const { getDataGridProps, state } = useI2pServerDataGrid<PriceSummary>({
        columns: priceMasterColumns,
        name: QUERYKEY_PRICE + historyVersion,
        dataGridInitialState: {
            pinnedColumns: getPriceMasterPinnedColumnInitialState(user),
        },
        pageSizeOptions: [...PAGE_SIZE_OPTIONS, 200],
        initialState: {
            paginationModel: { page: 0, pageSize: 200 },
            filterModel: getPriceMasterFilterModelInitialState(user),
            sortModel: [{ field: 'priceBook', sort: 'asc' }],
        },
        getData: async state => {
            return historyVersion !== ''
                ? submissionHistoryService.getHistorySummaryForVersion(parseInt(historyVersion), state)
                : priceService.getPriceData(state);
        },
    });

    const dataGridProps = getDataGridProps();

    const exportPriceMaster = () => {
        const priceMasterFilterModel = gridFilterModelSelector(dataGridProps.apiRef!);

        exportPriceMasterMutation.mutate({
            filterModel: priceMasterFilterModel,
            exchangeRate: priceMasterColumnInit.exchangeRate,
        });

        trackGenericActionMutation.mutateAsync({
            action: 'Download Price Master',
            user,
        });
    };

    const handleColumnVisibilityModelChange = (model: GridColumnVisibilityModel) => {
        setColumnVisibilityModel({ ...model, marketPriceRecommendation: true });
    };

    const handleHistoryChange = (event?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event) {
            setHistoryVersion(event.target.value);
            dataGridProps.apiRef?.current.updateColumns(
                PriceMasterColumns({ ...priceMasterColumnInit, readOnly: true })
            );
        } else {
            setHistoryVersion('');
            dataGridProps.apiRef?.current.updateColumns(PriceMasterColumns(priceMasterColumnInit));
        }
        handleColumnVisibilityModelChange(getPriceSummaryColumnVisibilityInitialState(user));
    };

    const setCurrencyType = (newCurrencyType: 'usd' | 'cad') => {
        priceMasterColumnInit.exchangeRate = exchangeRates ? exchangeRates[newCurrencyType] : 1.0;

        dataGridProps.apiRef?.current.updateColumns(PriceMasterColumns(priceMasterColumnInit));

        handleColumnVisibilityModelChange(getPriceSummaryColumnVisibilityInitialState(user));
    };

    const handleChangeCount = useCallback((newRow: PriceSummary) => {
        newRow.changeCount = calculatePriceChangeCount(newRow);
    }, []);

    const handleTargetBookPriceParams = useCallback((newRow: PriceSummary, oldRow: PriceSummary) => {
        newRow.targetMarginNew = Math.abs(newRow.targetMarginNew);
        newRow.bookPriceNew = Math.abs(newRow.bookPriceNew);
        if (newRow.targetMarginNew !== oldRow.targetMarginNew) {
            newRow.bookPriceNew = oldRow.bookPriceCurrent;
        } else if (newRow.bookPriceNew !== oldRow.bookPriceNew) {
            newRow.targetMarginNew = oldRow.targetMarginCurrent;
        }
    }, []);

    const trackEditedRow = useCallback(
        async (newRow: PriceSummary, oldRow: PriceSummary) => {
            if (JSON.stringify(newRow) !== JSON.stringify(oldRow)) {
                handleChangeCount(newRow);
                handleTargetBookPriceParams(newRow, oldRow);
                await savePriceChanges([newRow], [oldRow]);
            }
        },
        [savePriceChanges, handleChangeCount, handleTargetBookPriceParams]
    );

    const processRowUpdate = useCallback(
        (newRow: PriceSummary, oldRow: PriceSummary) =>
            new Promise<PriceSummary>(async resolve => {
                try {
                    setEditEnabled(false);
                    await trackEditedRow(newRow, oldRow);
                    resolve(newRow);
                } catch (e) {
                    resolve(oldRow);
                } finally {
                    setEditEnabled(true);
                }
            }),
        [trackEditedRow]
    );

    const handleFilterChange = (newFilterModel: GridFilterModel) => {
        const productFilter = newFilterModel.items.find(item => item.field === 'product');
        const formFilter = newFilterModel.items.find(item => item.field === 'form');
        const salesOfficeFilter = newFilterModel.items.find(item => item.field === 'so');
        const warningsFilter = newFilterModel.items.find(item => item.field === 'warnings');

        if (!productFilter) {
            if (productQuickFilterRef.current) {
                productQuickFilterRef.current.refResetFilter();
            }
        }

        if (!formFilter) {
            if (formQuickFilterRef.current) {
                formQuickFilterRef.current.refResetFilter();
            }
        }

        if (!salesOfficeFilter) {
            if (salesOfficeQuickFilterRef.current) {
                salesOfficeQuickFilterRef.current.refResetFilter();
            }
        }

        if (!warningsFilter) {
            if (warningsQuickFilterRef.current) {
                warningsQuickFilterRef.current.refResetFilter();
            }
        }
    };

    useEffect(() => {
        handleFilterChange(state.filterModel);
    }, [state.filterModel]);

    return (
        <Paper
            sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                },
                height: 'calc(100vh - 270px)',
                '& .MuiDataGrid-cell--editable': {
                    '& .MuiInputBase-root': {
                        height: '100%',
                    },
                },
                '& .cell-warning': {
                    backgroundColor: 'rgba(244, 67, 54, 0.3)',
                },
                '& .values-diff': {
                    backgroundColor: 'rgba(245, 213, 39, 0.5) !important',
                },
            }}
        >
            <Portal container={() => exportButtonContainerRef.current}>
                <LoadingButton
                    size='small'
                    sx={{ ml: 1 }}
                    variant='outlined'
                    color='warning'
                    startIcon={<Download />}
                    onClick={exportPriceMaster}
                    loading={exportPriceMasterMutation.isPending}
                    loadingPosition='start'
                >
                    Download Price Master
                </LoadingButton>
            </Portal>
            <Portal container={() => metricsContainerRef.current}>
                <PriceMasterMetrics apiRef={dataGridProps.apiRef!} priceMetrics={priceMetrics} />
            </Portal>
            <Stack
                direction='row'
                spacing={1}
                paddingTop={1}
                paddingBottom={1}
                alignItems='center'
                paddingLeft={1}
                data-testid='filter-toolbar'
            >
                <CurrencyToggle rates={exchangeRates} setCurrency={setCurrencyType}></CurrencyToggle>
                <QuickFilter
                    label='Product'
                    column='product'
                    apiRef={dataGridProps.apiRef!}
                    options={Object.values(ProductTypeFilter).map(x => ({ label: x.toString(), value: x.toString() }))}
                    ref={productQuickFilterRef}
                />
                <QuickFilter
                    label='Form'
                    column='form'
                    apiRef={dataGridProps.apiRef!}
                    options={Object.values(FormTypeFilter).map(x => ({ label: x.toString(), value: x.toString() }))}
                    ref={formQuickFilterRef}
                />
                <QuickMultiFilter
                    label='Sales Office'
                    column='so'
                    apiRef={dataGridProps.apiRef!}
                    options={salesOfficeOptions}
                    ref={salesOfficeQuickFilterRef}
                />
                <WarningsQuickMultiFilter
                    label='Warnings'
                    column='warnings'
                    apiRef={dataGridProps.apiRef!}
                    options={warningOptions}
                    ref={warningsQuickFilterRef}
                />

                {submissionHistoryMetadata ? (
                    <TextField
                        select
                        label='View Historical'
                        onChange={handleHistoryChange}
                        size='small'
                        sx={{
                            width: '280px',
                        }}
                        value={historyVersion}
                        variant='outlined'
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    sx={{ display: historyVersion ? '' : 'none' }}
                                    onClick={() => handleHistoryChange()}
                                >
                                    <Clear />
                                </IconButton>
                            ),
                        }}
                    >
                        {submissionHistoryMetadata?.map(option => (
                            <MenuItem key={option.version} value={option.version}>
                                {formatSlashDateWithTime(Date.parse(option.submissionDate))}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : null}
            </Stack>
            <DataGridPremium
                {...dataGridProps}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: PriceMasterTableToolbar as GridSlots['toolbar'],
                    loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
                }}
                slotProps={{
                    toolbar: {
                        user,
                        state,
                        filteredRowsCount,
                    },
                }}
                onStateChange={state => {
                    if (state.rows.totalRowCount) {
                        setFilteredRowsCount(state.rows.totalRowCount);
                    }
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                isCellEditable={() => editEnabled}
            />
        </Paper>
    );
}
