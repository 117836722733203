import { useService } from '@price-for-profit/service-provider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERYKEY_COST } from 'shared/constants';
import { UpdateCostMasterParameters } from 'shared/types';

export function useSaveCostMasterRows() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { costService } = useService();

    const mutation = useMutation<void, Error, UpdateCostMasterParameters>({
        mutationFn: request => costService.saveRows(request),
        onSuccess: () => {
            enqueueSnackbar('Cost fields calculated and saved', { variant: 'success' });
        },
        onError: () => {
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST] });
            enqueueSnackbar('Cost fields failed to calculate and save', { variant: 'error' });
        },
    });

    return mutation;
}
