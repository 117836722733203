import { TextField } from '@mui/material';
import { GridRenderCellParams, GridValidRowModel, useGridApiContext } from '@mui/x-data-grid-premium';
import { useLayoutEffect, useRef } from 'react';

export function PercentageColumn<T extends GridValidRowModel>(params: GridRenderCellParams<T, string>) {
    const apiRef = useGridApiContext();
    const inputRef = useRef<HTMLInputElement>();
    const numValue = Number(params.value);
    const defaultValue = numValue > 1 ? numValue : numValue * 100;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        apiRef.current.setEditCellValue({
            id: params.id,
            field: params.field,
            value: Number(event.target.value) / 100,
        });
    };

    useLayoutEffect(() => {
        inputRef.current?.select();
    }, []);

    return (
        <TextField
            id='percentage'
            autoFocus
            onKeyDown={event => {
                if (event.key.toLocaleLowerCase() === 'e') {
                    event.preventDefault();
                }
            }}
            inputProps={{
                step: 0.01,
                min: 0,
            }}
            defaultValue={defaultValue.toFixed(2)}
            type='number'
            onChange={handleChange}
            inputRef={inputRef}
        />
    );
}
