import { DeleteOutline, Verified } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { GridCustomTableToolbar, ServerSideState } from '@price-for-profit/data-grid';
import { useCallback } from 'react';
import { CostFutureConfirmationDialog } from '../cost-future-confirmation-dialog';
import { useToggle } from '@price-for-profit/react-hooks';
interface CostMasterFutureTableToolbarProps {
    user: drive.UserInfo;
    state: ServerSideState;
    handleDiscard: Function;
    handleApply: Function;
}

export function CostMasterFutureTableToolbar({ user, handleDiscard, handleApply }: CostMasterFutureTableToolbarProps) {
    const [isDiscardDialogOpen, toggleDiscardDialog] = useToggle(false);
    const [isApplyDialogOpen, toggleApplyDialog] = useToggle(false);

    const onDiscardDialogClose = useCallback(
        async (confirm: boolean) => {
            if (confirm) {
                handleDiscard();
            }
            toggleDiscardDialog();
        },
        [toggleDiscardDialog, handleDiscard]
    );

    const onApplyDialogClose = useCallback(
        async (confirm: boolean) => {
            if (confirm) {
                handleApply();
            }
            toggleApplyDialog();
        },
        [toggleApplyDialog, handleApply]
    );

    return (
        <GridCustomTableToolbar
            uniqueGridName='CostMasterFuture'
            isShareAllowed={user.layoutsShare}
            canUnshareAny={user.layoutsUnshareAny}
        >
            {user.coe ? (
                <Box>
                    <Button
                        onClick={() => toggleDiscardDialog()}
                        startIcon={<DeleteOutline />}
                        size='small'
                        variant='outlined'
                        sx={{ mr: 1 }}
                    >
                        Discard
                    </Button>
                    <Button
                        onClick={() => toggleApplyDialog()}
                        startIcon={<Verified />}
                        size='small'
                        variant='outlined'
                    >
                        Apply
                    </Button>
                </Box>
            ) : (
                ''
            )}

            <Box>
                <CostFutureConfirmationDialog
                    isConfirmationOpened={isDiscardDialogOpen}
                    onConfirmationClose={onDiscardDialogClose}
                    dialogText={'This will DELETE all records associated with this Future Rate, are you sure?'}
                />
                <CostFutureConfirmationDialog
                    isConfirmationOpened={isApplyDialogOpen}
                    onConfirmationClose={onApplyDialogClose}
                    dialogText={'This will apply all records associated with this Future Rate, are you sure?'}
                />
            </Box>
        </GridCustomTableToolbar>
    );
}
