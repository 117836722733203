import '@fontsource/roboto';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license';
import { ProvideAuth, getAppPermissions } from '@price-for-profit/auth';
import { DefaultUserInfo } from '@price-for-profit/auth-types';
import {
    MUI_LICENSE_KEY,
    ProvideErrorBoundary,
    ProvideQueryClient,
    ProvideSnackbar,
} from '@price-for-profit/drive-app-core';
import { getBrowserAuthClients } from '@price-for-profit/micro-services';
import { ProvideTheme } from '@price-for-profit/mui-theme';
import { ProvideService, getAppConfig } from '@price-for-profit/service-provider';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createServiceContext } from 'startup';
import App from './App';
import './index.css';
import { PERMISSIONS } from 'shared/constants';

//import reportWebVitals from './reportWebVitals';
const run = async () => {
    const baseUrl = process.env.PUBLIC_URL;
    const appConfig = await getAppConfig(baseUrl);
    const { clientApp, httpClient } = await getBrowserAuthClients({
        clientId: appConfig.appId,
        authDomainHost: appConfig.authDomainHost,
        authorityUrl: appConfig.authorityUrl,
        cacheLocation: 'sessionStorage',
        serviceBaseUrl: appConfig.serviceBaseUrl,
        environment: appConfig.deployedEnvironment,
        redirectUrl: baseUrl,
        clientScopes: {
            auth: appConfig.authorizationAppId,
            ces: appConfig.calculationAppId,
            das: appConfig.dataAccessAppId,
            event: appConfig.eventAppId,
            export: appConfig.exportAppId,
            status: appConfig.statusAppId,
        },
    });
    const serviceContext = createServiceContext(appConfig, httpClient);

    const getUserInfo = (defaultUser: DefaultUserInfo) => {
        const permissions = getAppPermissions(defaultUser.insightClaims, appConfig.clientId);

        const {
            canada_procurement,
            coe,
            cxm_mmgm,
            cxr,
            layoutsShare,
            layoutsUnshareAny,
            procurement,
        } = permissions.reduce(
            (result, permission) => {
                switch (true) {
                    case permission.includes(PERMISSIONS.canada_procurement):
                        result.canada_procurement = true;
                        break;
                    case permission.includes(PERMISSIONS.coe):
                        result.coe = true;
                        break;
                    case permission.includes(PERMISSIONS.cxm_mmgm):
                        result.cxm_mmgm = true;
                        break;
                    case permission.includes(PERMISSIONS.cxr):
                        result.cxr = true;
                        break;
                    case permission.includes(PERMISSIONS['layouts.share']):
                        result.layoutsShare = true;
                        break;
                    case permission.includes(PERMISSIONS['layouts.canUnshareAny']):
                        result.layoutsUnshareAny = true;
                        break;
                    case permission.includes(PERMISSIONS.procurement):
                        result.procurement = true;
                        break;
                }

                return result;
            },
            {
                canada_procurement: false,
                coe: false,
                cxm_mmgm: false,
                cxr: false,
                layoutsShare: false,
                layoutsUnshareAny: false,
                procurement: false,
            }
        );

        return {
            ...defaultUser,
            canada_procurement,
            coe,
            cxm_mmgm,
            cxr,
            layoutsShare,
            layoutsUnshareAny,
            procurement,
        };
    };

    LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

    const root = createRoot(document.getElementById('root') as HTMLElement);
    root.render(
        <React.StrictMode>
            <BrowserRouter basename={baseUrl}>
                <ProvideTheme>
                    <ProvideSnackbar>
                        <ProvideErrorBoundary>
                            <ProvideService context={serviceContext}>
                                <ProvideAuth clientApp={clientApp} getUserInfo={getUserInfo}>
                                    <ProvideQueryClient>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <App />
                                        </LocalizationProvider>
                                    </ProvideQueryClient>
                                </ProvideAuth>
                            </ProvideService>
                        </ProvideErrorBoundary>
                    </ProvideSnackbar>
                </ProvideTheme>
            </BrowserRouter>
        </React.StrictMode>
    );
};

run().catch(console.error);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
