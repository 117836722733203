import { formatDecimal, formatMoney, formatPercent, formatSlashDate } from 'shared/utility';

export function ColumnFormatterInt(value: any) {
    if (isNaN(value as number)) return '';
    return Math.round(value as number);
}

export function ColumnFormatterMoney(value: any) {
    if (isNaN(value as number)) return '';
    return formatMoney(value as number);
}

export function ColumnFormatterMoneyDecimals(value: any, decimalPlaces: number) {
    if (isNaN(value as number)) return '';
    return formatDecimal(value, decimalPlaces, decimalPlaces);
}

export function ColumnFormatterPercent(value: any) {
    return formatPercent(value as number);
}

export function ColumnFormatterPercentDecimals(value: any, minDecimalPlaces: number) {
    return formatPercent(value as number, minDecimalPlaces);
}

export function ColumnFormatterBoolean(value: any) {
    return value as boolean;
}

export function ColumnFormatterList(value: any) {
    return (value as string[]).join(', ');
}

export function ColumnFormatterMinMax(value: any) {
    return (value as number[]).join(' - ');
}

export function ColumnFormatterSlashDate(value: any) {
    if (!value) return '';
    return formatSlashDate(new Date(value) as Date);
}

export function ColumnFormatterPoundDecimals(value: any, row: any) {
    if (isNaN(value as number)) return '';
    return (
        '$' +
        Intl.NumberFormat('en-US', {
            minimumFractionDigits: row.poundDecimals,
            maximumFractionDigits: row.poundDecimals,
        }).format(value as number)
    );
}

export function ColumnFormatterExchangeRate(value: any, exchangeRate: number) {
    return value * exchangeRate;
}

export function ColumnSetterExchangeRate(value: any, row: any, exchangeRate: number, fieldName: string) {
    let updatedVal = { [fieldName]: value / exchangeRate };
    return { ...row, ...updatedVal };
}

export function ColumnFormatterUnitDecimals(value: any, row: any) {
    if (isNaN(value as number)) return '';
    return (
        '$' +
        Intl.NumberFormat('en-US', {
            minimumFractionDigits: row.unitDecimals,
            maximumFractionDigits: row.unitDecimals,
        }).format(value as number)
    );
}
