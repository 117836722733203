import { Clear } from '@mui/icons-material';
import { IconButton, MenuItem, TextField } from '@mui/material';
import { GridFilterItem } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';

type QuickFilterProps = {
    label: string;
    column: string;
    apiRef: React.MutableRefObject<GridApiPremium>;
    options: {
        label: string;
        value: string;
    }[];
    operator?: string;
    changeCallback?: Function;
};

export interface QuickFilterRef {
    refResetFilter: () => void;
}

export const QuickFilter = forwardRef<QuickFilterRef, QuickFilterProps>(
    ({ label, column, apiRef, options, changeCallback = null, operator = 'contains' }, ref) => {
        const [value, setValue] = useState<string>('');
        const [filterId, setFilterId] = useState<string | undefined>(undefined);

        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
            const currentId = filterId || crypto.randomUUID();
            setFilterId(currentId);
            setValue(event.target.value);

            var filterItem: GridFilterItem = {
                field: column,
                value: event.target.value,
                id: currentId,
                operator: operator,
            };
            apiRef.current.upsertFilterItem(filterItem);

            if (changeCallback) changeCallback(event);
        };

        const resetFilter = () => {
            var filterItem: GridFilterItem = { id: filterId, field: column, value: value, operator: 'contains' };
            apiRef.current.deleteFilterItem(filterItem);
            setValue('');
            setFilterId(undefined);
        };

        const refResetFilter = () => {
            setValue('');
            setFilterId(undefined);
        };

        useImperativeHandle(ref, () => ({
            refResetFilter: () => {
                refResetFilter();
            },
        }));

        return (
            <TextField
                select
                variant='outlined'
                label={label}
                value={value}
                onChange={onChange}
                size='small'
                sx={{
                    width: '200px',
                }}
                InputProps={{
                    endAdornment: (
                        <IconButton sx={{ display: value ? '' : 'none' }} onClick={resetFilter}>
                            <Clear />
                        </IconButton>
                    ),
                }}
            >
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        );
    }
);
