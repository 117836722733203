import { PriceSummary } from 'shared/types';

export function calculatePriceChangeCount(priceSummaryRow: PriceSummary) {
    let changeCount = 0;

    if (priceSummaryRow.targetMarginNew.toFixed(3) !== priceSummaryRow.targetMarginCurrent.toFixed(3)) changeCount++;
    if (priceSummaryRow.floorPriceNew.toFixed(2) !== priceSummaryRow.floorPriceCurrent.toFixed(2)) changeCount++;
    if (priceSummaryRow.floorMarginNew.toFixed(2) !== priceSummaryRow.floorMarginCurrent.toFixed(2)) changeCount++;
    if (priceSummaryRow.bookPriceNew.toFixed(2) !== priceSummaryRow.bookPriceCurrent.toFixed(2)) changeCount++;
    if (priceSummaryRow.baseCostChange !== 0) changeCount++;
    if (priceSummaryRow.marketMovementNew.toFixed(2) !== priceSummaryRow.marketMovementCurrent.toFixed(2))
        changeCount++;
    if (priceSummaryRow.floorMarginAllInNew.toFixed(2) !== priceSummaryRow.floorMarginAllInCurrent.toFixed(2))
        changeCount++;

    return changeCount;
}
