import { SurchargeMasterPage } from './page';
import { Loader } from 'shared/components/loader/loader';
import { useUser } from '@price-for-profit/auth';

export function SurchargeMasterResolver() {
    const user = useUser();

    if (!user) {
        return <Loader />;
    }

    return <SurchargeMasterPage user={user} />;
}
