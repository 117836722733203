import { format } from 'date-fns/fp';

const moneyFormatter = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });
const moneyCompactFormatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    compactDisplay: 'short',
});
const percentFormatter = (minDecimalPlaces = 2, minIntegerDigits = 2) =>
    new Intl.NumberFormat('en', {
        style: 'percent',
        minimumFractionDigits: minDecimalPlaces,
        minimumIntegerDigits: minIntegerDigits,
    });
export const formatMoney = (value: number) => moneyFormatter.format(value);

export const formatMoneyCompact = (value: number) => moneyCompactFormatter.format(value);

export const formatPercent = (value: number, minDecimalPlaces = 2, minIntegerDigits = 2) => {
    return percentFormatter(minDecimalPlaces, minIntegerDigits).format(value);
};

export const formatDecimal = (value: number, minDecimalConfig: number, maxDecimalConfig: number) =>
    '$' +
    Intl.NumberFormat('en-US', {
        minimumFractionDigits: minDecimalConfig,
        maximumFractionDigits: maxDecimalConfig,
    }).format(value as number);

export const formatDate = format('MM-dd-yy');

export const formatDateWithTime = format('MMddyyhhmm');

export const formatSlashDate = format('MM/dd/yyyy');

export const formatSlashDateWithTime = format('MM/dd/yyyy hh:mm aaa');

export const formatExchangeRate = (value: number, exchangeRate: number, decimalPlaces: number) =>
    formatDecimal(value * exchangeRate, decimalPlaces, decimalPlaces);
