type KeyOf<T> = Extract<keyof T, string>;
type ValueOf<T> = T[KeyOf<T>];

export type MapType<T, M> = {
    [I in keyof T]: M;
};

/**
 * Nicely typed aliases for some `Object` Methods
 * - PSA: Don't mutate `yourObject`s
 * - Numerical keys are BAD `{ 1: 'ha!' }` may not appear in your resulting types
 * - Discussion: https://stackoverflow.com/a/65117465/565877
 */
export const ObjectTyped = {
    /**
     * Object.keys, but with nice typing (`Array<keyof T>`)
     */
    keys: Object.keys as <T extends {}>(yourObject: T) => Array<KeyOf<T>>,
    /**
     * Object.values, but with nice typing
     */
    values: Object.values as <T extends {}>(yourObject: T) => Array<ValueOf<T>>,
    /**
     * Object.entries, but with nice typing
     */
    entries: Object.entries as <T extends {}>(yourObject: T) => Array<[KeyOf<T>, ValueOf<T>]>,
    /**
     * Object.fromEntries, but with nice typing
     */
    fromEntries: Object.fromEntries as <K extends string, V>(yourObjectEntries: Array<[K, V]>) => Record<K, V>,
};
