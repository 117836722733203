import { useState, ChangeEvent, useImperativeHandle, forwardRef } from 'react';
import { IconButton, MenuItem, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridFilterItem } from '@mui/x-data-grid-premium';
import {
    WARNING_BASE_COST_ADJUSTED,
    WARNING_BASE_COST_MAX_CHANGE,
    WARNING_MARKET_MOVEMENT_MAX_CHANGE,
    WARNING_PENDING_FUTURE_EFFECTIVE_DATE_ASSOCIATED,
} from 'shared/constants';

type QuickFilterProps = {
    label: string;
    column: string;
    apiRef: React.MutableRefObject<GridApiPremium>;
    options: string[];
};

export interface QuickFilterRef {
    refResetFilter: () => void;
}

export const WarningsQuickFilter = forwardRef<QuickFilterRef, QuickFilterProps>(
    ({ label, column, apiRef, options }, ref) => {
        const [value, setValue] = useState<string>('');
        const [filterId, setFilterId] = useState<string | undefined>(undefined);

        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
            const currentId = filterId || crypto.randomUUID();
            setFilterId(currentId);
            setValue(event.target.value);

            let filterItem: GridFilterItem = {
                field: column,
                value: true,
                id: currentId,
                operator: 'is',
            };

            switch (event.target.value) {
                case WARNING_BASE_COST_MAX_CHANGE:
                    filterItem.field = 'baseCostMaxChangeWarning';
                    break;
                case WARNING_BASE_COST_ADJUSTED:
                    filterItem.field = 'baseCostAdjustedWarning';
                    break;
                case WARNING_MARKET_MOVEMENT_MAX_CHANGE:
                    filterItem.field = 'marketMovementMaxChangeWarning';
                    break;
                case WARNING_PENDING_FUTURE_EFFECTIVE_DATE_ASSOCIATED:
                    filterItem.field = 'pendingFutureEffectiveDateWarning';
                    break;
            }

            apiRef.current.upsertFilterItem(filterItem);
        };

        const resetFilter = () => {
            var filterItem: GridFilterItem = { id: filterId, field: column, value: value, operator: 'is' }; // UNKNOWN
            apiRef.current.deleteFilterItem(filterItem);
            setValue('');
            setFilterId(undefined);
        };

        const refResetFilter = () => {
            setValue('');
            setFilterId(undefined);
        };

        useImperativeHandle(ref, () => ({
            refResetFilter: () => {
                refResetFilter();
            },
        }));

        return (
            <TextField
                select
                variant='outlined'
                label={label}
                value={value}
                onChange={onChange}
                size='small'
                sx={{
                    width: '200px',
                }}
                InputProps={{
                    endAdornment: (
                        <IconButton sx={{ display: value ? '' : 'none' }} onClick={resetFilter}>
                            <Clear />
                        </IconButton>
                    ),
                }}
            >
                {options.map(option => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        );
    }
);
