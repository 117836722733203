import {
    WARNING_BASE_COST_ADJUSTED,
    WARNING_BASE_COST_MAX_CHANGE,
    WARNING_MARKET_MOVEMENT_MAX_CHANGE,
    WARNING_PENDING_FUTURE_EFFECTIVE_DATE_ASSOCIATED,
} from 'shared/constants';
import { CostMasterExcelExport, CostSummary } from 'shared/types';
import { formatDecimal, formatPercent, formatSlashDate } from './formats';

export const hasCostWarnings = (price: CostSummary): boolean => {
    return (
        price.baseCostMaxChangeWarning ||
        price.baseCostAdjustedWarning ||
        price.marketMovementMaxChangeWarning ||
        price.pendingFutureEffectiveDateWarning
    );
};

export const getCostWarnings = (price: CostSummary): string => {
    let warnings: string[] = [];

    if (price.baseCostMaxChangeWarning) warnings.push(WARNING_BASE_COST_MAX_CHANGE);
    if (price.baseCostAdjustedWarning) warnings.push(WARNING_BASE_COST_ADJUSTED);
    if (price.marketMovementMaxChangeWarning) warnings.push(WARNING_MARKET_MOVEMENT_MAX_CHANGE);
    if (price.pendingFutureEffectiveDateWarning) warnings.push(WARNING_PENDING_FUTURE_EFFECTIVE_DATE_ASSOCIATED);

    return warnings.join(' ');
};

export const getCostPopoverWarnings = (price: CostSummary): string => {
    let warnings: string[] = [];

    if (price.baseCostMaxChangeWarning) warnings.push(WARNING_BASE_COST_MAX_CHANGE);
    if (price.baseCostAdjustedWarning) warnings.push(WARNING_BASE_COST_ADJUSTED);
    if (price.marketMovementMaxChangeWarning) warnings.push(WARNING_MARKET_MOVEMENT_MAX_CHANGE);
    if (price.pendingFutureEffectiveDateWarning) warnings.push(WARNING_PENDING_FUTURE_EFFECTIVE_DATE_ASSOCIATED);

    return warnings.join('\n');
};

export function getCostMasterExportData(costData: CostSummary[], exchangeRate: number): CostMasterExcelExport {
    return {
        costMasterSummary: costData?.map(cd => ({
            priceBook: cd.priceBook || '',
            bw: cd.bw || '',
            bwDesc: cd.bwDesc || '',
            owner: cd.owner || '',
            primaryMillSupplier: cd.primaryMillSupplier || '',
            secondaryMillSupplier: cd.secondaryMillSupplier || '',
            product: cd.product || '',
            form: cd.form || '',
            productCategory: cd.productCategory || '',
            surcharge: cd.surcharge || '',
            secondarySurchargeCurrent: cd.secondarySurchargeCurrent || '',
            warnings: getCostWarnings(cd),
            primaryBaseConversionCurrent:
                cd.primaryBaseConversionCurrent || cd.primaryBaseConversionCurrent === 0
                    ? formatDecimal(cd.primaryBaseConversionCurrent * exchangeRate, 2, 2)
                    : '',
            primaryBaseConversionNew:
                cd.primaryBaseConversionNew || cd.primaryBaseConversionNew === 0
                    ? formatDecimal(cd.primaryBaseConversionNew * exchangeRate, 2, 2)
                    : '',
            secondaryBaseConversionCurrent:
                cd.secondaryBaseConversionCurrent || cd.secondaryBaseConversionCurrent === 0
                    ? formatDecimal(cd.secondaryBaseConversionCurrent * exchangeRate, 2, 2)
                    : '',
            secondaryBaseConversionNew:
                cd.secondaryBaseConversionNew || cd.secondaryBaseConversionNew === 0
                    ? formatDecimal(cd.secondaryBaseConversionNew * exchangeRate, 2, 2)
                    : '',
            primaryAllocationCurrent:
                cd.primaryAllocationCurrent || cd.primaryAllocationCurrent === 0
                    ? formatPercent(cd.primaryAllocationCurrent, 1, 1)
                    : '',
            primaryAllocationNew:
                cd.primaryAllocationNew || cd.primaryAllocationNew === 0
                    ? formatPercent(cd.primaryAllocationNew, 1, 1)
                    : '',
            baseConversionCurrent:
                cd.baseConversionCurrent || cd.baseConversionCurrent === 0
                    ? formatDecimal(cd.baseConversionCurrent * exchangeRate, 2, 2)
                    : '',
            baseConversionNew:
                cd.baseConversionNew || cd.baseConversionNew === 0
                    ? formatDecimal(cd.baseConversionNew * exchangeRate, 2, 2)
                    : '',
            surchargeIngotCurrent:
                cd.surchargeIngotCurrent || cd.surchargeIngotCurrent === 0
                    ? formatDecimal(cd.surchargeIngotCurrent * exchangeRate, 2, 2)
                    : '',
            surchargeIngotNew:
                cd.surchargeIngotNew || cd.surchargeIngotNew === 0
                    ? formatDecimal(cd.surchargeIngotNew * exchangeRate, 2, 2)
                    : '',
            processingCurrent:
                cd.processingCurrent || cd.processingCurrent === 0
                    ? formatDecimal(cd.processingCurrent * exchangeRate, 2, 2)
                    : '',
            processingNew:
                cd.processingNew || cd.processingNew === 0 ? formatDecimal(cd.processingNew * exchangeRate, 2, 2) : '',
            otherExtrasCurrent:
                cd.otherExtrasCurrent || cd.otherExtrasCurrent === 0
                    ? formatDecimal(cd.otherExtrasCurrent * exchangeRate, 2, 2)
                    : '',
            otherExtrasNew:
                cd.otherExtrasNew || cd.otherExtrasNew === 0
                    ? formatDecimal(cd.otherExtrasNew * exchangeRate, 2, 2)
                    : '',
            inboundFreightCurrent:
                cd.inboundFreightCurrent || cd.inboundFreightCurrent === 0
                    ? formatDecimal(cd.inboundFreightCurrent * exchangeRate, 2, 2)
                    : '',
            inboundFreightNew:
                cd.inboundFreightNew || cd.inboundFreightNew === 0
                    ? formatDecimal(cd.inboundFreightNew * exchangeRate, 2, 2)
                    : '',
            secondaryInboundFreightCurrent:
                cd.secondaryInboundFreightCurrent || cd.secondaryInboundFreightCurrent === 0
                    ? formatDecimal(cd.secondaryInboundFreightCurrent * exchangeRate, 2, 2)
                    : '',
            secondaryInboundFreightNew:
                cd.secondaryInboundFreightNew || cd.secondaryInboundFreightNew === 0
                    ? formatDecimal(cd.secondaryInboundFreightNew * exchangeRate, 2, 2)
                    : '',
            baseCostCurrent:
                cd.baseCostCurrent || cd.baseCostCurrent === 0
                    ? formatDecimal(cd.baseCostCurrent * exchangeRate, 2, 2)
                    : '',
            baseCostLastChange: cd.baseCostLastChange ? formatSlashDate(Date.parse(cd.baseCostLastChange)) : '',
            baseCostNew:
                cd.baseCostNew || cd.baseCostNew === 0 ? formatDecimal(cd.baseCostNew * exchangeRate, 2, 2) : '',
            baseCostHoldPrice: cd.baseCostHoldPrice ? 'YES' : 'NO',
            replacementCostChange:
                cd.replacementCostChange || cd.replacementCostChange === 0
                    ? formatDecimal(cd.replacementCostChange * exchangeRate, 2, 2)
                    : '',
            marketMovementCurrent:
                cd.marketMovementCurrent || cd.marketMovementCurrent === 0
                    ? formatDecimal(cd.marketMovementCurrent * exchangeRate, 2, 2)
                    : '',
            marketMovementLastChange: cd.marketMovementLastChange
                ? formatSlashDate(Date.parse(cd.marketMovementLastChange))
                : '',
            marketMovementNew:
                cd.marketMovementNew || cd.marketMovementNew === 0
                    ? formatDecimal(cd.marketMovementNew * exchangeRate, 2, 2)
                    : '',
            marketMovementHoldPrice: cd.marketMovementHoldPrice ? 'YES' : 'NO',
            marketMovementAdHocCurrent:
                cd.marketMovementAdHocCurrent || cd.marketMovementAdHocCurrent === 0
                    ? formatDecimal(cd.marketMovementAdHocCurrent * exchangeRate, 2, 2)
                    : '',
            marketMovementAdHocNew:
                cd.marketMovementAdHocNew || cd.marketMovementAdHocNew === 0
                    ? formatDecimal(cd.marketMovementAdHocNew * exchangeRate, 2, 2)
                    : '',
            marketMovementAdHocLastChange: cd.marketMovementAdHocLastChange
                ? formatSlashDate(Date.parse(cd.marketMovementAdHocLastChange))
                : '',
            changeCount: cd.changeCount || '',
        })),
    };
}
