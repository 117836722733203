import { WARNING_TARGET_MARGIN_NEW_BELOW_FLOOR_MARGIN_NEW } from 'shared/constants';
import { PriceMasterExcelExport, PriceSummary } from 'shared/types';
import { formatDecimal, formatPercent } from './formats';

export const hasPriceWarnings = (price: PriceSummary): boolean => {
    return (
        price.targetMarginMaxChangeWarning ||
        price.targetMarginMinWarning ||
        price.bookPriceMaxChangeWarning ||
        price.floorPriceMinWarning ||
        price.targetMarginNewBelowFloorMarginNewWarning
    );
};

export function getNonConfigurableWarnings(): string[] {
    return [WARNING_TARGET_MARGIN_NEW_BELOW_FLOOR_MARGIN_NEW];
}

export function getPriceMasterExportData(priceData: PriceSummary[], exchangeRate: number): PriceMasterExcelExport {
    return {
        priceMasterSummary: priceData?.map(pd => ({
            so: pd.so || '',
            bw: pd.bw || '',
            threePc: pd.threePc || '',
            product: pd.product || '',
            form: pd.form || '',
            bwDesc: pd.bwDesc || '',
            rollingTwelveMonthsRevenue:
                pd.rollingTwelveMonthsRevenue || pd.rollingTwelveMonthsRevenue === 0
                    ? formatDecimal(pd.rollingTwelveMonthsRevenue * exchangeRate, 0, 0)
                    : '',
            baseLbs: pd.baseLbs || pd.baseLbs === 0 ? pd.baseLbs.toString() : '',
            baseLbsRange: pd.baseLbsRange || '',
            targetMarginCurrent:
                pd.targetMarginCurrent || pd.targetMarginCurrent === 0
                    ? formatPercent(pd.targetMarginCurrent, 1, 1)
                    : '',
            finalTargetMargin:
                pd.finalTargetMargin || pd.finalTargetMargin === 0 ? formatPercent(pd.finalTargetMargin, 1, 1) : '',
            targetMarginAdj:
                pd.targetMarginAdj || pd.targetMarginAdj === 0 ? formatPercent(pd.targetMarginAdj, 1, 1) : '',
            baseCostCurrent:
                pd.baseCostCurrent || pd.baseCostCurrent === 0
                    ? formatDecimal(pd.baseCostCurrent * exchangeRate, 4, 4)
                    : '',
            marketMovementCurrent:
                pd.marketMovementCurrent || pd.marketMovementCurrent === 0
                    ? formatDecimal(pd.marketMovementCurrent * exchangeRate, 4, 4)
                    : '',
            finalBaseCost:
                pd.finalBaseCost || pd.finalBaseCost === 0 ? formatDecimal(pd.finalBaseCost * exchangeRate, 4, 4) : '',
            finalMarketMovement:
                pd.finalMarketMovement || pd.finalMarketMovement === 0
                    ? formatDecimal(pd.finalMarketMovement * exchangeRate, 4, 4)
                    : '',
            nonMaterialCostAdj:
                pd.nonMaterialCostAdj || pd.nonMaterialCostAdj === 0
                    ? formatDecimal(pd.nonMaterialCostAdj * exchangeRate, 4, 4)
                    : '',
            pcpFreightCost:
                pd.pcpFreightCost || pd.pcpFreightCost === 0
                    ? formatDecimal(pd.pcpFreightCost * exchangeRate, 4, 4)
                    : '',
            nonMaterialCost:
                pd.nonMaterialCost || pd.nonMaterialCost === 0
                    ? formatDecimal(pd.nonMaterialCost * exchangeRate, 4, 4)
                    : '',
            smallOrderAdder:
                pd.smallOrderAdder || pd.smallOrderAdder === 0
                    ? formatDecimal(pd.smallOrderAdder * exchangeRate, 4, 4)
                    : '',
            bookPriceCurrent:
                pd.bookPriceCurrent || pd.bookPriceCurrent === 0
                    ? formatDecimal(pd.bookPriceCurrent * exchangeRate, 4, 4)
                    : '',
            finalBookPrice:
                pd.finalBookPrice || pd.finalBookPrice === 0
                    ? formatDecimal(pd.finalBookPrice * exchangeRate, 4, 4)
                    : '',
            floorPriceNew:
                pd.floorPriceNew || pd.floorPriceNew === 0 ? formatDecimal(pd.floorPriceNew * exchangeRate, 4, 4) : '',
            floorMarginCurrent:
                pd.floorMarginCurrent || pd.floorMarginCurrent === 0 ? formatPercent(pd.floorMarginCurrent, 1, 1) : '',
            floorMarginNew: pd.floorMarginNew || pd.floorMarginNew === 0 ? formatPercent(pd.floorMarginNew, 1, 1) : '',
            floorMarginAllInCurrent: formatPercent(pd.floorMarginAllInCurrent, 1, 1),
            floorMarginAllInNew: formatPercent(pd.floorMarginAllInNew, 1, 1),
        })),
    };
}
