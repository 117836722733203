import { ServerSideState } from '@price-for-profit/data-grid';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERYKEY_PRICE, QUERYKEY_PRICE_WARNING_SUMMARY } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';
import { PriceMasterMassActionParameters } from 'shared/types';
import { setMassActionCorrelationId } from 'shared/utility';

type UseMassActionPriceMasterProps = {
    state: ServerSideState;
};

export function useMassActionPriceMaster({ state }: UseMassActionPriceMasterProps) {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { priceService } = useService();

    const mutation = useMutation<void, Error, PriceMasterMassActionParameters>({
        mutationFn: request => priceService.massAction(state, request),
        onSuccess: () => {
            enqueueSnackbar('Price fields will take some time to update, please grab a cup of coffee...', {
                variant: 'info',
            });
        },
        onError: () => {
            enqueueSnackbar('Price fields failed to save', { variant: 'error' });
            setMassActionCorrelationId('');
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_PRICE] });
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_PRICE_WARNING_SUMMARY] });
        },
    });

    return mutation;
}
