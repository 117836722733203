import { ServerSideState, generateCollectionFilter } from '@price-for-profit/data-grid';
import { DataAccessPaginatedResponse, IDataAccessService, IFilter, NoInfer } from '@price-for-profit/micro-services';
import {
    DATASTORE_SQL,
    TABLE_HISTORY_METADATA,
    TABLE_SUBMISSION_HISTORY,
    VIEW_BOOK_PRICE_HISTORY_SUMMARY,
} from 'shared/constants/services';
import { HistoryMetadata, SubmissionHistory, SubmissionHistorySummary } from 'shared/types';

export interface ISubmissionHistoryService {
    getLastSubmissionHistories(priceMasterId: string, amount?: number): Promise<SubmissionHistory[]>;
    getRecentHistoryMetadata(): Promise<HistoryMetadata[]>;
    getSubmissionHistoryForVersion(
        version: number,
        state: ServerSideState
    ): Promise<DataAccessPaginatedResponse<SubmissionHistory>>;
    getHistorySummaryForVersion(
        version: number,
        state: ServerSideState
    ): Promise<DataAccessPaginatedResponse<SubmissionHistorySummary>>;
    getSubmissionHistoryAfterDate(
        daysPassed: number,
        state: ServerSideState
    ): Promise<DataAccessPaginatedResponse<SubmissionHistorySummary>>;
}

export class SubmissionHistoryService implements ISubmissionHistoryService {
    constructor(private dasService: IDataAccessService, private clientId: string) {}

    async getLastSubmissionHistories(priceMasterId: string, amount = 10): Promise<SubmissionHistory[]> {
        const filter: IFilter<SubmissionHistory> = {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'priceMasterId',
                    operator: 'eq',
                    value: priceMasterId,
                },
            ],
        };

        const { data } = await this.dasService.getCollection<SubmissionHistory, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: VIEW_BOOK_PRICE_HISTORY_SUMMARY,
            collectionFilter: filter,
            pageSize: amount,
            sortBy: 'updatedDate',
            sortDescending: true,
        });

        return data;
    }

    async getSubmissionHistoryForVersion(
        version: number,
        state: ServerSideState
    ): Promise<DataAccessPaginatedResponse<SubmissionHistory>> {
        const filter: IFilter<SubmissionHistory> = {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'version',
                    operator: 'eq',
                    value: version,
                },
            ],
        };

        return await this.dasService.getCollection<SubmissionHistory, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_SUBMISSION_HISTORY,
            page: state.paginationModel.page,
            pageSize: state.paginationModel.pageSize,
            sortBy: state.sortModel[0]?.field as NoInfer<keyof SubmissionHistory>,
            sortDescending: state.sortModel[0]?.sort === 'desc' ? true : false,
            collectionFilter: generateCollectionFilter<SubmissionHistory>(state.filterModel, filter),
        });
    }

    async getSubmissionHistoryAfterDate(
        daysPassed: number,
        state: ServerSideState
    ): Promise<DataAccessPaginatedResponse<SubmissionHistorySummary>> {
        const daysPassedDate = new Date();
        daysPassedDate.setDate(daysPassedDate.getDate() - daysPassed);

        const filter: IFilter<SubmissionHistorySummary> = {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'updatedDate',
                    operator: 'gt',
                    value: daysPassedDate,
                },
            ],
        };

        return await this.dasService.getCollection<SubmissionHistorySummary, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_SUBMISSION_HISTORY,
            page: state.paginationModel.page,
            pageSize: state.paginationModel.pageSize,
            sortBy: state.sortModel[0]?.field as NoInfer<keyof SubmissionHistorySummary>,
            sortDescending: state.sortModel[0]?.sort === 'desc' ? true : false,
            collectionFilter: generateCollectionFilter<SubmissionHistorySummary>(state.filterModel, filter),
        });
    }

    async getHistorySummaryForVersion(
        version: number,
        state: ServerSideState
    ): Promise<DataAccessPaginatedResponse<SubmissionHistorySummary>> {
        const filter: IFilter<SubmissionHistorySummary> = {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'version',
                    operator: 'eq',
                    value: version,
                },
            ],
        };

        const historyCollection = await this.dasService.getCollection<SubmissionHistorySummary, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_SUBMISSION_HISTORY,
            page: state.paginationModel.page,
            pageSize: state.paginationModel.pageSize,
            sortBy: state.sortModel[0]?.field as NoInfer<keyof SubmissionHistorySummary>,
            sortDescending: state.sortModel[0]?.sort === 'desc' ? true : false,
            collectionFilter: generateCollectionFilter<SubmissionHistorySummary>(state.filterModel, filter),
        });

        const historicalPriceSummary = this.mapSubmissionHistoryToPriceSummary(historyCollection);

        return historicalPriceSummary;
    }

    async getRecentHistoryMetadata(): Promise<HistoryMetadata[]> {
        const daysPassed = 60;
        const date = new Date();
        date.setDate(date.getDate() - daysPassed);

        const filter: IFilter<HistoryMetadata> = {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'submissionDate',
                    operator: 'gt',
                    value: date,
                },
            ],
        };

        const { data } = await this.dasService.getCollection<HistoryMetadata, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_HISTORY_METADATA,
            collectionFilter: filter,
            sortBy: 'submissionDate',
            sortDescending: true,
            pageSize: 100,
        });

        return data;
    }

    mapSubmissionHistoryToPriceSummary(
        subHistory: DataAccessPaginatedResponse<SubmissionHistorySummary>
    ): DataAccessPaginatedResponse<SubmissionHistorySummary> {
        const newPriceSummary: DataAccessPaginatedResponse<SubmissionHistorySummary> = {
            // Need to calculate a few fields when loading submission history for price summary
            data: subHistory.data
                .map(sh => ({
                    ...sh,
                    baseCostChange: sh.finalBaseCost / sh.baseCostCurrent - 1,
                    targetMarginChange: sh.finalTargetMargin / sh.targetMarginCurrent - 1,
                    bookPriceChange: sh.finalBookPrice / sh.bookPriceCurrent - 1,
                    floorPriceChange: sh.finalFloorPrice / sh.floorPriceCurrent - 1,
                    warnings: 'Historical record',
                }))
                .sort((a, b) => parseInt(a.id) - parseInt(b.id)),
            metadata: subHistory.metadata,
        };
        return newPriceSummary;
    }
}
