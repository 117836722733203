import { Box, Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { useTitle } from '@price-for-profit/react-hooks';
import { useService } from '@price-for-profit/service-provider';
import { TitleHeader } from '@price-for-profit/shared-components';
import { useTrackPageEvent } from '@price-for-profit/user-activity-tracking';
import { Loader } from 'shared/components';
import { useCurrencyPreference } from 'shared/hooks';
import { useGetExchangeRates } from 'shared/queries';
import { CostMasterFutureTable } from './table';

interface CostMasterPageProps {
    user: drive.UserInfo;
}

export function CostMasterFuturePage({ user }: CostMasterPageProps) {
    useTitle('Ryerson Cost Master Futures');
    const preferredCurrencyType = useCurrencyPreference();
    const exchangeRates = useGetExchangeRates().data;
    const apiRef = useGridApiRef();
    const { appConfig } = useService();

    useTrackPageEvent({
        action: 'CostMasterFuturePage',
        clientId: appConfig.clientId,
        user,
    });

    if (!preferredCurrencyType || !exchangeRates) {
        return <Loader />;
    }

    return (
        <Stack>
            <TitleHeader title={'Cost Master Futures'} />
            <Box>
                <CostMasterFutureTable
                    user={user}
                    apiRef={apiRef}
                    preferredExchangeRate={exchangeRates ? exchangeRates[preferredCurrencyType] : 1.0}
                />
            </Box>
        </Stack>
    );
}
