import { LinearProgress, Paper } from '@mui/material';
import {
    DataGridPremium,
    GridColumnsInitialState,
    GridFilterModel,
    GridPinnedColumnFields,
    GridSlots,
    GridSortModel,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { PAGE_SIZE_OPTIONS, useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { useService } from '@price-for-profit/service-provider';
import { useCallback, useState } from 'react';
import { QUERYKEY_WARNING_CONFIGURATION } from 'shared/constants';
import { useSaveWarningConfigurationRow } from 'shared/mutations/save-warning-configuration-row';
import { WarningConfiguration } from 'shared/types';
import {
    WarningConfigurationColumns,
    WarningConfigurationTableToolbar,
    useWarningConfigurationTableState,
} from './table-def';

interface WarningConfigurationTableProps {
    user: drive.UserInfo;
}

export function WarningConfigurationTable({ user }: WarningConfigurationTableProps) {
    const apiRef = useGridApiRef();
    const { warningConfigurationService } = useService();
    const tableState = useWarningConfigurationTableState();
    const [editEnabled, setEditEnabled] = useState(true);

    const saveWarningConfigurationRow = useSaveWarningConfigurationRow();

    const { getDataGridProps } = useI2pServerDataGrid<WarningConfiguration>({
        columns: WarningConfigurationColumns(),
        name: QUERYKEY_WARNING_CONFIGURATION,
        dataGridInitialState: {
            columns: tableState.columns.value as GridColumnsInitialState,
            pinnedColumns: tableState.pinnedColumns.value as GridPinnedColumnFields,
        },
        pageSizeOptions: [...PAGE_SIZE_OPTIONS, 200],
        initialState: {
            paginationModel: tableState.paginationModel?.value || undefined,
            filterModel: tableState.filter?.filterModel?.value as GridFilterModel,
            sortModel: tableState.sorting?.sortModel?.value as GridSortModel,
        },
        getData: async state => {
            return warningConfigurationService.getWarningConfigurationData(state);
        },
    });

    const dataGridProps = getDataGridProps();

    const trackEditedRow = useCallback(
        async (newRow: WarningConfiguration, oldRow: WarningConfiguration) => {
            if (JSON.stringify(newRow) !== JSON.stringify(oldRow)) {
                await saveWarningConfigurationRow.mutateAsync({ newRow, oldRow, user });
            }
        },
        [saveWarningConfigurationRow, user]
    );

    const processRowUpdate = useCallback(
        (newRow: WarningConfiguration, oldRow: WarningConfiguration) =>
            new Promise<WarningConfiguration>(async resolve => {
                try {
                    setEditEnabled(false);
                    await trackEditedRow(newRow, oldRow);
                    setEditEnabled(true);
                    resolve(newRow);
                } catch (e) {
                    resolve(oldRow);
                }
            }),
        [trackEditedRow]
    );

    return (
        <Paper
            sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                },
                height: 'calc(100vh - 110px)',
                '& .MuiDataGrid-cell--editable': {
                    '& .MuiInputBase-root': {
                        height: '100%',
                    },
                },
            }}
        >
            <DataGridPremium
                {...dataGridProps}
                pagination={false}
                apiRef={apiRef}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: WarningConfigurationTableToolbar,
                    loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
                }}
                onColumnVisibilityModelChange={model => tableState.columns.columnVisibilityModel.set(model)}
                isCellEditable={() => editEnabled}
            />
        </Paper>
    );
}
