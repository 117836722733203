import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { LineChart } from '@mui/x-charts';
import { GridLogicOperator } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ServerSideState } from '@price-for-profit/data-grid';
import { TitleHeader } from '@price-for-profit/shared-components';
import { useEffect, useState } from 'react';
import { Loader } from 'shared/components';
import { useGetSubmissionHistoryAfterDate } from 'shared/queries';
import { mapSubmissionHistoryToChart } from 'shared/utility/chart-data-maps';

interface DecisionSupportDrawerContentProps {
    apiRef: React.MutableRefObject<GridApiPremium>;
}

export function DecisionSupportDrawerContent({ apiRef }: DecisionSupportDrawerContentProps) {
    // Get history records by CostMasterID
    const costMasterIdFilter: ServerSideState = {
        filterModel: {
            logicOperator: GridLogicOperator.Or,
            items: apiRef.current.getAllRowIds().map(id => ({
                field: 'costMasterId',
                value: id,
                operator: 'equals',
            })),
        },
        paginationModel: {
            pageSize: 0,
            page: 0,
        },
        sortModel: [],
    };

    const [historyChartData, setHistoryChartData] = useState<any>();
    const history = useGetSubmissionHistoryAfterDate(costMasterIdFilter);

    useEffect(() => {
        if (history.data) {
            const historyChartData = mapSubmissionHistoryToChart(history.data.data);
            setHistoryChartData(historyChartData);
        }
    }, [history.data]);

    return (
        <>
            <TitleHeader title={'Cost Change Review'} />
            <Box width={'100%'} textAlign={'center'}>
                <Typography fontWeight={'bold'}>Customer-Product Cost Trend</Typography>
            </Box>
            <Stack direction='row' justifyContent='center'>
                {history.isLoading || history.isRefetching || historyChartData === undefined ? (
                    <Loader />
                ) : (
                    <LineChart
                        xAxis={[historyChartData.xAxis]}
                        yAxis={[historyChartData.yAxis]}
                        series={historyChartData.series}
                        width={800}
                        height={500}
                    />
                )}
            </Stack>
        </>
    );
}
