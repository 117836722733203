import { SxProps, Theme, TextField, MenuItem } from '@mui/material';
import { useController } from 'react-hook-form';

type SelectProps = {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
    label: string;
    options: any[];
    rules?: Object;
    readOnly?: boolean;
    defaultValue?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    placeholder?: string;
    sx?: SxProps<Theme>;
    inputProps?: any;
};

export function SelectTextField({
    name,
    label,
    options,
    rules,
    readOnly = false,
    defaultValue = '',
    fullWidth = true,
    disabled,
    placeholder,
    inputProps,
    sx,
}: SelectProps) {
    const {
        field: { ref, ...fieldProps },
        fieldState: { error },
    } = useController({
        name,
        rules,
        defaultValue,
    });

    return (
        <TextField
            select
            {...fieldProps}
            id={name}
            inputRef={ref}
            variant='outlined'
            fullWidth={fullWidth}
            label={label}
            error={Boolean(error)}
            helperText={error?.message}
            disabled={disabled}
            placeholder={placeholder}
            InputProps={{
                readOnly,
                ...inputProps,
            }}
            sx={sx}
        >
            {options.map(option => (
                <MenuItem key={option.label} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
}
