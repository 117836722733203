import { CostMasterPage } from './page';
import { Loader } from 'shared/components/loader/loader';
import { useUser } from '@price-for-profit/auth';
import { useGetCostMetrics } from 'shared/queries/get-cost-metrics';

export function CostMasterResolver() {
    const user = useUser();
    const { costMetrics } = useGetCostMetrics();

    if (costMetrics === undefined || !user) {
        return <Loader />;
    }

    return <CostMasterPage user={user} costMetrics={costMetrics} />;
}
