import { Box, CircularProgress, SxProps } from '@mui/material';

const loadingStyles: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 2,
};

export function Loader() {
    return (
        <Box data-testid='Loader' sx={loadingStyles}>
            <CircularProgress />
        </Box>
    );
}
