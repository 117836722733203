import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Loader } from '../loader';

export interface SideDrawerTableData {
    header: string;
    footer: string;
    rows: {
        label: string | number;
        value: string | number;
        alert?: boolean;
    }[];
}

export interface SideDrawerTableProps {
    tableData?: SideDrawerTableData[];
    loading?: boolean;
}

const headerStyle = {
    backgroundColor: '#FEF6ED',
    borderBottom: '1px solid lightgray',
    color: '#F08B1D',
    fontSize: '1.1em',
    padding: '10px 0px',
    textAlign: 'center',
};

const footerStyle = {
    color: '#A9A9A9',
    fontSize: '.9em',
    padding: '10px 0px',
    textAlign: 'center',
    fontStyle: 'italic',
};

const tableStyle = {
    display: 'grid',
    fontSize: '.9em',
    gridTemplateColumns: '1fr 1fr',
    minWidth: 500,
};

const rowLabelStyle = {
    borderBottom: '1px solid lightgray',
    fontWeight: 600,
    padding: 0.8,
};

const rowValueStyle = {
    borderBottom: '1px solid lightgray',
    padding: 0.8,
};

const rowValueSeperatorStyle = {
    color: 'lightgray',
    paddingRight: 5,
};

const alertRowStyle = {
    backgroundColor: '#FEF6ED',
    color: '#F08B1D',
};

export function SideDrawerTable({ tableData, loading }: SideDrawerTableProps) {
    return (
        <>
            {loading ? (
                <Stack justifyContent='center' sx={{ height: '100vh', minWidth: 500 }}>
                    <Loader />
                </Stack>
            ) : tableData ? (
                tableData.map(({ header, rows, footer }, i) => {
                    return (
                        <Box key={i} style={{ padding: 20 }}>
                            <Box sx={headerStyle}>{header}</Box>
                            <Box sx={tableStyle}>
                                {rows.map((row, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <Box sx={[rowLabelStyle, row.alert ? alertRowStyle : {}]}>{row.label}</Box>
                                            <Box sx={[rowValueStyle, row.alert ? alertRowStyle : {}]}>
                                                <span style={rowValueSeperatorStyle}>|</span> {row.value}
                                            </Box>
                                        </React.Fragment>
                                    );
                                })}
                            </Box>
                            <Box sx={footerStyle}>{footer}</Box>
                        </Box>
                    );
                })
            ) : (
                <Box sx={{ padding: 40 }}>Information not found</Box>
            )}
        </>
    );
}
