import { getGridBooleanOperators } from '@mui/x-data-grid-premium';
import {
    InsightGridColDef,
    getEqualsGridStringOperators,
    getLimitedGridNumericOperators,
    getLimitedGridStringOperators,
} from '@price-for-profit/data-grid';
import { CellPopover } from 'shared/components';
import { CostSummary } from 'shared/types';
import {
    ColumnFormatterBoolean,
    ColumnFormatterExchangeRate,
    ColumnFormatterMoneyDecimals,
    ColumnFormatterPercentDecimals,
    ColumnFormatterSlashDate,
    ColumnSetterExchangeRate,
    PercentageColumn,
    PriceColumn,
    formatDecimal,
    formatExchangeRate,
    formatPercent,
} from 'shared/utility';
import { getCostPopoverWarnings, getCostWarnings, hasCostWarnings } from 'shared/utility/helpers-cost';
import { MillSupplierDropdown } from './mill-supplier-dropdown';
import { SurchargeDropdown } from './surcharge-dropdown';

interface CostMasterColumnsProps {
    user: drive.UserInfo;
    exchangeRate: number;
}

export function CostMasterColumns({ user, exchangeRate }: CostMasterColumnsProps): InsightGridColDef<CostSummary>[] {
    const editable = user.coe || user.procurement || user.canada_procurement;

    return [
        {
            field: 'priceBook',
            headerName: 'Price Book',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'bw',
            headerName: 'BW',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
            renderCell: ({ row }) => {
                const value = row.bw;
                return value && <CellPopover value={value} popoverMessage={row.bwDesc} />;
            },
        },
        {
            field: 'bwDesc',
            headerName: 'BW Description',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'owner',
            headerName: 'Owner',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'primaryMillSupplier',
            headerName: 'Primary Mill Supplier',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
            editable: editable,
            renderEditCell: params => <MillSupplierDropdown {...params} />,
        },
        {
            field: 'secondaryMillSupplier',
            headerName: 'Secondary Mill Supplier',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
            editable: editable,
            renderEditCell: params => <MillSupplierDropdown {...params} />,
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'form',
            headerName: 'Form',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'productCategory',
            headerName: 'Product Category',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'surcharge',
            headerName: 'Primary Surcharge Current',
            width: 200,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'primarySurchargeNew',
            headerName: 'Primary Surcharge New',
            width: 200,
            filterOperators: getLimitedGridStringOperators(),
            editable: editable,
            renderEditCell: params => <SurchargeDropdown {...params} />,
            cellClassName: ({ row }) => (row.surcharge !== row.primarySurchargeNew ? 'values-diff' : ''),
        },
        {
            field: 'secondarySurchargeCurrent',
            headerName: 'Secondary Surcharge Current',
            width: 200,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'secondarySurchargeNew',
            headerName: 'Secondary Surcharge New',
            width: 200,
            filterOperators: getLimitedGridStringOperators(),
            editable: editable,
            renderEditCell: params => <SurchargeDropdown {...params} />,
            cellClassName: ({ row }) =>
                row.secondarySurchargeCurrent !== row.secondarySurchargeNew ? 'values-diff' : '',
        },
        {
            field: 'warnings',
            headerName: 'Warnings',
            width: 300,
            filterable: false,
            sortable: false,
            filterOperators: getLimitedGridStringOperators(),
            cellClassName: ({ row }) => (hasCostWarnings(row) ? 'cell-warning' : ''),
            renderCell: ({ row }) => {
                return <CellPopover value={getCostWarnings(row)} popoverMessage={getCostPopoverWarnings(row)} />;
            },
        },
        {
            field: 'primaryBaseConversionCurrent',
            headerName: 'Primary Base / Conversion (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'primaryBaseConversionNew',
            headerName: 'Primary Base / Conversion (New)',
            width: 150,
            type: 'number',
            editable: editable,
            valueSetter: (value, row) => ColumnSetterExchangeRate(value, row, exchangeRate, 'primaryBaseConversionNew'),
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatDecimal(row.primaryBaseConversionCurrent, 2, 2) !==
                formatDecimal(row.primaryBaseConversionNew, 2, 2)
                    ? 'values-diff'
                    : '',
            renderEditCell: params => <PriceColumn {...params} />,
        },
        {
            field: 'secondaryBaseConversionCurrent',
            headerName: 'Secondary Base / Conversion (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'secondaryBaseConversionNew',
            headerName: 'Secondary Base / Conversion (New)',
            width: 150,
            type: 'number',
            editable: editable,
            valueSetter: (value, row) =>
                ColumnSetterExchangeRate(value, row, exchangeRate, 'secondaryBaseConversionNew'),
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatDecimal(row.secondaryBaseConversionCurrent, 2, 2) !==
                formatDecimal(row.secondaryBaseConversionNew, 2, 2)
                    ? 'values-diff'
                    : '',
            renderEditCell: params => <PriceColumn {...params} />,
        },
        {
            field: 'primaryAllocationCurrent',
            headerName: 'Primary Allocation (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterPercentDecimals(value, 1),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'primaryAllocationNew',
            headerName: 'Primary Allocation (New)',
            width: 150,
            type: 'number',
            editable: editable,
            valueFormatter: value => ColumnFormatterPercentDecimals(value, 1),
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatPercent(row.primaryAllocationCurrent, 1, 1) !== formatPercent(row.primaryAllocationNew, 1, 1)
                    ? 'values-diff'
                    : '',
            renderEditCell: params => <PercentageColumn {...params} />,
        },
        {
            field: 'baseConversionCurrent',
            headerName: 'Base / Conversion (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'baseConversionNew',
            headerName: 'Base / Conversion (New)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'surchargeIngotCurrent',
            headerName: 'Surcharge / Ingot (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'surchargeIngotNew',
            headerName: 'Surcharge / Ingot (New)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'processingCurrent',
            headerName: 'Processing (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'processingNew',
            headerName: 'Processing (New)',
            width: 150,
            type: 'number',
            editable: editable,
            valueSetter: (value, row) => ColumnSetterExchangeRate(value, row, exchangeRate, 'processingNew'),
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatDecimal(row.processingCurrent, 2, 2) !== formatDecimal(row.processingNew, 2, 2)
                    ? 'values-diff'
                    : '',
            renderEditCell: params => <PriceColumn {...params} />,
        },
        {
            field: 'otherExtrasCurrent',
            headerName: 'Other / Extras (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'otherExtrasNew',
            headerName: 'Other / Extras (New)',
            width: 150,
            type: 'number',
            editable: editable,
            valueSetter: (value, row) => ColumnSetterExchangeRate(value, row, exchangeRate, 'otherExtrasNew'),
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatDecimal(row.otherExtrasCurrent, 2, 2) !== formatDecimal(row.otherExtrasNew, 2, 2)
                    ? 'values-diff'
                    : '',
            renderEditCell: params => <PriceColumn {...params} />,
        },
        {
            field: 'inboundFreightCurrent',
            headerName: 'Primary Inbound Freight Incl. Fuel S/C (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'inboundFreightNew',
            headerName: 'Primary Inbound Freight Incl. Fuel S/C (New)',
            width: 150,
            type: 'number',
            editable: editable,
            valueSetter: (value, row) => ColumnSetterExchangeRate(value, row, exchangeRate, 'inboundFreightNew'),
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatDecimal(row.inboundFreightCurrent, 2, 2) !== formatDecimal(row.inboundFreightNew, 2, 2)
                    ? 'values-diff'
                    : '',
            renderEditCell: params => <PriceColumn {...params} />,
        },
        {
            field: 'secondaryInboundFreightCurrent',
            headerName: 'Secondary Inbound Freight Incl. Fuel S/C (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'secondaryInboundFreightNew',
            headerName: 'Secondary Inbound Freight Incl. Fuel S/C (New)',
            width: 150,
            type: 'number',
            editable: editable,
            valueSetter: (value, row) =>
                ColumnSetterExchangeRate(value, row, exchangeRate, 'secondaryInboundFreightNew'),
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatDecimal(row.secondaryInboundFreightCurrent, 2, 2) !==
                formatDecimal(row.secondaryInboundFreightNew, 2, 2)
                    ? 'values-diff'
                    : '',
            renderEditCell: params => <PriceColumn {...params} />,
        },
        {
            field: 'baseCostCurrent',
            headerName: 'Regional Replacement Cost (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'baseCostLastChange',
            headerName: 'Regional RC Last Change',
            width: 200,
            valueGetter: ColumnFormatterSlashDate,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'baseCostNew',
            headerName: 'Regional Replacement Cost (New)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'baseCostHoldPrice',
            headerName: 'Regional Replacement Cost Hold Price',
            width: 150,
            type: 'boolean',
            editable: editable,
            valueGetter: ColumnFormatterBoolean,
            filterOperators: getEqualsGridStringOperators(),
        },
        {
            field: 'replacementCostChange',
            headerName: 'Replacement Cost Change',
            width: 150,
            valueGetter: (_, row) => {
                const value = Number(row.replacementCostChange.toFixed(2));

                return formatExchangeRate(value === 0 ? Math.abs(value) : value, exchangeRate, 2);
            },
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'marketMovementCurrent',
            headerName: 'Market Movement - CRU (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'marketMovementLastChange',
            headerName: 'Market Movement - CRU Last Change',
            width: 150,
            valueGetter: ColumnFormatterSlashDate,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'marketMovementNew',
            headerName: 'Market Movement - CRU (New)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'marketMovementHoldPrice',
            headerName: 'Market Movement Hold Price',
            width: 150,
            type: 'boolean',
            editable: editable,
            valueGetter: ColumnFormatterBoolean,
            filterOperators: getEqualsGridStringOperators(),
        },
        {
            field: 'marketMovementAdHocCurrent',
            headerName: 'Market Movement (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'marketMovementAdHocNew',
            headerName: 'Market Movement (New)',
            width: 150,
            type: 'number',
            editable: editable,
            valueSetter: (value, row) => ColumnSetterExchangeRate(value, row, exchangeRate, 'marketMovementAdHocNew'),
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatDecimal(row.marketMovementAdHocCurrent, 2, 2) !== formatDecimal(row.marketMovementAdHocNew, 2, 2)
                    ? 'values-diff'
                    : '',
            renderEditCell: params => <PriceColumn {...params} />,
        },
        {
            field: 'marketMovementAdHocLastChange',
            headerName: 'Market Movement Last Change',
            width: 150,
            valueGetter: ColumnFormatterSlashDate,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'changeCount',
            headerName: 'Change Count',
            width: 150,
            type: 'number',
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'updatedByEmail',
            headerName: 'Most Recent Change By',
            width: 150,
        },
        {
            field: 'baseCostMaxChangeWarning',
            headerName: 'Warning: Base Cost Max Change',
            type: 'singleSelect',
            filterOperators: getGridBooleanOperators(),
            hideable: false,
        },
        {
            field: 'baseCostAdjustedWarning',
            headerName: 'Warning: Base Cost Adjusted',
            type: 'singleSelect',
            filterOperators: getGridBooleanOperators(),
            hideable: false,
        },
        {
            field: 'marketMovementMaxChangeWarning',
            filterOperators: getGridBooleanOperators(),
            type: 'singleSelect',
            headerName: 'Warning: Market Movement Max Change',
            hideable: false,
        },
        {
            field: 'pendingFutureEffectiveDateWarning',
            filterOperators: getGridBooleanOperators(),
            type: 'singleSelect',
            headerName: 'Warning: Pending future effective (date) associated',
            hideable: false,
        },
        {
            field: 'mappedMaterialsCount',
            headerName: 'Count of Mapped Materials',
            width: 150,
            type: 'number',
            filterOperators: getLimitedGridNumericOperators(),
        },
    ];
}
