import { ServerSideState, generateCollectionFilter } from '@price-for-profit/data-grid';
import {
    DataAccessPaginatedResponse,
    IDataAccessService,
    IExportService,
    IFilter,
    NoInfer,
    compare,
    getData,
} from '@price-for-profit/micro-services';
import {
    DATASTORE_SQL,
    SP_KEY_PRICE_MASTER_MASS_ACTION,
    SP_KEY_SUBMIT_WORKING_PRICE_MASTER_SUMMARY,
    TABLE_EXCHANGE_RATES,
    TABLE_MONTHLY_PERFORMANCE_METRIC,
    TABLE_PRICE_MASTER,
    TABLE_PRICE_MASTER_CLCODE_PRICES,
    TABLE_WEEKLY_PERFORMANCE_METRIC,
    VIEW_PRICE_MASTER_SUMMARY,
    VIEW_PRICE_MASTER_WARNING_SUMMARY,
    VIEW_SALES_OFFICE_OPTIONS,
} from 'shared/constants/services';
import {
    CostSummary,
    DropdownOption,
    ExchangeRate,
    IDownloadFileProps,
    MultiMarketProductsProps,
    PerformanceMetrics,
    PerformanceMetricsProps,
    Price,
    PriceMasterClCodeWeightPrices,
    PriceMasterExcelExport,
    PriceMasterExportExcelProps,
    PriceMasterMassActionParameters,
    PriceMetrics,
    PriceSummary,
    PriceUpdate,
    SqlExchangeRate,
    SubmitPriceMasterParameters,
    UpdatePriceMasterParameters,
} from 'shared/types';
import { getPriceMasterExportData, handleBlob } from 'shared/utility';

export interface IPriceService {
    getPriceData(state: ServerSideState): Promise<DataAccessPaginatedResponse<PriceSummary>>;
    massAction(state: ServerSideState, parameters: PriceMasterMassActionParameters): Promise<void>;
    getPriceWarningData(): Promise<PriceMetrics>;
    getPriceCollectionFromCostMaster(costMaster: CostSummary): Promise<PriceSummary[]>;
    saveRows({ newRows, oldRows, user }: UpdatePriceMasterParameters): Promise<void>;
    submitPriceMaster(props: SubmitPriceMasterParameters): Promise<void>;
    getExchangeRates(): Promise<ExchangeRate>;
    getPerformanceMetrics(props: PerformanceMetricsProps): Promise<PerformanceMetrics>;
    getProductsForMultimarket(props: MultiMarketProductsProps): Promise<Price[]>;
    getSalesOfficeOptions(): Promise<DropdownOption[]>;
    exportPriceMaster({ filterModel, exchangeRate }: PriceMasterExportExcelProps): Promise<void>;
    exportExcel<T>(props: IDownloadFileProps<T>): Promise<void>;
    getCLPricesByWeight(priceMasterId: string): Promise<PriceMasterClCodeWeightPrices[]>;
}

type UpdatedPriceFields = Pick<
    PriceUpdate,
    | 'changeCount'
    | 'targetMarginNew'
    | 'bookPriceNew'
    | 'floorPriceNew'
    | 'floorMarginNew'
    | 'floorMarginAllInNew'
    | 'finalTargetMargin'
    | 'finalBookPrice'
    | 'finalFloorPrice'
    | 'finalFloorMargin'
    | 'finalFloorMarginAllIn'
    | 'floorVsBookPriceDiff'
    | 'marketPriceRecommendation'
    | 'updatedBy'
    | 'updatedByEmail'
    | 'updatedDate'
>;

function priceSummaryToUpdatePrice(view: PriceSummary): UpdatedPriceFields {
    return {
        changeCount: view.changeCount,
        targetMarginNew: view.targetMarginNew,
        bookPriceNew: view.bookPriceNew,
        floorPriceNew: view.floorPriceNew,
        floorMarginNew: view.floorMarginNew,
        finalTargetMargin: view.finalTargetMargin,
        finalBookPrice: view.finalBookPrice,
        finalFloorPrice: view.finalFloorPrice,
        finalFloorMargin: view.finalFloorMargin,
        floorVsBookPriceDiff: view.floorVsBookPriceDiff,
        floorMarginAllInNew: view.floorMarginAllInNew,
        finalFloorMarginAllIn: view.finalFloorMarginAllIn,
        marketPriceRecommendation: view.marketPriceRecommendation,
        updatedDate: '',
        updatedBy: '',
        updatedByEmail: '',
    };
}

function updatePriceMetadataFields(editedPriceValues: UpdatedPriceFields, user: drive.UserInfo): PriceUpdate {
    const today = new Date().toISOString();
    return {
        ...editedPriceValues,
        updatedDate: today,
        updatedBy: user.id,
        updatedByEmail: user.email,
    };
}
export class PriceService implements IPriceService {
    constructor(
        private dasService: IDataAccessService,
        private exportService: IExportService,
        private clientId: string
    ) {}

    async getPriceData(state: ServerSideState): Promise<DataAccessPaginatedResponse<PriceSummary>> {
        return await this.dasService.getCollection<PriceSummary, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: VIEW_PRICE_MASTER_SUMMARY,
            page: state.paginationModel.page,
            pageSize: state.paginationModel.pageSize,
            sortBy: state.sortModel[0]?.field as NoInfer<keyof PriceSummary>,
            sortDescending: state.sortModel[0]?.sort === 'desc' ? true : false,
            collectionFilter: generateCollectionFilter<PriceSummary>(state.filterModel, undefined),
        });
    }

    async massAction(state: ServerSideState, parameters: PriceMasterMassActionParameters) {
        await this.dasService.executeMassAction<Price, PriceMasterMassActionParameters>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: VIEW_PRICE_MASTER_SUMMARY,
            queryName: SP_KEY_PRICE_MASTER_MASS_ACTION,
            parameters,
            page: state.paginationModel.page,
            pageSize: state.paginationModel.pageSize,
            collectionFilter: generateCollectionFilter<Price>(state.filterModel, undefined),
        });
    }

    async getPriceCollectionFromCostMaster(costMaster: CostSummary): Promise<PriceSummary[]> {
        const baseFilter: IFilter<PriceSummary> = {
            logicalOperator: 'and',
            filters: [
                { property: 'bw', operator: 'eq', value: costMaster.bw },
                { property: 'priceBook', operator: 'eq', value: costMaster.priceBook },
            ],
        };

        const { data } = await this.dasService.getCollection<PriceSummary, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: VIEW_PRICE_MASTER_SUMMARY,
            collectionFilter: baseFilter,
            pageSize: 1000,
        });

        return data;
    }

    async getPriceWarningData(): Promise<PriceMetrics> {
        const { data } = await this.dasService.getCollection<PriceMetrics, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: VIEW_PRICE_MASTER_WARNING_SUMMARY,
        });
        return data[0];
    }

    async getExchangeRates(): Promise<ExchangeRate> {
        const { data } = await this.dasService.getCollection<SqlExchangeRate, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_EXCHANGE_RATES,
        });

        // Sort and get the latest exchange rate
        const latestCadRate = data.sort((a, b) => a.year - b.year || a.month - b.month).pop();

        // CAD only right now
        return {
            cad: latestCadRate ? latestCadRate.exchangeRate : 0,
            usd: 1.0,
        };
    }

    async getPerformanceMetrics(props: PerformanceMetricsProps): Promise<PerformanceMetrics> {
        const tableId = props.monthly ? TABLE_MONTHLY_PERFORMANCE_METRIC : TABLE_WEEKLY_PERFORMANCE_METRIC;
        const filter: IFilter<PerformanceMetrics> = {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'bw',
                    operator: 'eq',
                    value: props.bw,
                },
                {
                    property: 'so',
                    operator: 'eq',
                    value: props.so,
                },
            ],
        };

        const { data } = await this.dasService.getCollection<PerformanceMetrics, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: tableId,
            collectionFilter: filter,
        });

        return data[0];
    }

    async getProductsForMultimarket(props: MultiMarketProductsProps): Promise<Price[]> {
        const filter: IFilter<Price> = {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'multiMarket',
                    operator: 'eq',
                    value: props.multiMarket,
                },
                {
                    property: 'bw',
                    operator: 'eq',
                    value: props.bw,
                },
                {
                    property: 'deletedDate',
                    operator: 'isnull',
                    value: undefined,
                },
            ],
        };

        const { data } = await this.dasService.getCollection<Price, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_PRICE_MASTER,
            collectionFilter: filter,
        });

        return data;
    }

    async getCLPricesByWeight(priceMasterId: string): Promise<PriceMasterClCodeWeightPrices[]> {
        const filter: IFilter<PriceMasterClCodeWeightPrices> = {
            property: 'priceMasterId',
            operator: 'eq',
            value: priceMasterId,
        };

        const { data } = await this.dasService.getCollection<PriceMasterClCodeWeightPrices, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_PRICE_MASTER_CLCODE_PRICES,
            collectionFilter: filter,
        });

        return data;
    }

    async saveRows({ newRows, oldRows, user }: UpdatePriceMasterParameters): Promise<void> {
        if (newRows.length > 0) {
            await Promise.all(
                newRows.map(row => {
                    const oldRow = oldRows.find(x => x.id === row.id);
                    if (oldRow !== undefined) {
                        const [newPrice, oldPrice] = [row, oldRow].map(priceSummaryToUpdatePrice);
                        const priceOperations = compare(oldPrice, updatePriceMetadataFields(newPrice, user));
                        return this.dasService.patchRow<Price, typeof DATASTORE_SQL>({
                            clientId: this.clientId,
                            databaseLabel: DATASTORE_SQL,
                            tableId: TABLE_PRICE_MASTER,
                            key: row.id,
                            operations: priceOperations,
                        });
                    }
                    return Promise.reject();
                })
            );
        }
    }

    async submitPriceMaster(props: SubmitPriceMasterParameters): Promise<void> {
        await this.dasService.executeNamedQuery<SubmitPriceMasterParameters, void>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            queryName: SP_KEY_SUBMIT_WORKING_PRICE_MASTER_SUMMARY,
            parameters: props,
        });
    }

    async getSalesOfficeOptions(): Promise<DropdownOption[]> {
        const { data } = await this.dasService.getCollection<DropdownOption, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: VIEW_SALES_OFFICE_OPTIONS,
            pageSize: 1000,
        });
        return data;
    }

    async exportPriceMaster({ filterModel, exchangeRate }: PriceMasterExportExcelProps): Promise<void> {
        const today = new Date().toLocaleDateString();
        const data = await getData(page =>
            this.dasService.getCollection<PriceSummary, typeof DATASTORE_SQL>({
                clientId: this.clientId,
                databaseLabel: DATASTORE_SQL,
                tableId: VIEW_PRICE_MASTER_SUMMARY,
                page: page,
                pageSize: 1000,
                enableTotalCount: true,
                collectionFilter: generateCollectionFilter<PriceSummary>(filterModel),
            })
        );

        const priceData: PriceMasterExcelExport = getPriceMasterExportData(data, exchangeRate);

        return await this.exportExcel({
            exportId: 'price-export',
            data: priceData,
            fileName: `Complete Price Master ${today}`,
        });
    }

    async exportExcel<T>({ exportId, data, fileName }: IDownloadFileProps<T>) {
        const blob = await this.exportService.downloadExcel<T>({
            clientId: this.clientId,
            templateId: exportId,
            payload: data,
        });
        handleBlob(blob, `${fileName}.xlsx`);
    }
}
