import { Check, Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useCostMasterTableState } from './table-def';

interface CostMassActionConfirmationDialogProps {
    isConfirmationOpened: boolean;
    onConfirmationClose: (confirm: boolean) => void;
}

export function CostMassActionConfirmationDialog({
    isConfirmationOpened,
    onConfirmationClose,
}: CostMassActionConfirmationDialogProps) {
    const tableState = useCostMasterTableState();

    return (
        <Dialog onClose={() => onConfirmationClose(false)} open={isConfirmationOpened}>
            <DialogTitle sx={{ color: 'primary.main' }}>Warning</DialogTitle>
            <DialogContent>You are about to edit {tableState.filteredRowsCount.get()} items.</DialogContent>
            <DialogActions>
                <Button startIcon={<Close />} onClick={() => onConfirmationClose(false)}>
                    Cancel
                </Button>
                <Button startIcon={<Check />} onClick={() => onConfirmationClose(true)}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
