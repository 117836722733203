import { DEFAULT_ROUTES } from '@price-for-profit/drive-app-core';

export const ROUTES = {
    ...DEFAULT_ROUTES,
    costMaster: '/cost-master',
    costMasterFuture: '/cost-master-future',
    priceMaster: '/price-master',
    surchargeMaster: '/surcharge-master',
    warningConfiguration: '/warning-configuration',
} as const;
