import { useService } from '@price-for-profit/service-provider';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { QUERYKEY_SURCHARGE_OPTIONS } from 'shared/constants';
import { DropdownOption } from 'shared/types';

export function useGetSurchargeOptions() {
    const { surchargeService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const { data, isError } = useQuery({
        queryKey: [QUERYKEY_SURCHARGE_OPTIONS],
        queryFn: () => surchargeService.getAllSurcharges(),
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Failed to fetch surcharges dropdown options.', { variant: 'error' });
        }
    }, [isError, enqueueSnackbar]);

    const surchargeOptions: DropdownOption[] = [
        {
            uniqueId: 'No surcharge',
            value: 'No surcharge',
        },
        ...(data || []).map<DropdownOption>(item => ({
            uniqueId: item.id,
            value: item.surchargeDescription,
        })),
    ];

    return {
        data: surchargeOptions,
    };
}
