import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { QUERYKEY_MASS_ACTION_STATUS } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';
import {
    getMassActionCorrelationId,
    isFinalizedStatus,
    isSuccessStatus,
    setMassActionCorrelationId,
} from 'shared/utility';

const SECOND = 1000;

export function useGetMassActionEnabled() {
    const { eventStatusService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const correlationId = getMassActionCorrelationId() || '';
    const { data } = useQuery({
        queryKey: [QUERYKEY_MASS_ACTION_STATUS, correlationId],
        queryFn: () => eventStatusService.getStatusByCorrelationId(correlationId),
        enabled: correlationId !== '',
        refetchInterval: query => {
            const data = query.state.data;
            if (isFinalizedStatus(data)) {
                if (isSuccessStatus(data)) {
                    enqueueSnackbar('Mass action completed successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(`Mass action failed to complete. Correlation ID: ${correlationId}`, {
                        variant: 'error',
                    });
                }
                setMassActionCorrelationId('');
                return 0;
            }
            return SECOND * 10;
        },
    });

    return correlationId === '' || isFinalizedStatus(data);
}
