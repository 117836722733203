import { useService } from '@price-for-profit/service-provider';
import { useQuery } from '@tanstack/react-query';
import { QUERYKEY_CLIENT_USER_RESTRICTED } from 'shared/constants';

export function useGetClientUserRestricted(userId: string) {
    const { userAuthorizationService } = useService();

    return useQuery({
        queryKey: [QUERYKEY_CLIENT_USER_RESTRICTED, userId],
        queryFn: () => userAuthorizationService.getUserAttributes(userId),
    });
}
