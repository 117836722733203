import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface MassActionDisabledDialogProps {
    isDialogOpened: boolean;
    onDialogClose: () => void;
}

export function MassActionDisabledDialog({ isDialogOpened, onDialogClose }: MassActionDisabledDialogProps) {
    return (
        <Dialog onClose={() => onDialogClose()} open={isDialogOpened}>
            <DialogTitle sx={{ color: 'primary.main' }}>In Progress...</DialogTitle>
            <DialogContent>Mass action in progress, please continue waiting.</DialogContent>
            <DialogActions>
                <Button startIcon={<Close />} onClick={() => onDialogClose()}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
