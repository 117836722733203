import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { QUERYKEY_COST_FUTURE_RATE_METADATA } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';
import { useEffect } from 'react';

export function useGetCostMasterFutureRateMetadata(includeProcessed: boolean = false) {
    const { costService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const { data, isError } = useQuery({
        queryKey: [QUERYKEY_COST_FUTURE_RATE_METADATA],
        queryFn: () => costService.getCostFutureRateMetadata(includeProcessed),
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Failed to fetch cost future rates metadata.', { variant: 'error' });
        }
    }, [isError, enqueueSnackbar]);

    if (data === undefined) {
        return { data: [], rowCount: 0 };
    }

    return {
        data: data.data,
    };
}
