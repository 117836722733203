import { Check, Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface PriceMassActionConfirmationDialogProps {
    isConfirmationOpened: boolean;
    onConfirmationClose: (confirm: boolean) => void;
    filteredRowsCount: number;
}

export function PriceMassActionConfirmationDialog({
    isConfirmationOpened,
    onConfirmationClose,
    filteredRowsCount,
}: PriceMassActionConfirmationDialogProps) {
    return (
        <Dialog onClose={() => onConfirmationClose(false)} open={isConfirmationOpened}>
            <DialogTitle sx={{ color: 'primary.main' }}>Warning</DialogTitle>
            <DialogContent>You are about to edit {filteredRowsCount} items.</DialogContent>
            <DialogActions>
                <Button startIcon={<Close />} onClick={() => onConfirmationClose(false)}>
                    Cancel
                </Button>
                <Button startIcon={<Check />} onClick={() => onConfirmationClose(true)}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
