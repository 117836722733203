import { EventData } from '@price-for-profit/micro-services';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import { useService } from '@price-for-profit/service-provider';
import { EventServiceTriggerAdfParameters } from 'shared/types';
import { setMassActionCorrelationId } from 'shared/utility';

export function useEventTriggerAdfPipeline() {
    const { enqueueSnackbar } = useSnackbar();
    const { eventStatusService } = useService();

    const mutation = useMutation<EventData, Error, EventServiceTriggerAdfParameters>({
        mutationFn: req => eventStatusService.triggerADFPipeline(req),
        onError: () => {
            enqueueSnackbar('Failed to communicate with data pipeline', { variant: 'error' });
            setMassActionCorrelationId('');
        },
    });

    return mutation;
}
