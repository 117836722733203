import { useService } from '@price-for-profit/service-provider';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { QUERYKEY_SALES_OFFICE_OPTIONS } from 'shared/constants';

export function useGetSalesOfficeOptions() {
    const { priceService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const { data, isError } = useQuery({
        queryKey: [QUERYKEY_SALES_OFFICE_OPTIONS],
        queryFn: () => priceService.getSalesOfficeOptions(),
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Failed to fetch sales office filter dropdown options.', { variant: 'error' });
        }
    }, [isError, enqueueSnackbar]);

    return { data };
}
