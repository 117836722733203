import { useAuthStore, useUser } from '@price-for-profit/auth';
import { PropsWithChildren, useEffect } from 'react';
import { useGetClientUserRestricted } from 'shared/queries';

export const SetUserAssignment = ({ children }: PropsWithChildren<{}>) => {
    const user = useUser();
    const setUser = useAuthStore(store => store.setUser);
    const { data } = useGetClientUserRestricted(user?.id || 'invalid user id');

    useEffect(() => {
        if (user && typeof data !== 'undefined') {
            if (typeof user.multiMarket === 'undefined') {
                const userWithMultiMarket = { ...user, multiMarket: data.multimarket };
                setUser(userWithMultiMarket);
            }

            if (typeof user.office === 'undefined') {
                const userWithOffice = { ...user, office: data.office };
                setUser(userWithOffice);
            }
        }
    }, [data, setUser, user]);

    return <>{children}</>;
};
