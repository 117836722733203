import { Box, Popover, Typography } from '@mui/material';
import { useState } from 'react';

interface CellPopoverProps {
    value: string;
    popoverMessage: string;
}

export function CellPopover({ value, popoverMessage }: CellPopoverProps) {
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

    const handlePopoverOpen = (event: any) => {
        setPopoverAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    };

    return (
        <>
            <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} sx={{ width: '100%' }}>
                {value}
            </Box>
            <Popover
                anchorEl={popoverAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                disableRestoreFocus
                sx={{ pointerEvents: 'none' }}
                onClose={handlePopoverClose}
                open={Boolean(popoverAnchorEl)}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                disableEnforceFocus
            >
                <Typography sx={{ p: 1, textAlign: 'center', maxWidth: '450px', whiteSpace: 'pre-line' }}>
                    {popoverMessage}
                </Typography>
            </Popover>
        </>
    );
}
