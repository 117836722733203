import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERYKEY_COST_FUTURE_RATE, QUERYKEY_COST_FUTURE_RATE_METADATA } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';
import { ApplyCostFutureRateParams } from 'shared/types';
import { setMassActionCorrelationId } from 'shared/utility';

export function useApplyCostMasterFutureRates() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { costService } = useService();

    return useMutation<void, Error, ApplyCostFutureRateParams>({
        mutationFn: request => costService.applyCostMasterFutureRates(request),
        onSuccess: () => {
            enqueueSnackbar('Applying future date rates', {
                variant: 'info',
            });
        },
        onError: () => {
            enqueueSnackbar('Future date rates failed to apply', { variant: 'error' });
            setMassActionCorrelationId('');
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST_FUTURE_RATE] });
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST_FUTURE_RATE_METADATA] });
        },
    });
}
