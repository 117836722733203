import { useService } from '@price-for-profit/service-provider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERYKEY_PRICE, QUERYKEY_PRICE_WARNING_SUMMARY } from 'shared/constants';
import { UpdatePriceMasterParameters } from 'shared/types';

export function useSavePriceMasterRows() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { priceService } = useService();

    const mutation = useMutation<void, Error, UpdatePriceMasterParameters>({
        mutationFn: request => priceService.saveRows(request),
        onSuccess: () => {
            enqueueSnackbar('Price fields calculated and saved', { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar('Price fields failed to calculate and save', { variant: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_PRICE] });
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_PRICE_WARNING_SUMMARY] });
        },
    });

    return mutation;
}
