import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { EditCellDropdown } from 'shared/components';
import { useGetMillSupplierOptions } from 'shared/queries';
import { CostSummary } from 'shared/types';

export function MillSupplierDropdown(params: GridRenderCellParams<CostSummary, string>) {
    const { data } = useGetMillSupplierOptions();

    if (data !== undefined) {
        return <EditCellDropdown data={data} params={params}></EditCellDropdown>;
    } else {
        return <Box>Loading...</Box>;
    }
}
