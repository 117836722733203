import { CalculationPayload, CalculationResponse, ICalculationService } from '@price-for-profit/micro-services';

export interface CalculateProps<T> {
    modelId: string;
    payload: CalculationPayload<T>;
}

export interface ICalcService {
    calculate<I, O>(props: CalculateProps<I>): Promise<CalculationResponse<O>>;
}

export class CalcService implements ICalcService {
    constructor(private calculationService: ICalculationService, private clientId: string) {}

    async calculate<I, O>({ modelId, payload }: CalculateProps<I>) {
        return await this.calculationService.calculate<I, O>({ clientId: this.clientId, modelId, payload });
    }
}
