import { LinearProgress, Paper, Stack, Typography } from '@mui/material';
import {
    DataGridPremium,
    GridFilterModel,
    GridPinnedColumnFields,
    GridSlots,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import { PAGE_SIZE_OPTIONS, useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { useService } from '@price-for-profit/service-provider';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CurrencyToggle, QuickFilter, QuickFilterRef } from 'shared/components';
import { WarningsQuickFilter } from 'shared/components/warnings-quick-filter';
import {
    QUERYKEY_COST_FUTURE_RATE,
    WARNING_BASE_COST_ADJUSTED,
    WARNING_BASE_COST_MAX_CHANGE,
    WARNING_MARKET_MOVEMENT_MAX_CHANGE,
} from 'shared/constants';
import { useGetCostMasterFutureRateMetadata, useGetExchangeRates } from 'shared/queries';
import {
    ApplyCostFutureRateParams,
    CostFutureRateSummary,
    CostSummary,
    FormTypeFilter,
    ProductTypeFilter,
} from 'shared/types';
import {
    CostMasterColumns,
    costMasterColumnVisibilityInitialState,
    useCostMasterTableState,
    CostMasterFutureTableToolbar,
} from './table-def';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { formatSlashDate, generateCorrelationId } from 'shared/utility';
import {
    useSaveCostMasterFutureRateRows,
    useDeleteCostMasterFutureRateRows,
    useEventStatusCreate,
    useEventTriggerAdfPipeline,
    useApplyCostMasterFutureRates,
} from 'shared/mutations';

interface CostMasterFutureTableProps {
    user: drive.UserInfo;
    preferredExchangeRate: number;
    apiRef: React.MutableRefObject<GridApiPremium>;
}

export function CostMasterFutureTable({ user, preferredExchangeRate, apiRef }: CostMasterFutureTableProps) {
    const { costService } = useService();
    const tableState = useCostMasterTableState();
    const [editEnabled, setEditEnabled] = useState(true);
    const queryClient = useQueryClient();
    const exchangeRates = useGetExchangeRates().data;
    const productQuickFilterRef = useRef<QuickFilterRef>(null);
    const formQuickFilterRef = useRef<QuickFilterRef>(null);
    const warningsQuickFilterRef = useRef<QuickFilterRef>(null);
    const metadataIdQuickFilterRef = useRef<QuickFilterRef>(null);
    const [costMasterFutureRatesMetadataId, setCostMasterFutureRatesMetadataId] = useState('');
    const { data: CostMasterFutureRateMetadata } = useGetCostMasterFutureRateMetadata();
    const deleteFutureRatesMutation = useDeleteCostMasterFutureRateRows();
    const createEventStatus = useEventStatusCreate();
    const triggerAdfMutation = useEventTriggerAdfPipeline();
    const createFutureRatesMutation = useApplyCostMasterFutureRates();

    const warningOptions = [
        WARNING_BASE_COST_MAX_CHANGE,
        WARNING_BASE_COST_ADJUSTED,
        WARNING_MARKET_MOVEMENT_MAX_CHANGE,
    ];

    const saveCostChanges = useSaveCostMasterFutureRateRows();

    const handleChangeCount = useCallback((newRow: CostSummary) => {
        let changeCount = 0;
        if (newRow.primaryBaseConversionNew.toFixed(2) !== newRow.primaryBaseConversionCurrent.toFixed(2))
            changeCount++;
        if (newRow.secondaryBaseConversionNew.toFixed(2) !== newRow.secondaryBaseConversionCurrent.toFixed(2))
            changeCount++;
        if (newRow.primaryAllocationNew.toFixed(2) !== newRow.primaryAllocationCurrent.toFixed(2)) changeCount++;
        if (newRow.processingNew.toFixed(2) !== newRow.processingCurrent.toFixed(2)) changeCount++;
        if (newRow.otherExtrasNew.toFixed(2) !== newRow.otherExtrasCurrent.toFixed(2)) changeCount++;
        if (newRow.inboundFreightNew.toFixed(2) !== newRow.inboundFreightCurrent.toFixed(2)) changeCount++;
        if (newRow.secondaryInboundFreightNew.toFixed(2) !== newRow.secondaryInboundFreightCurrent.toFixed(2))
            changeCount++;
        if (newRow.marketMovementAdHocNew.toFixed(2) !== newRow.marketMovementAdHocCurrent.toFixed(2)) changeCount++;

        newRow.changeCount = changeCount;
    }, []);

    const trackEditedRow = useCallback(
        async (newRow: CostFutureRateSummary, oldRow: CostFutureRateSummary) => {
            if (JSON.stringify(newRow) !== JSON.stringify(oldRow)) {
                handleChangeCount(newRow);
                await saveCostChanges.mutateAsync({
                    newRows: [newRow],
                    oldRows: [oldRow],
                    futureRateMetadataId: newRow.costMasterFutureRatesMetadataId,
                });
            }
        },
        [saveCostChanges, handleChangeCount]
    );

    const processRowUpdate = useCallback(
        (newRow: CostFutureRateSummary, oldRow: CostFutureRateSummary) =>
            new Promise<CostFutureRateSummary>(async resolve => {
                try {
                    setEditEnabled(false);
                    await trackEditedRow(newRow, oldRow);
                    resolve(newRow);
                    queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST_FUTURE_RATE] });
                } catch (e) {
                    resolve(oldRow);
                } finally {
                    setEditEnabled(true);
                }
            }),
        [trackEditedRow, queryClient]
    );

    const costMasterColumnInit = useMemo(() => {
        return {
            user,
            exchangeRate: preferredExchangeRate,
            processRowUpdate: processRowUpdate,
        };
    }, [user, preferredExchangeRate, processRowUpdate]);

    const setCurrencyType = (newCurrencyType: 'usd' | 'cad') => {
        const columnVisibilityModel = { ...tableState.columns.columnVisibilityModel.get() };

        costMasterColumnInit.exchangeRate = exchangeRates ? exchangeRates[newCurrencyType] : 1.0;

        apiRef.current.updateColumns(CostMasterColumns(costMasterColumnInit));
        if (columnVisibilityModel) {
            apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
        }
    };

    const costMasterColumns = useMemo(() => {
        return CostMasterColumns(costMasterColumnInit);
    }, [costMasterColumnInit]);

    const { getDataGridProps, state } = useI2pServerDataGrid<CostFutureRateSummary>({
        columns: costMasterColumns,
        name: QUERYKEY_COST_FUTURE_RATE,
        dataGridInitialState: {
            columns: {
                columnVisibilityModel: costMasterColumnVisibilityInitialState,
            },
            pinnedColumns: tableState.pinnedColumns.value as GridPinnedColumnFields,
        },
        pageSizeOptions: [...PAGE_SIZE_OPTIONS, 200],
        initialState: {
            paginationModel: tableState.paginationModel?.value || undefined,
            filterModel: (tableState.filter?.filterModel?.value as GridFilterModel) || undefined,
            sortModel: (tableState.sorting?.sortModel?.value as GridSortModel) || undefined,
        },
        getData: async state => {
            return costService.getCostFutureRateData(state);
        },
    });

    const handleFilterChange = (newFilterModel: GridFilterModel) => {
        const productFilter = newFilterModel.items.find(item => item.field === 'product');
        const formFilter = newFilterModel.items.find(item => item.field === 'form');
        const metadataIdFilter = newFilterModel.items.find(item => item.field === 'costMasterFutureRatesMetadataId');
        const warningsFilter = newFilterModel.items.find(
            item =>
                item.field === 'baseCostMaxChangeWarning' ||
                item.field === 'baseCostAdjustedWarning' ||
                item.field === 'marketMovementMaxChangeWarning'
        );

        if (!productFilter) {
            if (productQuickFilterRef.current) {
                productQuickFilterRef.current.refResetFilter();
            }
        }

        if (!formFilter) {
            if (formQuickFilterRef.current) {
                formQuickFilterRef.current.refResetFilter();
            }
        }

        if (!metadataIdFilter) {
            if (metadataIdQuickFilterRef.current) {
                metadataIdQuickFilterRef.current.refResetFilter();
            }
        }

        if (!warningsFilter) {
            if (warningsQuickFilterRef.current) {
                warningsQuickFilterRef.current.refResetFilter();
            }
        }
    };

    const triggerMassAction = useCallback(async () => {
        const correlationId = generateCorrelationId();
        const params: ApplyCostFutureRateParams = {
            updatedBy: user.id,
            updatedByEmail: user.email,
            costMasterFutureRatesMetadataId,
            correlationId,
        };
        await createFutureRatesMutation.mutateAsync(params);

        await createEventStatus.mutateAsync({
            eventType: 'dataLoad',
            eventTrigger: 'FutureRateStarted',
            actor: user.email,
            correlationId: correlationId,
        });

        await triggerAdfMutation.mutateAsync({
            actor: user.id,
            eventType: 'application.event.triggered',
            subject: 'Build and Submit Batch Process - FutureRate',
            eventBody: { email: user.email, futureRateCorrelationId: correlationId },
            correlationId: correlationId,
        });
    }, [createEventStatus, triggerAdfMutation, createFutureRatesMutation, costMasterFutureRatesMetadataId, user]);

    const handleDiscard = () => {
        if (costMasterFutureRatesMetadataId) deleteFutureRatesMutation.mutate(costMasterFutureRatesMetadataId);
    };

    const handleApply = () => {
        if (costMasterFutureRatesMetadataId) triggerMassAction();
    };

    useEffect(() => {
        handleFilterChange(state.filterModel);
    }, [state.filterModel]);

    const dataGridProps = getDataGridProps();

    return (
        <Paper
            sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                },
                height: 'calc(100vh - 270px)',
                '& .MuiDataGrid-cell--editable': {
                    '& .MuiInputBase-root': {
                        height: '100%',
                    },
                },
                '& .cell-warning': {
                    backgroundColor: 'rgba(244, 67, 54, 0.3)',
                },
                '& .values-diff': {
                    backgroundColor: 'rgba(245, 213, 39, 0.5) !important',
                },
            }}
        >
            <Stack
                direction='row'
                spacing={1}
                paddingTop={1}
                paddingBottom={1}
                alignItems='center'
                paddingLeft={1}
                data-testid='filter-toolbar'
            >
                <CurrencyToggle rates={exchangeRates} setCurrency={setCurrencyType}></CurrencyToggle>
                <QuickFilter
                    label='Product'
                    column='product'
                    apiRef={apiRef}
                    options={Object.values(ProductTypeFilter).map(x => ({ label: x.toString(), value: x.toString() }))}
                    ref={productQuickFilterRef}
                />
                <QuickFilter
                    label='Form'
                    column='form'
                    apiRef={apiRef}
                    options={Object.values(FormTypeFilter).map(x => ({ label: x.toString(), value: x.toString() }))}
                    ref={formQuickFilterRef}
                />
                <WarningsQuickFilter
                    label='Warnings'
                    column='warnings'
                    apiRef={apiRef}
                    options={warningOptions}
                    ref={warningsQuickFilterRef}
                />
                <QuickFilter
                    label='Future Rate Date'
                    column='costMasterFutureRatesMetadataId'
                    apiRef={apiRef}
                    changeCallback={(event: ChangeEvent<HTMLInputElement>) => {
                        setCostMasterFutureRatesMetadataId(event.target.value);
                    }}
                    operator={'equals'}
                    options={
                        CostMasterFutureRateMetadata
                            ? CostMasterFutureRateMetadata.map(cmfr => ({
                                  label: formatSlashDate(new Date(cmfr.effectiveDate)),
                                  value: cmfr.id,
                              }))
                            : []
                    }
                    ref={metadataIdQuickFilterRef}
                />
            </Stack>

            <DataGridPremium
                {...dataGridProps}
                apiRef={apiRef}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: () => (
                        <CostMasterFutureTableToolbar
                            user={user}
                            state={state}
                            handleApply={handleApply}
                            handleDiscard={handleDiscard}
                        />
                    ),
                    loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
                    noRowsOverlay: () => (
                        <Typography sx={{ padding: 2 }}>No rows to display - Select a date</Typography>
                    ),
                }}
                slotProps={{
                    toolbar: {
                        user,
                        state,
                    },
                }}
                onStateChange={state => {
                    if (tableState.filteredRowsCount.value !== state.rows.totalRowCount) {
                        tableState.filteredRowsCount.set(state.rows.totalRowCount);
                    }
                }}
                onColumnVisibilityModelChange={model => tableState.columns.columnVisibilityModel.set(model)}
                isCellEditable={() => editEnabled}
                getRowId={row => row.id + '|' + row.costMasterFutureRatesMetadataId}
            />
        </Paper>
    );
}
