import { hookstate, useHookstate } from '@hookstate/core';
import {
    GridColumnsInitialState,
    GridFilterInitialState,
    GridLogicOperator,
    GridPaginationModel,
    GridPinnedColumnFields,
    GridSortingInitialState,
} from '@mui/x-data-grid-premium';
import { WarningConfiguration } from 'shared/types';

interface WarningConfigurationTableState {
    paginationModel: GridPaginationModel;
    sorting: GridSortingInitialState;
    filter: GridFilterInitialState;
    columns: GridColumnsInitialState;
    pinnedColumns: GridPinnedColumnFields;
}

const warningConfigurationPinnedColumnInitialState: GridPinnedColumnFields = {
    left: [],
    right: [],
};

const warningConfigurationColumnVisibilityInitialState: Record<keyof WarningConfiguration, boolean> = {
    id: false,
    product: true,
    form: true,
    category: true,
    warningMessage: true,
    value: true,
    updatedBy: true,
    updatedByEmail: false,
    updatedDate: true,
    createdBy: false,
    createdDate: false,
};

export const defaultWarningConfigurationTableState: WarningConfigurationTableState = {
    paginationModel: { page: 0, pageSize: 200 },
    sorting: {
        sortModel: [{ field: 'product', sort: 'asc' }],
    },
    filter: {
        filterModel: { items: [], logicOperator: GridLogicOperator.And },
    },
    columns: {
        columnVisibilityModel: warningConfigurationColumnVisibilityInitialState,
    },
    pinnedColumns: warningConfigurationPinnedColumnInitialState,
};

const warningConfigurationTableState = hookstate<WarningConfigurationTableState>(defaultWarningConfigurationTableState);

export function useWarningConfigurationTableState() {
    return useHookstate<WarningConfigurationTableState>(warningConfigurationTableState);
}
