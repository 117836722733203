import { Box, Drawer, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useService } from '@price-for-profit/service-provider';
import { useState } from 'react';
import { CLPriceSideDrawerTable } from 'shared/components';
import { PriceMasterClCodeWeightPrices, PriceSummary } from 'shared/types';
import { CalculateInput, ClCodeWeightsResult } from 'shared/types/model';
import { ColumnFormatterMoneyDecimals, buildModelPayload } from 'shared/utility';

interface FinalBookPriceColProps {
    gridParams: GridRenderCellParams<PriceSummary, number>;
    exchangeRate: number;
}

export function FinalBookPriceCol(props: FinalBookPriceColProps) {
    const decimalPlaces = 2;
    const gridParams = props.gridParams;
    const { priceService, calcService } = useService();
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [currentDrawerData, setCurrentDrawerData] = useState<PriceMasterClCodeWeightPrices[]>([]);
    const [finalDrawerData, setFinalDrawerData] = useState<PriceMasterClCodeWeightPrices[]>([]);
    const [drawerLoading, setDrawerLoading] = useState<boolean>(false);

    // eslint-disable-next-line
    async function onClickInfoButton(): Promise<void> {
        setDrawerOpen(true);
        setDrawerLoading(true);

        const finalPricesPromises = [];
        for (let i = 0; i < 7; i++) {
            // Do something dumb with customer ID: find index where the start of sales office is represented
            // Overwrite the character before the sales office (should be 9) with a cl code (1-6)
            // Exception: BookPrice is the 'default' and does not get changed from a 9
            const customerId =
                i === 2
                    ? gridParams.row.customerId
                    : gridParams.row.customerId.replace(9 + gridParams.row.so, i + gridParams.row.so);

            // Inject the new customerId into the payloads
            const payload = { ...buildModelPayload({ ...gridParams.row, customerId }, true) };

            // Setup promise to request rates from model simultanously
            finalPricesPromises.push(
                calcService.calculate<CalculateInput, ClCodeWeightsResult>({
                    modelId: 'recommendedPrice',
                    payload,
                })
            );
        }

        // Make model calls
        await Promise.all(finalPricesPromises).then(responses => {
            // Using result, map quotelines into PriceMasterClCodeWeightPrices
            const finalClPricesByWeight: PriceMasterClCodeWeightPrices[] = responses.map((res, index) => {
                return {
                    clCode: index, // Assume positions didn't shift...
                    uniqueid: gridParams.row.id + '|' + index,
                    priceMasterId: gridParams.row.id,
                    recPriceWC1: res.data.quoteLines[0].recPriceWC1,
                    recPriceWC200: res.data.quoteLines[0].recPriceWC200,
                    recPriceWC500: res.data.quoteLines[0].recPriceWC500,
                    recPriceWC1000: res.data.quoteLines[0].recPriceWC1000,
                    recPriceWC2000: res.data.quoteLines[0].recPriceWC2000,
                    recPriceWC5000: res.data.quoteLines[0].recPriceWC5000,
                    recPriceWC6500: res.data.quoteLines[0].recPriceWC6500,
                    recPriceWC10000: res.data.quoteLines[0].recPriceWC10000,
                    recPriceWC20000: res.data.quoteLines[0].recPriceWC20000,
                    recPriceWC24000: res.data.quoteLines[0].recPriceWC24000,
                    recPriceWC40000: res.data.quoteLines[0].recPriceWC40000,
                };
            });
            // Send Final Cl Prices by Weight to side drawer data
            setFinalDrawerData(finalClPricesByWeight);
        });

        // Get Current Cl Prices by Weight from database, send to side drawer
        const currentClPricesByWeight = await priceService.getCLPricesByWeight(gridParams.row.id);
        setCurrentDrawerData(currentClPricesByWeight);
        setDrawerLoading(false);
    }

    return (
        <>
            <Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <CLPriceSideDrawerTable
                    currentTableData={currentDrawerData}
                    finalTableData={finalDrawerData}
                    sharedMetrics={{
                        floorCurrent: gridParams.row.floorPriceCurrent,
                        floorFinal: gridParams.row.finalFloorPrice,
                        exchangeRate: props.exchangeRate,
                        decimalPlaces: decimalPlaces,
                    }}
                    loading={drawerLoading}
                />
            </Drawer>
            <Stack flexDirection='row' alignItems='center' justifyContent='flex-end'>
                {/*Price Matrix temporarily removed (https://insight2profit.atlassian.net/browse/RPM-828)
                 <InfoOutlined
                    sx={{ color: 'darkgray', marginRight: 1, cursor: 'pointer' }}
                    onClick={() => onClickInfoButton()}
                /> */}
                <Box mt='1px'>{ColumnFormatterMoneyDecimals(gridParams.value, decimalPlaces)}</Box>
            </Stack>
        </>
    );
}
