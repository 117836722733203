import { useService } from '@price-for-profit/service-provider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERYKEY_PRICE } from 'shared/constants';
import { SubmitPriceMasterParameters } from 'shared/types';

export function useSubmitPriceMaster() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { priceService } = useService();

    const mutation = useMutation<void, Error, SubmitPriceMasterParameters>({
        mutationFn: request => priceService.submitPriceMaster(request),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_PRICE] });
            enqueueSnackbar('Submission complete', { variant: 'success' });
        },
        onError: () => {
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_PRICE] });
            enqueueSnackbar('Submission failed', { variant: 'error' });
        },
    });

    return mutation;
}
