import { IAuthorizationService } from '@price-for-profit/micro-services';

export interface IUserAuthorizationService {
    getUserAttributes(userId: string | undefined): Promise<Record<'office' | 'multimarket', string> | undefined>;
}

export class UserAuthorizationService implements IUserAuthorizationService {
    constructor(private authorizationService: IAuthorizationService, private clientId: string) {}

    async getUserAttributes(userId: string): Promise<Record<'office' | 'multimarket', string> | undefined> {
        const clientUserRestricted = await this.authorizationService.getClientUserRestricted({
            clientId: this.clientId,
            userId,
        });

        return clientUserRestricted.clients.find(client => client.clientId === this.clientId)?.attributes;
    }
}
