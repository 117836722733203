import { AppPermission, LoginPermissionKey } from '@price-for-profit/auth-types';

type PermissionKey =
    | LoginPermissionKey
    | 'canada_procurement'
    | 'coe'
    | 'cxm_mmgm'
    | 'cxr'
    | 'procurement'
    | 'layouts.share'
    | 'layouts.canUnshareAny';

export const PERMISSIONS: Record<PermissionKey, AppPermission<PermissionKey>> = {
    login: 'ryerson.login',
    canada_procurement: 'ryerson.canada_procurement',
    coe: 'ryerson.coe',
    cxm_mmgm: 'ryerson.cxm_mmgm',
    cxr: 'ryerson.cxr',
    procurement: 'ryerson.procurement',
    'layouts.share': 'ryerson.layouts.share',
    'layouts.canUnshareAny': 'ryerson.layouts.canUnshareAny',
};
