import { Edit } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import { GridCustomTableToolbar, ServerSideState } from '@price-for-profit/data-grid';
import { useToggle } from '@price-for-profit/react-hooks';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { MassActionDisabledDialog } from 'shared/components';
import { MASS_ACTION_COST_MASTER_TOOLTIP } from 'shared/constants';
import { useGetMassActionEnabled } from 'shared/queries';
import { CostMassActionDialog } from '../cost-mass-action-dialog';
import { useCostMasterTableState } from './table-state';
import { GridToolbarFutureLoadButton } from './grid-toolbar-future-load-btn';

interface CostMasterTableToolbarProps {
    user: drive.UserInfo;
    state: ServerSideState;
}

export function CostMasterTableToolbar({ user, state }: CostMasterTableToolbarProps) {
    const tableState = useCostMasterTableState();
    const snackbar = useSnackbar();
    const [isMassActionDialogOpened, toggleMassActionDialog] = useToggle(false);

    const massActionEnabled = useGetMassActionEnabled();
    const [isMassActionDisabledDialogOpened, toggleMassActionDisabledDialog] = useToggle(false);

    const onMassActionDialogClose = useCallback(() => {
        toggleMassActionDialog();
    }, [toggleMassActionDialog]);

    const openMassActionDialog = async () => {
        if (tableState.filteredRowsCount.get() <= 1) {
            snackbar.enqueueSnackbar('Please select more than one item to do a mass action.', { variant: 'error' });
        } else {
            toggleMassActionDialog();
        }
    };

    return (
        <GridCustomTableToolbar
            uniqueGridName='CostMaster'
            isShareAllowed={user.layoutsShare}
            canUnshareAny={user.layoutsUnshareAny}
        >
            <GridToolbarFutureLoadButton user={user}></GridToolbarFutureLoadButton>
            <Box>
                <CostMassActionDialog
                    isMassActionOpened={isMassActionDialogOpened}
                    onMassActionClose={onMassActionDialogClose}
                    user={user}
                    state={state}
                />
                {user.coe && (
                    <>
                        {massActionEnabled && (
                            <Tooltip
                                title={
                                    <span style={{ whiteSpace: 'pre-line' }}>{MASS_ACTION_COST_MASTER_TOOLTIP}</span>
                                }
                            >
                                <Button
                                    onClick={openMassActionDialog}
                                    startIcon={<Edit />}
                                    size='small'
                                    variant='outlined'
                                    sx={{ mr: 1 }}
                                >
                                    Mass Action
                                </Button>
                            </Tooltip>
                        )}
                        {!massActionEnabled && (
                            <>
                                <MassActionDisabledDialog
                                    isDialogOpened={isMassActionDisabledDialogOpened}
                                    onDialogClose={toggleMassActionDisabledDialog}
                                />
                                <Button
                                    onClick={toggleMassActionDisabledDialog}
                                    startIcon={<Edit />}
                                    size='small'
                                    variant='outlined'
                                    sx={{ mr: 1 }}
                                >
                                    In Progress...
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Box>
        </GridCustomTableToolbar>
    );
}
