import { Close, Send } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useCallback } from 'react';
import { useToggle } from '@price-for-profit/react-hooks';
import { useSubmitPriceMaster } from 'shared/mutations';
import { useEventTriggerAdfPipeline } from 'shared/mutations/event-trigger-adf-pipeline';
import { SubmitPriceMasterParameters } from 'shared/types';

interface DialogSubmissionProps {
    user: drive.UserInfo;
}

export function DialogSubmission({ user }: DialogSubmissionProps) {
    const submitMutation = useSubmitPriceMaster();
    const triggerAdfMutation = useEventTriggerAdfPipeline();
    const [dialogOpen, toggleDialog] = useToggle(false);

    const onOpen = useCallback(() => {
        toggleDialog();
    }, [toggleDialog]);

    const onClose = useCallback(() => {
        toggleDialog();
    }, [toggleDialog]);

    const confirm = async () => {
        const params: SubmitPriceMasterParameters = {
            userName: user.displayName,
            date: new Date().toISOString(),
            version: 0,
        };
        try {
            await submitMutation.mutateAsync(params);
            await triggerAdfMutation.mutateAsync({
                actor: user.id,
                eventType: 'application.event.triggered',
                subject: 'Build and Submit Batch Process - Submitted',
                eventBody: { email: user.email },
            });
        } catch (e) {}
        toggleDialog();
    };

    return (
        <>
            <LoadingButton
                startIcon={<Send />}
                onClick={onOpen}
                loading={submitMutation.isPending}
                loadingPosition='start'
                color='success'
                size='small'
                variant='outlined'
            >
                {' '}
                Submit{' '}
            </LoadingButton>
            <Dialog open={dialogOpen} onClose={onClose} fullWidth>
                <DialogTitle sx={{ color: 'primary.main' }}>
                    Are you sure you would like to submit all pending changes to production?
                </DialogTitle>
                <DialogActions>
                    <Button startIcon={<Close />} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button startIcon={<Send />} onClick={confirm}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
