import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { QUERYKEY_EXCHANGE_RATES } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';
import { useEffect } from 'react';

export function useGetExchangeRates() {
    const { priceService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const { data, isLoading, isFetching, isError } = useQuery({
        queryKey: [QUERYKEY_EXCHANGE_RATES],
        queryFn: () => priceService.getExchangeRates(),
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Failed to fetch exchange rates.', { variant: 'error' });
        }
    }, [isError, enqueueSnackbar]);

    if (data === undefined) {
        return { data, rowCount: 0, isLoading, isFetching };
    }

    return {
        data: data,
        isLoading,
        isFetching,
    };
}
