import { useService } from '@price-for-profit/service-provider';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { QUERYKEY_MILL_SUPPLIER_OPTIONS } from 'shared/constants';

export function useGetMillSupplierOptions() {
    const { costService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const { data, isError } = useQuery({
        queryKey: [QUERYKEY_MILL_SUPPLIER_OPTIONS],
        queryFn: () => costService.getMillSupplierOptions(),
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Failed to fetch mill supplier dropdown options.', { variant: 'error' });
        }
    }, [isError, enqueueSnackbar]);

    return {
        data,
    };
}
