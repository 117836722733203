import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERYKEY_COST_FUTURE_RATE, QUERYKEY_COST_FUTURE_RATE_METADATA } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';
import { CreateCostFutureRateParams } from 'shared/types';
import { setMassActionCorrelationId } from 'shared/utility';

export function useCreateCostMasterFutureRates() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { costService } = useService();

    return useMutation<void, Error, CreateCostFutureRateParams>({
        mutationFn: request => costService.createCostMasterFutureRates(request),
        onSuccess: () => {
            enqueueSnackbar('Creating future date rates', {
                variant: 'info',
            });
        },
        onError: () => {
            enqueueSnackbar('Future date rates creation failed', { variant: 'error' });
            setMassActionCorrelationId('');
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST_FUTURE_RATE] });
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST_FUTURE_RATE_METADATA] });
        },
    });
}
