import { useMsal } from '@azure/msal-react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const SECOND = 1000;
const MINUTE = SECOND * 60;

const EXPIRE_IN = 15 * MINUTE;
const PROMPT_BEFORE_IDLE = 5 * MINUTE;

export function SessionDialog() {
    const { instance } = useMsal();
    const [open, setOpen] = useState<boolean>(false);
    const [remaining, setRemaining] = useState<number>(0);

    const formatedRemaining = () => {
        const min = Math.floor(remaining / 60);
        const sec = remaining - min * 60;

        return `${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
    };

    const handleClose = () => {
        setOpen(false);
        activate();
    };

    const onPrompt = () => {
        setOpen(true);
        setRemaining(PROMPT_BEFORE_IDLE);
    };

    const onIdle = () => {
        instance.logout();
        setOpen(false);
    };

    const onActive = () => {
        setOpen(false);
        setRemaining(0);
    };

    const { getRemainingTime, isPrompted, activate } = useIdleTimer({
        timeout: EXPIRE_IN,
        promptBeforeIdle: PROMPT_BEFORE_IDLE,
        onPrompt,
        onIdle,
        onActive,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [getRemainingTime, isPrompted]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Session Expire Warning</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your session will expire in {formatedRemaining()}, and all unsaved work will be lost. Do you want to
                    extend the session?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => instance.logout()}>Log Out</Button>
                <Button onClick={handleClose} autoFocus>
                    Stay Logged In
                </Button>
            </DialogActions>
        </Dialog>
    );
}
