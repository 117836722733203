import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { EditCellDropdown } from 'shared/components';
import { useGetSurchargeOptions } from 'shared/queries';
import { CostSummary } from 'shared/types';

export function SurchargeDropdown(params: GridRenderCellParams<CostSummary, string>) {
    const { data } = useGetSurchargeOptions();

    if (data !== undefined) {
        return <EditCellDropdown data={data} params={params} />;
    } else {
        return <Box>Loading...</Box>;
    }
}
