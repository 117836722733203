import { hookstate, useHookstate } from '@hookstate/core';
import {
    GridColumnsInitialState,
    GridFilterInitialState,
    GridLogicOperator,
    GridPaginationModel,
    GridPinnedColumnFields,
    GridSortingInitialState,
} from '@mui/x-data-grid-premium';
import { Surcharge } from 'shared/types';

interface SurchargeMasterTableState {
    paginationModel: GridPaginationModel;
    sorting: GridSortingInitialState;
    filter: GridFilterInitialState;
    columns: GridColumnsInitialState;
    pinnedColumns: GridPinnedColumnFields;
}

const surchargeMasterPinnedColumnInitialState: GridPinnedColumnFields = {
    left: [],
    right: [],
};

export const surchargeMasterColumnVisibilityInitialState: Record<keyof Surcharge, boolean> = {
    id: false,
    surcharge: true,
    surchargeDescription: true,
    surchargeValueCurrent: true,
    surchargeValueNew: true,
    changeCount: false,
    product: true,
    form: true,
    notes: true,
    updatedBy: false,
    updatedByEmail: false,
    updatedDate: false,
    createdBy: false,
    createdDate: false,
    deletedDate: false,
};

export const defaultSurchargeMasterTableState: SurchargeMasterTableState = {
    paginationModel: { page: 0, pageSize: 200 },
    sorting: {
        sortModel: [{ field: 'surcharge', sort: 'asc' }],
    },
    filter: {
        filterModel: { items: [], logicOperator: GridLogicOperator.And },
    },
    columns: {
        columnVisibilityModel: surchargeMasterColumnVisibilityInitialState,
    },
    pinnedColumns: surchargeMasterPinnedColumnInitialState,
};

const surchargeMasterTableState = hookstate<SurchargeMasterTableState>(defaultSurchargeMasterTableState);

export function useSurchargeMasterTableState() {
    return useHookstate<SurchargeMasterTableState>(surchargeMasterTableState);
}
