import { hookstate, useHookstate } from '@hookstate/core';
import {
    GridColumnsInitialState,
    GridFilterInitialState,
    GridLogicOperator,
    GridPaginationModel,
    GridPinnedColumnFields,
    GridSortingInitialState,
} from '@mui/x-data-grid-premium';
import { CostSummary } from 'shared/types';

interface CostMasterTableState {
    paginationModel: GridPaginationModel;
    sorting: GridSortingInitialState;
    filter: GridFilterInitialState;
    columns: GridColumnsInitialState;
    pinnedColumns: GridPinnedColumnFields;
    filteredRowsCount: number;
}

const costMasterPinnedColumnInitialState: GridPinnedColumnFields = {
    left: ['priceBook', 'bw', 'bwDesc'],
    right: [],
};

export const costMasterColumnVisibilityInitialState: Record<keyof CostSummary, boolean> = {
    id: false,
    priceBook: true,
    bw: true,
    bwDesc: true,
    owner: true,
    primaryMillSupplier: true,
    secondaryMillSupplier: true,
    product: true,
    form: true,
    productCategory: true,
    surcharge: true,
    primarySurchargeNew: true,
    secondarySurchargeCurrent: true,
    secondarySurchargeNew: true,
    secondarySurchargeValueNew: false,
    primaryBaseConversionCurrent: true,
    primaryBaseConversionNew: true,
    secondaryBaseConversionCurrent: true,
    secondaryBaseConversionNew: true,
    primaryAllocationCurrent: true,
    primaryAllocationNew: true,
    baseConversionCurrent: true,
    baseConversionNew: true,
    surchargeIngotCurrent: true,
    surchargeIngotNew: true,
    processingCurrent: true,
    processingNew: true,
    otherExtrasCurrent: true,
    otherExtrasNew: true,
    inboundFreightCurrent: true,
    inboundFreightNew: true,
    secondaryInboundFreightCurrent: true,
    secondaryInboundFreightNew: true,
    baseCostCurrent: true,
    baseCostLastChange: true,
    baseCostNew: true,
    baseCostHoldPrice: true,
    replacementCostChange: true,
    baseCostMode: true,
    marketMovementCurrent: true,
    marketMovementLastChange: true,
    marketMovementNew: true,
    marketMovementMode: true,
    marketMovementHoldPrice: true,
    marketMovementAdHocCurrent: true,
    marketMovementAdHocNew: true,
    marketMovementAdHocLastChange: true,
    changeCount: true,
    finalBaseCost: false,
    finalMarketMovement: false,
    baseCostChange: false,
    marketMovementChange: false,
    baseCostMaxChangeWarning: false,
    baseCostAdjustedWarning: false,
    marketMovementMaxChangeWarning: false,
    pendingFutureEffectiveDateWarning: false,
    mappedMaterialsCount: true,
    updatedBy: false,
    updatedByEmail: false,
    updatedDate: false,
    createdBy: false,
    createdDate: false,
};

export const defaultCostMasterTableState: CostMasterTableState = {
    paginationModel: { page: 0, pageSize: 200 },
    sorting: {
        sortModel: [{ field: 'priceBook', sort: 'asc' }],
    },
    filter: {
        filterModel: { items: [], logicOperator: GridLogicOperator.And },
    },
    columns: {
        columnVisibilityModel: { ...costMasterColumnVisibilityInitialState },
    },
    pinnedColumns: costMasterPinnedColumnInitialState,
    filteredRowsCount: 0,
};

export const COST_MASTER_TABLE_STATE_KEY = 'cost-master-table-state';

const costMasterTableState = hookstate<CostMasterTableState>(defaultCostMasterTableState);

export function useCostMasterTableState() {
    return useHookstate<CostMasterTableState>(costMasterTableState);
}
