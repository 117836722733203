import { ServerSideState, generateCollectionFilter } from '@price-for-profit/data-grid';
import {
    DataAccessPaginatedResponse,
    DataAccessResponse,
    IDataAccessService,
    IExportService,
    IFilter,
    NoInfer,
    compare,
    getData,
} from '@price-for-profit/micro-services';
import {
    DATASTORE_SQL,
    SP_KEY_APPLY_COST_MASTER_FUTURE_RATE,
    SP_KEY_COST_MASTER_MASS_ACTION,
    SP_KEY_CREATE_COST_MASTER_FUTURE_RATE,
    TABLE_COST_MASTER,
    TABLE_COST_MASTER_FUTURE_RATES,
    TABLE_COST_MASTER_FUTURE_RATE_METADATA,
    TABLE_MILL_SUPPLIER_NAMES,
    VIEW_COST_MASTER_FUTURE_RATE_SUMMARY,
    VIEW_COST_MASTER_SUMMARY,
    VIEW_COST_MASTER_WARNING_SUMMARY,
} from 'shared/constants';
import {
    ApplyCostFutureRateParams,
    Cost,
    CostFutureRateSummary,
    CostMasterExcelExport,
    CostMasterExportExcelProps,
    CostMasterFutureRateMetadata,
    CostMasterMassActionParameters,
    CostMasterMassActionRequest,
    CostMetrics,
    CostSummary,
    CostUpdate,
    CreateCostFutureRateParams,
    DropdownOption,
    IDownloadFileProps,
    UpdateCostMasterFutureRateParameters,
    UpdateCostMasterParameters,
} from 'shared/types';
import { getCostMasterExportData, handleBlob } from 'shared/utility';

export interface ICostService {
    getCostData(state: ServerSideState): Promise<DataAccessPaginatedResponse<CostSummary>>;
    massAction(request: CostMasterMassActionRequest): Promise<void>;
    getCostWarningData(): Promise<CostMetrics>;
    saveRows({ newRows, oldRows, user }: UpdateCostMasterParameters): Promise<void>;
    getCostsForSurcharge(surcharge: string): Promise<Cost[]>;
    getMillSupplierOptions(): Promise<DropdownOption[]>;
    exportCostMaster({ filterModel, exchangeRate }: CostMasterExportExcelProps): Promise<void>;
    exportExcel<T>(props: IDownloadFileProps<T>): Promise<void>;
    getCostFutureRateData(state: ServerSideState): Promise<DataAccessPaginatedResponse<CostFutureRateSummary>>;
    saveCostFutureRate({ newRows, oldRows }: Omit<UpdateCostMasterParameters, 'user'>): Promise<void>;
    getCostFutureRateMetadata(
        includeProcessed: boolean
    ): Promise<DataAccessPaginatedResponse<CostMasterFutureRateMetadata>>;
    deleteCostFutureMetadataAndRates(futureRateMetadataId: string): Promise<DataAccessResponse<string>>;
    createCostMasterFutureRates(params: CreateCostFutureRateParams): Promise<void>;
    applyCostMasterFutureRates(params: ApplyCostFutureRateParams): Promise<void>;
}
type UpdatedCostFields = Pick<
    CostUpdate,
    | 'primaryMillSupplier'
    | 'secondaryMillSupplier'
    | 'primaryBaseConversionNew'
    | 'secondaryBaseConversionNew'
    | 'primaryAllocationNew'
    | 'processingNew'
    | 'otherExtrasNew'
    | 'inboundFreightNew'
    | 'secondaryInboundFreightNew'
    | 'baseConversionNew'
    | 'baseCostNew'
    | 'changeCount'
    | 'finalBaseCost'
    | 'finalMarketMovement'
    | 'baseCostHoldPrice'
    | 'marketMovementHoldPrice'
    | 'marketMovementAdHocNew'
    | 'primarySurchargeNew'
    | 'secondarySurchargeNew'
    | 'updatedBy'
    | 'updatedByEmail'
    | 'updatedDate'
>;
function costToUpdateCost(table: CostSummary): UpdatedCostFields {
    return {
        primaryMillSupplier: table.primaryMillSupplier,
        secondaryMillSupplier: table.secondaryMillSupplier,
        primaryBaseConversionNew: table.primaryBaseConversionNew,
        secondaryBaseConversionNew: table.secondaryBaseConversionNew,
        primaryAllocationNew: table.primaryAllocationNew,
        processingNew: table.processingNew,
        otherExtrasNew: table.otherExtrasNew,
        inboundFreightNew: table.inboundFreightNew,
        secondaryInboundFreightNew: table.secondaryInboundFreightNew,
        baseConversionNew: table.baseConversionNew,
        baseCostNew: table.baseCostNew,
        changeCount: table.changeCount,
        finalBaseCost: table.finalBaseCost,
        finalMarketMovement: table.finalMarketMovement,
        baseCostHoldPrice: table.baseCostHoldPrice,
        marketMovementHoldPrice: table.marketMovementHoldPrice,
        marketMovementAdHocNew: table.marketMovementAdHocNew,
        primarySurchargeNew: table.primarySurchargeNew,
        secondarySurchargeNew: table.secondarySurchargeNew,
        updatedDate: '',
        updatedBy: '',
        updatedByEmail: '',
    };
}

type UpdatedCostFutureRateFields = {
    id: string;
    costMasterFutureRatesMetadataId: string;
    costMasterId: string;
    futureBaseConversion: number | null;
    futurePrimaryAllocation: number | null;
    futureSecondaryBaseConversion: number | null;
};
function costToUpdateCostFutureRate(cost: CostSummary, metadataId: string): UpdatedCostFutureRateFields {
    return {
        id: `${cost.id}|${metadataId}`,
        costMasterId: cost.id,
        costMasterFutureRatesMetadataId: metadataId,
        futureBaseConversion: cost.primaryBaseConversionNew === 0 ? null : cost.primaryBaseConversionNew,
        futurePrimaryAllocation: cost.primaryAllocationNew === 0 ? null : cost.primaryAllocationNew,
        futureSecondaryBaseConversion: cost.secondaryBaseConversionNew === 0 ? null : cost.secondaryBaseConversionNew,
    };
}
function updateCostMetadataFields(editedCostValues: UpdatedCostFields, user: drive.UserInfo): CostUpdate {
    const today = new Date().toISOString();
    return {
        ...editedCostValues,
        updatedDate: today,
        updatedBy: user.id,
        updatedByEmail: user.email,
    };
}
export class CostService implements ICostService {
    constructor(
        private dasService: IDataAccessService,
        private exportService: IExportService,
        private clientId: string
    ) {}

    async getCostData(state: ServerSideState): Promise<DataAccessPaginatedResponse<CostSummary>> {
        return await this.dasService.getCollection<CostSummary, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: VIEW_COST_MASTER_SUMMARY,
            page: state.paginationModel.page,
            pageSize: state.paginationModel.pageSize,
            sortBy: state.sortModel[0]?.field as NoInfer<keyof CostSummary>,
            sortDescending: state.sortModel[0]?.sort === 'desc' ? true : false,
            collectionFilter: generateCollectionFilter<CostSummary>(state.filterModel, undefined),
        });
    }

    async massAction(request: CostMasterMassActionRequest) {
        await this.dasService.executeMassAction<Cost, CostMasterMassActionParameters>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: VIEW_COST_MASTER_SUMMARY,
            queryName: SP_KEY_COST_MASTER_MASS_ACTION,
            parameters: request.params,
            collectionFilter: generateCollectionFilter<Cost>(request.filterModel, undefined),
        });
    }

    async getCostWarningData(): Promise<CostMetrics> {
        const { data } = await this.dasService.getCollection<CostMetrics, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: VIEW_COST_MASTER_WARNING_SUMMARY,
        });
        return data[0];
    }

    async saveRows({ newRows, oldRows, user }: UpdateCostMasterParameters): Promise<void> {
        if (newRows.length > 0) {
            newRows.forEach(async row => {
                const oldRow = oldRows.find(x => x.id === row.id);
                if (oldRow !== undefined) {
                    const [newCost, oldCost] = [row, oldRow].map(costToUpdateCost);
                    const costOperations = compare(oldCost, updateCostMetadataFields(newCost, user));
                    await this.dasService.patchRow<Cost, typeof DATASTORE_SQL>({
                        clientId: this.clientId,
                        databaseLabel: DATASTORE_SQL,
                        tableId: TABLE_COST_MASTER,
                        key: row.id,
                        operations: costOperations,
                    });
                }
            });
        }
    }

    async getMillSupplierOptions(): Promise<DropdownOption[]> {
        const { data } = await this.dasService.getCollection<DropdownOption, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_MILL_SUPPLIER_NAMES,
            pageSize: 1000,
        });

        return data;
    }

    async getCostsForSurcharge(surcharge: string): Promise<Cost[]> {
        const filter: IFilter<Cost> = {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'surcharge',
                    operator: 'eq',
                    value: surcharge,
                },
            ],
        };

        const { data } = await this.dasService.getCollection<Cost, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_COST_MASTER,
            collectionFilter: filter,
            pageSize: 1000,
        });

        return data;
    }

    async exportCostMaster({ filterModel, exchangeRate }: CostMasterExportExcelProps): Promise<void> {
        const today = new Date().toLocaleString();
        const data = await getData(page =>
            this.dasService.getCollection<CostSummary, typeof DATASTORE_SQL>({
                clientId: this.clientId,
                databaseLabel: DATASTORE_SQL,
                tableId: VIEW_COST_MASTER_SUMMARY,
                page: page,
                pageSize: 1000,
                enableTotalCount: true,
                collectionFilter: generateCollectionFilter<CostSummary>(filterModel),
            })
        );

        const costData: CostMasterExcelExport = getCostMasterExportData(data, exchangeRate);

        return await this.exportExcel({
            exportId: 'cost-export',
            data: costData,
            fileName: `Cost Master ${today}`,
        });
    }

    async exportExcel<T>({ exportId, data, fileName }: IDownloadFileProps<T>) {
        const blob = await this.exportService.downloadExcel<T>({
            clientId: this.clientId,
            templateId: exportId,
            payload: data,
        });
        handleBlob(blob, `${fileName}.xlsx`);
    }

    async getCostFutureRateData(state: ServerSideState): Promise<DataAccessPaginatedResponse<CostFutureRateSummary>> {
        // If we didn't find a futureratesmetadataid filter, apply 0 user must select date
        const futureRatesMetadataFilter = state.filterModel.items.filter(
            x => x.field === 'costMasterFutureRatesMetadataId'
        );
        const filter: IFilter<CostFutureRateSummary> | undefined =
            futureRatesMetadataFilter.length === 0
                ? {
                      logicalOperator: 'and',
                      filters: [
                          {
                              property: 'costMasterFutureRatesMetadataId',
                              operator: 'eq',
                              value: '0',
                          },
                      ],
                  }
                : undefined;

        return await this.dasService.getCollection<CostFutureRateSummary, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: VIEW_COST_MASTER_FUTURE_RATE_SUMMARY,
            page: state.paginationModel.page,
            pageSize: state.paginationModel.pageSize,
            sortBy: state.sortModel[0]?.field as NoInfer<keyof CostFutureRateSummary>,
            sortDescending: state.sortModel[0]?.sort === 'desc' ? true : false,
            collectionFilter: generateCollectionFilter<CostFutureRateSummary>(state.filterModel, filter),
        });
    }

    async getCostFutureRateMetadata(
        includeProcessed = false
    ): Promise<DataAccessPaginatedResponse<CostMasterFutureRateMetadata>> {
        const filter: IFilter<CostMasterFutureRateMetadata> = {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'processedDate',
                    operator: 'isnull',
                    value: null,
                },
            ],
        };

        let collectionProps = {
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_COST_MASTER_FUTURE_RATE_METADATA,
            pageSize: 1000,
        };

        if (!includeProcessed) {
            collectionProps = { ...collectionProps, ...{ collectionFilter: filter } };
        }

        return await this.dasService.getCollection<CostMasterFutureRateMetadata, typeof DATASTORE_SQL>(collectionProps);
    }

    async createCostMasterFutureRates(params: CreateCostFutureRateParams) {
        await this.dasService.executeNamedQuery<CreateCostFutureRateParams>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            queryName: SP_KEY_CREATE_COST_MASTER_FUTURE_RATE,
            parameters: params,
        });
    }

    async applyCostMasterFutureRates(params: ApplyCostFutureRateParams) {
        await this.dasService.executeNamedQuery<ApplyCostFutureRateParams>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            queryName: SP_KEY_APPLY_COST_MASTER_FUTURE_RATE,
            parameters: params,
        });
    }
    async deleteCostFutureMetadataAndRates(futureRateMetadataId: string): Promise<DataAccessResponse<string>> {
        // !! Deleting metadata row cascades to related future rates
        return await this.dasService.deleteRow<string, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            key: futureRateMetadataId,
            tableId: TABLE_COST_MASTER_FUTURE_RATE_METADATA,
        });
    }

    async saveCostFutureRate({ newRows, oldRows, futureRateMetadataId }: UpdateCostMasterFutureRateParameters) {
        if (newRows.length > 0) {
            newRows.forEach(async row => {
                const oldRow = oldRows.find(x => x.id === row.id);
                if (oldRow !== undefined) {
                    const [newCostFutureRate] = [row, oldRow].map(cost =>
                        costToUpdateCostFutureRate(cost, futureRateMetadataId)
                    );
                    await this.dasService.updateRow<UpdatedCostFutureRateFields, typeof DATASTORE_SQL>({
                        clientId: this.clientId,
                        databaseLabel: DATASTORE_SQL,
                        tableId: TABLE_COST_MASTER_FUTURE_RATES,
                        payload: newCostFutureRate,
                    });
                }
            });
        }
    }
}
