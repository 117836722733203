import { useService } from '@price-for-profit/service-provider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERYKEY_WARNING_CONFIGURATION } from 'shared/constants';
import { UpdateWarningConfigurationParameters } from 'shared/types';

export function useSaveWarningConfigurationRow() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { warningConfigurationService } = useService();

    const mutation = useMutation<void, Error, UpdateWarningConfigurationParameters>({
        mutationFn: request => warningConfigurationService.saveRow(request),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_WARNING_CONFIGURATION] });
            enqueueSnackbar('Warning Threshold updated', { variant: 'success' });
        },
        onError: () => {
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_WARNING_CONFIGURATION] });
            enqueueSnackbar('Warning Threshold failed to save', { variant: 'error' });
        },
    });

    return mutation;
}
