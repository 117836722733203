import { useService } from '@price-for-profit/service-provider';
import { useQuery } from '@tanstack/react-query';
import { DropdownOption } from 'shared/types';
import { getNonConfigurableWarnings } from 'shared/utility';

export function useGetWarningsFilter() {
    const { warningConfigurationService } = useService();
    const { data, isLoading } = useQuery({
        queryKey: ['warnings'],
        queryFn: () => warningConfigurationService.getWarningsData(),
    });
    const warningsFilter = [...(data || []), ...getNonConfigurableWarnings()];

    return {
        warnings: data,
        warningOptions: warningsFilter.map<DropdownOption>((warning, idx) => ({
            value: warning,
            uniqueId: idx.toString(),
        })),
        isWarningsLoading: isLoading,
    } as const;
}
