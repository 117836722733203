import {
    InsightGridColDef,
    getLimitedGridNumericOperators,
    getLimitedGridStringOperators,
} from '@price-for-profit/data-grid';
import { WarningConfiguration } from 'shared/types';
import { ColumnFormatterMoneyDecimals, ColumnFormatterSlashDate, PriceColumn } from 'shared/utility';

export function WarningConfigurationColumns(): InsightGridColDef<WarningConfiguration>[] {
    return [
        {
            field: 'product',
            headerName: 'Product',
            flex: 1,
            width: 150,
            minWidth: 50,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'form',
            headerName: 'Form',
            flex: 1,
            width: 150,
            minWidth: 50,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'category',
            headerName: 'Category',
            flex: 2,
            width: 150,
            minWidth: 50,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'warningMessage',
            headerName: 'Warning Message',
            flex: 2,
            width: 150,
            minWidth: 50,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'value',
            headerName: 'Warning Threshold',
            flex: 1,
            width: 150,
            minWidth: 50,
            type: 'number',
            editable: true,
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            renderEditCell: params => <PriceColumn {...params} />,
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'updatedBy',
            headerName: 'Last Updated By',
            flex: 1,
            width: 150,
            minWidth: 50,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'updatedDate',
            headerName: 'Last Updated Date',
            flex: 1,
            width: 150,
            minWidth: 50,
            valueGetter: ColumnFormatterSlashDate,
            filterOperators: getLimitedGridNumericOperators(),
        },
    ];
}
