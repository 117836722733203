import { Paper, Stack, Typography } from '@mui/material';
import { GridFilterItem } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { InfoBars } from '@price-for-profit/shared-components';
import { useState } from 'react';
import { PriceMetrics } from 'shared/types';
import { formatMoneyCompact } from 'shared/utility';

interface PriceMasterMetricsProps {
    priceMetrics: PriceMetrics;
    apiRef: React.MutableRefObject<GridApiPremium>;
}

export function PriceMasterMetrics({ priceMetrics, apiRef }: PriceMasterMetricsProps) {
    const [filterId, setFilterId] = useState<string | null>(null);

    const toggleFilterWarnings = () => {
        if (filterId) {
            apiRef.current.deleteFilterItem({ id: filterId, field: '', value: 'value', operator: 'is' });

            setFilterId(null);

            return;
        }

        const currentId = crypto.randomUUID();

        setFilterId(currentId);

        const filterItem: GridFilterItem = {
            field: 'hasWarnings',
            value: true,
            id: currentId,
            operator: 'is',
        };

        apiRef.current.upsertFilterItem(filterItem);
    };

    return (
        <Paper elevation={0}>
            <Stack p={1} direction='row' spacing={1}>
                <InfoBars title='Warnings Count' onClickHandler={toggleFilterWarnings}>
                    <Typography align='center' variant='h6' lineHeight={1.25}>
                        <strong style={priceMetrics.warningsCount > 0 ? { color: 'red' } : {}}>
                            {priceMetrics.warningsCount}
                        </strong>
                    </Typography>
                </InfoBars>

                <InfoBars title='Warning $'>
                    <Typography align='center' variant='h6' lineHeight={1.25}>
                        <strong style={priceMetrics.warningsTotal > 0 ? { color: 'red' } : {}}>
                            {formatMoneyCompact(priceMetrics.warningsTotal)}
                        </strong>
                    </Typography>
                </InfoBars>

                {/* <Box sx={paperStyles}>
              <Typography align='center' variant='body2'>
                Time Until Prices Are Submitted
              </Typography>
              <Typography align='center' variant='h6' lineHeight={1.25}>
                <strong>18h 06m</strong>
              </Typography>
            </Box> */}
            </Stack>
        </Paper>
    );
}
