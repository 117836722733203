import { Close, EditCalendar, Send } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import React, { useState } from 'react';
import { DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { enqueueSnackbar } from 'notistack';
import { useCreateCostMasterFutureRates } from 'shared/mutations';
import { useGetCostMasterFutureRateMetadata } from 'shared/queries';
import { isSameDay } from 'date-fns';
import { CostMasterFutureRateMetadata } from 'shared/types';

interface FutureDateSelectorDialogProps {
    isOpened: boolean;
    onClose: (confirmed: boolean) => void;
    onChange: (value: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => void;
    FutureRateMetadata: CostMasterFutureRateMetadata[];
}

function FutureDateSelectorDialog({ isOpened, onClose, onChange, FutureRateMetadata }: FutureDateSelectorDialogProps) {
    const dateExistsInMetadata = (day: Date) => {
        for (let cmfr of FutureRateMetadata) {
            if (isSameDay(day, new Date(cmfr.effectiveDate))) {
                return true;
            }
        }

        return false;
    };

    return (
        <Dialog onClose={() => onClose(false)} open={isOpened}>
            <DialogTitle sx={{ color: 'primary.main' }}>Create Future Load</DialogTitle>
            <DialogContent>
                <StaticDatePicker
                    shouldDisableDate={dateExistsInMetadata}
                    disablePast={true}
                    slotProps={{
                        actionBar: {
                            actions: ['today'],
                        },
                    }}
                    onChange={onChange}
                />
            </DialogContent>
            <DialogActions>
                <Button startIcon={<Close />} onClick={() => onClose(false)}>
                    Cancel
                </Button>
                <Button startIcon={<Send />} onClick={() => onClose(true)}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface GridToolbarFutureLoadButtonProps {
    user: drive.UserInfo;
}

export function GridToolbarFutureLoadButton({ user }: GridToolbarFutureLoadButtonProps) {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedDateValue, setSelectedDateValue] = useState<Date | null>();
    const createFutureRatesMutation = useCreateCostMasterFutureRates();
    const { data: CostMasterFutureRateMetadata } = useGetCostMasterFutureRateMetadata(true);

    const handleOnClick = () => {
        if (CostMasterFutureRateMetadata.filter(cmfr => cmfr.processedDate === null).length === 2) {
            enqueueSnackbar('Two active future loads already exist. Apply or discard one to create another', {
                variant: 'error',
            });
            return;
        }
        setOpenDialog(true);
    };
    const onChange = (value: Date | null, _context: PickerChangeHandlerContext<DateValidationError>) => {
        setSelectedDateValue(value);
    };
    const onClose = (confirmed: boolean) => {
        if (confirmed) {
            if (selectedDateValue) {
                createFutureRatesMutation.mutate({ futureDate: selectedDateValue });
            } else {
                setOpenDialog(true);
                enqueueSnackbar('Invalid date', { variant: 'error' });
                return;
            }
        }
        setSelectedDateValue(null);
        setOpenDialog(false);
    };

    return (
        <Box>
            <FutureDateSelectorDialog
                FutureRateMetadata={CostMasterFutureRateMetadata}
                isOpened={openDialog}
                onClose={onClose}
                onChange={onChange}
            />
            {user.coe ? (
                <Button
                    size='small'
                    variant='outlined'
                    sx={{ mr: 1 }}
                    startIcon={<EditCalendar />}
                    onClick={handleOnClick}
                >
                    Future Load
                </Button>
            ) : (
                ''
            )}
        </Box>
    );
}
