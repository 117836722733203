import { NavigationService } from '@price-for-profit/drive-account-navigation';
import {
    AuthorizationService,
    AxiosInstance,
    CalculationService,
    DataAccessService,
    EventService,
    ExportService,
    StatusService,
} from '@price-for-profit/micro-services';
import { ActivityTrackingService } from '@price-for-profit/user-activity-tracking';
import {
    CostService,
    EventStatusService,
    PriceService,
    SubmissionHistoryService,
    SurchargeService,
    UserAuthorizationService,
    WarningConfigurationService,
} from 'shared/services';
import { CalcService } from 'shared/services/calc-service';

export function createServiceContext(appConfig: drive.AppConfig, httpClient: AxiosInstance): drive.ServiceContext {
    const authorizationService = new AuthorizationService(httpClient);
    const dataAccessService = new DataAccessService(httpClient);
    const calculationService = new CalculationService(httpClient);
    const eventService = new EventService(httpClient);
    const exportService = new ExportService(httpClient);
    const statusService = new StatusService(httpClient);

    const calcService = new CalcService(calculationService, appConfig.clientId);
    const costService = new CostService(dataAccessService, exportService, appConfig.clientId);
    const eventStatusService = new EventStatusService(eventService, statusService, appConfig.clientId);
    const priceService = new PriceService(dataAccessService, exportService, appConfig.clientId);
    const userAuthorizationService = new UserAuthorizationService(authorizationService, appConfig.clientId);
    const surchargeService = new SurchargeService(dataAccessService, appConfig.clientId);
    const submissionHistoryService = new SubmissionHistoryService(dataAccessService, appConfig.clientId);
    const warningConfigurationService = new WarningConfigurationService(dataAccessService, appConfig.clientId);
    const activityTrackingService = new ActivityTrackingService(eventService);
    const navigationService = new NavigationService(appConfig.clientId, dataAccessService);

    return {
        appConfig,
        calcService,
        costService,
        eventStatusService,
        exportService,
        priceService,
        userAuthorizationService,
        surchargeService,
        submissionHistoryService,
        warningConfigurationService,
        eventService,
        dataAccessService,
        navigationService,
        activityTrackingService,
    };
}
