import { SubmissionHistorySummary } from 'shared/types';
import { formatDate } from './formats';
import { parseISO } from 'date-fns';

export const mapSubmissionHistoryToChart = (historyRecords: SubmissionHistorySummary[]) => {
    const dates = [];
    const seriesData: any = {};

    // Records must be in order or chart hovering breaks
    historyRecords = historyRecords.sort((a, b) => Date.parse(a.baseCostLastChange) - Date.parse(b.baseCostLastChange));

    function addValueToSeries(seriesKey: string, value: string) {
        seriesData[seriesKey] = seriesData[seriesKey] || [];
        seriesData[seriesKey].push(value);
    }

    function mapDataToChartSeries() {
        return Object.entries(seriesData).map(([label, data]) => ({
            label,
            data,
            connectNulls: true,
            valueFormatter: (val: string) => (val ? `$${val}` : null),
        }));
    }

    // Use a fuzzy date for better chart axis ticks
    // @ts-expect-error - groupBy not on Object definitions?
    const groupedByFuzzyDate = Object.groupBy(historyRecords, r =>
        r.baseCostLastChange ? formatDate(parseISO(r.baseCostLastChange)) : undefined
    );

    for (const key in groupedByFuzzyDate) {
        const groupedRecords = groupedByFuzzyDate[key];
        dates.push(new Date(key));
        // Rollup duplicated records by Key, use the first one
        // @ts-expect-error - groupBy not on Object definitions?
        const duplicatedSeriesRecords = Object.groupBy(groupedRecords, hr => `${hr.priceBook}|${hr.bw}`);
        // Must use series keys from both sets, if a key does not exist in duplicatedSeriesRecords, add null for the series data point
        for (const seriesKey in { ...seriesData, ...duplicatedSeriesRecords }) {
            addValueToSeries(
                seriesKey,
                duplicatedSeriesRecords[seriesKey] ? duplicatedSeriesRecords[seriesKey][0].finalBaseCost : null
            );
        }
    }

    return {
        yAxis: { label: 'Currency ($ USD)' },
        xAxis: {
            data: dates,
            scaleType: 'time',
            valueFormatter: (val: string) => new Date(val).toLocaleDateString(),
        },
        series: mapDataToChartSeries(),
    };
};
