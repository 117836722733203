import {
    InsightGridColDef,
    getEqualsGridStringOperators,
    getLimitedGridBooleanOperators,
    getLimitedGridNumericOperators,
    getLimitedGridStringOperators,
} from '@price-for-profit/data-grid';
import { CellPopover } from 'shared/components';
import { PriceSummary } from 'shared/types';
import {
    ColumnFormatterBoolean,
    ColumnFormatterExchangeRate,
    ColumnFormatterMoneyDecimals,
    ColumnFormatterPercent,
    ColumnFormatterPercentDecimals,
    ColumnFormatterSlashDate,
    ColumnSetterExchangeRate,
    PercentageColumn,
    PriceColumn,
    formatDecimal,
    formatPercent,
    hasPriceWarnings,
} from 'shared/utility';
import { BookPriceLastChangeCol } from './custom-columns/book-price-last-change-col';
import { FinalBookPriceCol } from './custom-columns/finalbookprice-col';
import { PerformanceMetricsButton } from './performance-btn';
import { SalesOfficeCol } from './sales-office-col';

interface PriceMasterColumnProps {
    user: drive.UserInfo;
    exchangeRate: number;
    readOnly: boolean;
}

export function PriceMasterColumns(props: PriceMasterColumnProps): InsightGridColDef<PriceSummary>[] {
    const { user, exchangeRate, readOnly } = props;
    const editable = readOnly ? false : user.coe || user.cxm_mmgm || user.canada_procurement;

    return [
        {
            field: 'actions',
            type: 'actions',
            width: 10,
            getActions: params => [<PerformanceMetricsButton gridParams={params} />],
        },
        {
            field: 'priceBook',
            headerName: 'Price Book',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'multiMarket',
            headerName: 'Multi-Market',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'so',
            headerName: 'Sales Office',
            width: 100,
            renderCell: params => {
                return <SalesOfficeCol gridParams={params} exchangeRate={exchangeRate}></SalesOfficeCol>;
            },
        },
        {
            field: 'bw',
            headerName: 'BW',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
            renderCell: ({ row }) => {
                const value = row.bw;
                return value && <CellPopover value={value} popoverMessage={row.bwDesc} />;
            },
        },
        {
            field: 'bwDesc',
            headerName: 'BW Description',
            width: 300,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'threePc',
            headerName: '3PC',
            width: 100,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'warnings',
            headerName: 'Warnings',
            width: 300,
            filterOperators: getLimitedGridStringOperators(),
            cellClassName: ({ row }) => (hasPriceWarnings(row) ? 'cell-warning' : ''),
            renderCell: ({ row }) => {
                const value = row.warnings;

                return value && <CellPopover value={value} popoverMessage={value.split('. ').join('.\n')} />;
            },
        },
        {
            field: 'hasWarnings',
            headerName: 'Has Warnings',
            width: 300,
            filterable: true,
            sortable: false,
            filterOperators: getLimitedGridBooleanOperators(),
            type: 'boolean',
            hideable: false,
        },
        {
            field: 'marketPriceRecommendationWarning',
            headerName: 'Market Price Recommendation Warnings',
            width: 300,
            filterOperators: getLimitedGridStringOperators(),
            cellClassName: ({ row }) => (row.marketPriceRecommendationWarning ? 'cell-warning' : ''),
            renderCell: ({ row }) => {
                const value = row.marketPriceRecommendationWarning;

                return value && <CellPopover value={value} popoverMessage={value.split('. ').join('.\n')} />;
            },
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'form',
            headerName: 'Form',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'productCategory',
            headerName: 'Product Category',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'index',
            headerName: 'Index',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'revenueTier',
            headerName: 'Revenue Tier',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'priceSyncFlag',
            headerName: 'Price Sync Flag',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'priceSyncAnchor',
            headerName: 'Anchor SO|BW',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'marginModelAutoload',
            headerName: 'Margin Model Autoload',
            width: 150,
            type: 'boolean',
            valueGetter: ColumnFormatterBoolean,
            filterOperators: getEqualsGridStringOperators(),
        },
        {
            field: 'marketTmRecommendation',
            headerName: 'Market TM Recommendation - Current',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterPercentDecimals(value, 1),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'rollingTwelveMonthsRevenue',
            headerName: 'Rolling 12-month revenue',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 0),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'baseLbs',
            headerName: 'Base Lbs',
            width: 150,
            type: 'number',
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'baseLbsRange',
            headerName: 'Base Lbs Range',
            width: 150,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'targetMarginCurrent',
            headerName: 'Target Margin % (Current)',
            width: 175,
            type: 'number',
            valueGetter: value => ColumnFormatterPercentDecimals(value, 1),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'targetMarginLastChange',
            headerName: 'Target Margin Last Change',
            width: 150,
            valueGetter: ColumnFormatterSlashDate,
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'targetMarginNew',
            headerName: 'Target Margin % (New)',
            width: 175,
            type: 'number',
            editable: editable,
            valueFormatter: value => ColumnFormatterPercentDecimals(value, 1),
            renderEditCell: params => <PercentageColumn {...params} />,
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatPercent(row.targetMarginCurrent, 1, 1) !== formatPercent(row.targetMarginNew, 1, 1)
                    ? 'values-diff'
                    : '',
        },
        {
            field: 'targetMarginAdj',
            headerName: 'Target Margin Adj.',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterPercentDecimals(value, 1),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'baseCostCurrent',
            headerName: 'Base Cost (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'baseCostLastChange',
            headerName: 'Base Cost Last Change',
            width: 150,
            valueGetter: ColumnFormatterSlashDate,
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'baseCostNew',
            headerName: 'Base Cost Input ($/Lb)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'baseCostHoldPrice',
            headerName: 'Base Cost Hold Price',
            width: 150,
            type: 'boolean',
            valueGetter: ColumnFormatterBoolean,
            filterOperators: getEqualsGridStringOperators(),
        },
        {
            field: 'marketMovementCurrent',
            headerName: 'Market Movement (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'marketMovementLastChange',
            headerName: 'Market Movement Last Change',
            width: 150,
            valueGetter: ColumnFormatterSlashDate,
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'marketMovementNew',
            headerName: 'Market Movement Input ($/Lb)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'marketMovementHoldPrice',
            headerName: 'Market Movement Hold Price',
            width: 150,
            type: 'boolean',
            valueGetter: ColumnFormatterBoolean,
            filterOperators: getEqualsGridStringOperators(),
        },
        {
            field: 'nonMaterialCostAdj',
            headerName: 'Non-Material Cost Adj.',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'pcpFreightCost',
            headerName: 'PCP Freight Cost',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'replacementCost',
            headerName: 'Regional Replacement Cost',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'nonMaterialCost',
            headerName: 'Non-Material Cost',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'smallOrderAdder',
            headerName: 'Small Order Adder',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'bookPriceCurrent',
            headerName: 'Book Price (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'bookPriceLastChange',
            headerName: 'Book Price Last Change',
            width: 150,
            valueGetter: ColumnFormatterSlashDate,
            filterOperators: getLimitedGridNumericOperators(),
            renderCell: params => {
                return (
                    <BookPriceLastChangeCol gridParams={params} exchangeRate={exchangeRate}></BookPriceLastChangeCol>
                );
            },
        },
        {
            field: 'marketPriceRecommendation',
            headerName: 'Market Price Recommendation - Current',
            width: 150,
            type: 'number',
            hideable: false,
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'previousMarketPriceRecommendation',
            headerName: 'Previous Market Price Recommendation',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'previousMarketTMRecommendation',
            headerName: 'Previous Market TM Recommendation',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterPercentDecimals(value, 1),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'bookPriceNew',
            headerName: 'Book Price (New)',
            width: 150,
            type: 'number',
            editable: editable,
            valueSetter: (value, row) => ColumnSetterExchangeRate(value, row, exchangeRate, 'bookPriceNew'),
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatDecimal(row.bookPriceCurrent, 2, 2) !== formatDecimal(row.bookPriceNew, 2, 2)
                    ? 'values-diff'
                    : '',
            renderEditCell: params => <PriceColumn {...params} />,
            preProcessEditCellProps: params => {
                const hasError = params.props.value > 100000 ? 'Above max value (100000)' : false;
                return { ...params.props, error: hasError };
            },
        },
        {
            field: 'floorPriceCurrent',
            headerName: 'Floor Price (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'floorPriceNew',
            headerName: 'Floor Price (New)',
            width: 150,
            type: 'number',
            editable: editable,
            valueSetter: (value, row) => ColumnSetterExchangeRate(value, row, exchangeRate, 'floorPriceNew'),
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatDecimal(row.floorPriceCurrent, 2, 2) !== formatDecimal(row.floorPriceNew, 2, 2)
                    ? 'values-diff'
                    : '',
            renderEditCell: params => <PriceColumn {...params} />,
        },
        {
            field: 'floorMarginCurrent',
            headerName: 'Floor Margin % (Current)',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterPercentDecimals(value, 1),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'floorMarginNew',
            headerName: 'Floor Margin % (New)',
            width: 150,
            type: 'number',
            editable: editable,
            valueFormatter: value => ColumnFormatterPercentDecimals(value, 1),
            renderEditCell: params => <PercentageColumn {...params} />,
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatPercent(row.floorMarginCurrent, 1, 1) !== formatPercent(row.floorMarginNew, 1, 1)
                    ? 'values-diff'
                    : '',
        },
        {
            field: 'floorMarginAllInCurrent',
            headerName: 'Floor Margin % All In (Current)',
            width: 175,
            type: 'number',
            valueGetter: params => ColumnFormatterPercentDecimals(params, 1),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'floorMarginAllInNew',
            headerName: 'Floor Margin % All In (New)',
            width: 150,
            type: 'number',
            editable: editable,
            valueFormatter: params => ColumnFormatterPercentDecimals(params, 1),
            renderEditCell: params => <PercentageColumn {...params} />,
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatPercent(row.floorMarginAllInCurrent, 1, 1) !== formatPercent(row.floorMarginAllInNew, 1, 1)
                    ? 'values-diff'
                    : '',
        },
        {
            field: 'changeCount',
            headerName: 'Change Count',
            width: 150,
            type: 'number',
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'updatedByEmail',
            headerName: 'Most Recent Change By',
            width: 150,
        },
        {
            field: 'finalTargetMargin',
            headerName: 'Final Target Margin',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterPercentDecimals(value, 1),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'targetMarginChange',
            headerName: 'Target Margin Change',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterPercentDecimals(value, 1),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'finalBaseCost',
            headerName: 'Final Base Cost',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'baseCostChange',
            headerName: 'Base Cost Change',
            width: 150,
            type: 'number',
            valueGetter: ColumnFormatterPercent,
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'finalMarketMovement',
            headerName: 'Final Market Movement',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'marketMovementChange',
            headerName: 'Market Movement Change',
            width: 150,
            type: 'number',
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'finalBookPrice',
            headerName: 'Final Book Price',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            filterOperators: getLimitedGridNumericOperators(),
            renderCell: params => {
                return <FinalBookPriceCol gridParams={params} exchangeRate={exchangeRate}></FinalBookPriceCol>;
            },
        },
        {
            field: 'bookPriceChange',
            headerName: 'Book Price Change',
            width: 150,
            type: 'number',
            valueGetter: ColumnFormatterPercent,
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'finalFloorPrice',
            headerName: 'Final Floor Price',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'floorPriceChange',
            headerName: 'Floor Price Change',
            width: 150,
            type: 'number',
            valueGetter: ColumnFormatterPercent,
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'floorVsBookPriceDiff',
            headerName: 'Floor vs Book % Diff',
            width: 150,
            type: 'number',
            valueGetter: value => ColumnFormatterPercentDecimals(value, 1),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'finalFloorMarginAllIn',
            headerName: 'Final Floor Margin % All In',
            width: 150,
            type: 'number',
            valueGetter: params => ColumnFormatterPercentDecimals(params, 1),
            filterOperators: getLimitedGridNumericOperators(),
        },
    ];
}
