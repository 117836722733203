import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { GridEditInputCell, GridRenderEditCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { useLayoutEffect, useRef } from 'react';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));

export function PriceColumn<T extends GridValidRowModel>(props: GridRenderEditCellParams<T, string>) {
    const { error } = props;
    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        ref.current?.getElementsByTagName('input')[0].select();
    }, []);

    return (
        <StyledTooltip open={!!error} title={error}>
            <div ref={ref}>
                <GridEditInputCell
                    {...props}
                    inputProps={{ step: 0.01, min: 0 }}
                    onKeyDown={(event: any) => {
                        if (event.key.toLocaleLowerCase() === 'e') {
                            event.preventDefault();
                        }
                    }}
                />
            </div>
        </StyledTooltip>
    );
}
