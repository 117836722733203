import { useService } from '@price-for-profit/service-provider';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { CostMasterExportExcelProps } from 'shared/types';

export function useCostExcelExport() {
    const { costService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const mutation = useMutation<void, Error, CostMasterExportExcelProps>({
        mutationFn: costExcelExportProps => costService.exportCostMaster(costExcelExportProps),
        onSuccess: () => {
            enqueueSnackbar('Cost master was successfully exported.', { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar('There was a problem exporting the cost master.', { variant: 'error' });
        },
    });
    return mutation;
}
