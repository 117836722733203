import { ServerSideState, generateCollectionFilter } from '@price-for-profit/data-grid';
import {
    DataAccessPaginatedResponse,
    IDataAccessService,
    IFilter,
    NoInfer,
    compare,
} from '@price-for-profit/micro-services';
import { DATASTORE_SQL, TABLE_SURCHARGE_MASTER } from 'shared/constants';
import { Surcharge, SurchargeUpdate, UpdateSurchargeMasterParameters } from 'shared/types';
export interface ISurchargeService {
    getSurchargeData(state: ServerSideState): Promise<DataAccessPaginatedResponse<Surcharge>>;
    getAllSurcharges(): Promise<Surcharge[]>;
    saveRow({ newRow, oldRow, user }: UpdateSurchargeMasterParameters): Promise<void>;
}
type UpdatedSurchargeFields = Pick<
    SurchargeUpdate,
    'surchargeValueNew' | 'changeCount' | 'notes' | 'updatedBy' | 'updatedByEmail' | 'updatedDate'
>;
function surchargeToUpdateSurcharge(table: Surcharge): UpdatedSurchargeFields {
    return {
        surchargeValueNew: table.surchargeValueNew,
        changeCount: table.changeCount,
        notes: table.notes?.trim() || '',
        updatedDate: '',
        updatedBy: '',
        updatedByEmail: '',
    };
}
function updateSurchargeMetadataFields(
    editedSurchargeValues: UpdatedSurchargeFields,
    user: drive.UserInfo
): SurchargeUpdate {
    const today = new Date().toISOString();
    return {
        ...editedSurchargeValues,
        updatedDate: today,
        updatedBy: user.id,
        updatedByEmail: user.email,
    };
}
export class SurchargeService implements ISurchargeService {
    constructor(private dasService: IDataAccessService, private clientId: string) {}

    async getSurchargeData(state: ServerSideState): Promise<DataAccessPaginatedResponse<Surcharge>> {
        const baseFilter: IFilter<Surcharge> = {
            property: 'deletedDate',
            operator: 'isnull',
            value: undefined,
        };

        return await this.dasService.getCollection<Surcharge, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_SURCHARGE_MASTER,
            page: state.paginationModel.page,
            pageSize: state.paginationModel.pageSize,
            sortBy: state.sortModel[0]?.field as NoInfer<keyof Surcharge>,
            sortDescending: state.sortModel[0]?.sort === 'desc' ? true : false,
            collectionFilter: generateCollectionFilter<Surcharge>(state.filterModel, baseFilter),
        });
    }

    async getAllSurcharges(): Promise<Surcharge[]> {
        const baseFilter: IFilter<Surcharge> = {
            property: 'deletedDate',
            operator: 'isnull',
            value: undefined,
        };

        const { data } = await this.dasService.getCollection<Surcharge, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_SURCHARGE_MASTER,
            pageSize: 1000,
            collectionFilter: baseFilter,
        });

        return data;
    }

    async saveRow({ newRow, oldRow, user }: UpdateSurchargeMasterParameters): Promise<void> {
        const [newSurcharge, oldSurcharge] = [newRow, oldRow].map(surchargeToUpdateSurcharge);
        const surchargeOperations = compare(oldSurcharge, updateSurchargeMetadataFields(newSurcharge, user));
        await this.dasService.patchRow<Surcharge, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_SURCHARGE_MASTER,
            key: newRow.id,
            operations: surchargeOperations,
        });
    }
}
