export enum ProductTypeFilter {
    ALUMINUM = 'ALUMINUM',
    CARBON = 'CARBON',
    STAINLESS = 'STAINLESS',
    OTHER = 'OTHER',
}

export enum FormTypeFilter {
    FLAT = 'FLAT',
    PF = 'PF',
    LONG = 'LONG',
    PLATE = 'PLATE',
}
