import { HelpOutline } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip } from '@mui/material';
import { ServerSideState } from '@price-for-profit/data-grid';
import { CostMassActionConfirmationDialog } from './cost-mass-action-confirmation-dialog';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { NumberTextField, SelectTextField } from 'shared/components';
import {
    MASS_ACTION_FIELD_TOOLTIP,
    MASS_ACTION_HOLD_PRICE_TOOLTIP,
    MASS_ACTION_MODE_TOOLTIP,
    MASS_ACTION_THRESHOLD_WARNING,
} from 'shared/constants';
import { useToggle } from '@price-for-profit/react-hooks';
import { useEventStatusCreate } from 'shared/mutations';
import { useEventTriggerAdfPipeline } from 'shared/mutations/event-trigger-adf-pipeline';
import { useMassActionCostMaster } from 'shared/mutations/mass-action-cost-master';
import { CostMasterMassActionParameters } from 'shared/types';
import { generateCorrelationId } from 'shared/utility';
import { useCostMasterTableState } from './table-def';

interface CostMassActionForm {
    field: string;
    input: number;
    mode: string;
    holdPrice: boolean;
}

interface CostMassActionDialogProps {
    isMassActionOpened: boolean;
    onMassActionClose: () => void;
    user: drive.UserInfo;
    state: ServerSideState;
}

export function CostMassActionDialog({
    isMassActionOpened,
    onMassActionClose,
    user,
    state,
}: CostMassActionDialogProps) {
    const tableState = useCostMasterTableState();
    const massActionMutation = useMassActionCostMaster();
    const triggerAdfMutation = useEventTriggerAdfPipeline();
    const createEventStatus = useEventStatusCreate();
    const methods = useForm<CostMassActionForm>({
        defaultValues: {},
    });
    const { handleSubmit, getValues, watch } = methods;
    const [isConfirmationDialogOpened, toggleConfirmationDialog] = useToggle(false);

    const onConfirmationDialogClose = useCallback(
        async (confirm: boolean) => {
            const form = getValues();
            if (confirm) {
                // TODO: create function for the logic below as it is being repeated twice in this file
                const massActionCorrelationId = generateCorrelationId();
                const params: CostMasterMassActionParameters = {
                    field: form.field,
                    input: form.input || 0,
                    mode: form.mode || '',
                    holdPrice: form.holdPrice || false,
                    updatedBy: user.id,
                    updatedByEmail: user.email,
                    massActionCorrelationId,
                };
                await massActionMutation.mutateAsync({ params, filterModel: state.filterModel });
                await createEventStatus.mutateAsync({
                    eventType: 'MassAction',
                    eventTrigger: 'MassActionStarted',
                    actor: user.email,
                    correlationId: massActionCorrelationId,
                });
                await triggerAdfMutation.mutateAsync({
                    actor: user.id,
                    eventType: 'application.event.triggered',
                    subject: 'Build and Submit Batch Process - PostMassAction',
                    eventBody: { email: user.email, massActionCorrelationId },
                    correlationId: massActionCorrelationId,
                });
                onMassActionClose();
            }
            toggleConfirmationDialog();
        },
        [
            getValues,
            user,
            onMassActionClose,
            toggleConfirmationDialog,
            massActionMutation,
            state.filterModel,
            createEventStatus,
            triggerAdfMutation,
        ]
    );

    const openConfirmationDialog = async () => {
        toggleConfirmationDialog();
    };

    const onSubmit = async (form: CostMassActionForm) => {
        if (tableState.filteredRowsCount.get() > MASS_ACTION_THRESHOLD_WARNING) {
            openConfirmationDialog();
        } else {
            const massActionCorrelationId = generateCorrelationId();
            const params: CostMasterMassActionParameters = {
                field: form.field,
                input: form.input || 0,
                mode: form.mode || '',
                holdPrice: form.holdPrice || false,
                updatedBy: user.id,
                updatedByEmail: user.email,
                massActionCorrelationId,
            };
            await massActionMutation.mutateAsync({ params, filterModel: state.filterModel });
            await createEventStatus.mutateAsync({
                eventType: 'MassAction',
                eventTrigger: 'MassActionStarted',
                actor: user.email,
                correlationId: massActionCorrelationId,
            });
            await triggerAdfMutation.mutateAsync({
                actor: user.id,
                eventType: 'application.event.triggered',
                subject: 'Build and Submit Batch Process - PostMassAction',
                eventBody: { email: user.email, massActionCorrelationId },
                correlationId: massActionCorrelationId,
            });
            onMassActionClose();
        }
    };

    return (
        <FormProvider {...methods}>
            <Dialog onClose={onMassActionClose} open={isMassActionOpened}>
                <DialogTitle sx={{ textAlign: 'center' }}>Update Price</DialogTitle>
                <DialogContent>{`${tableState.filteredRowsCount.get()} items affected.`}</DialogContent>
                <DialogContent>
                    <Grid
                        id='mass-action-form'
                        component='form'
                        onSubmit={handleSubmit(onSubmit)}
                        container
                        spacing={2}
                    >
                        <Grid item xs={6}>
                            <SelectTextField
                                name='field'
                                label='Field*'
                                options={[
                                    { value: 'primaryBaseConversionNew', label: 'Primary Base / Conversion (New)' },
                                    { value: 'secondaryBaseConversionNew', label: 'Secondary Base / Conversion (New)' },
                                    { value: 'primaryAllocationNew', label: 'Primary Allocation (New)' },
                                    { value: 'processingNew', label: 'Processing (New)' },
                                    { value: 'otherExtrasNew', label: 'Other / Extras (New)' },
                                    {
                                        value: 'inboundFreightNew',
                                        label: 'Primary Inbound Freight Incl. Fuel S/C (New)',
                                    },
                                    {
                                        value: 'secondaryInboundFreightNew',
                                        label: 'Secondary Inbound Freight Incl. Fuel S/C (New)',
                                    },
                                    { value: 'baseCostHoldPrice', label: 'Regional Replacement Cost Hold Price' },
                                    { value: 'marketMovementHoldPrice', label: 'Market Movement Hold Price' },
                                    { value: 'marketMovementAdHocNew', label: 'Market Movement (New)' },
                                ]}
                                inputProps={{
                                    endAdornment: (
                                        <Box sx={{ position: 'absolute', right: '26px', top: '18px' }}>
                                            <Tooltip
                                                title={
                                                    <span style={{ whiteSpace: 'pre-line' }}>
                                                        {MASS_ACTION_FIELD_TOOLTIP}
                                                    </span>
                                                }
                                            >
                                                <HelpOutline
                                                    sx={{ color: 'action.active', fontSize: '18px', cursor: 'pointer' }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    ),
                                }}
                                rules={{
                                    required: { message: 'Field is required.', value: true },
                                }}
                                sx={{ minWidth: 216 }}
                            />
                        </Grid>
                        {!watch('field')?.includes('HoldPrice') && (
                            <Grid item xs={6}>
                                <NumberTextField
                                    name='input'
                                    label='Input*'
                                    decimalScale={9}
                                    rules={{
                                        required: { message: 'Input is required.', value: true },
                                    }}
                                    prefix='$'
                                    sx={{ minWidth: 216 }}
                                />
                            </Grid>
                        )}
                        {!watch('field')?.includes('HoldPrice') && (
                            <Grid item xs={6}>
                                <SelectTextField
                                    name='mode'
                                    label='Mode*'
                                    options={[
                                        { value: 'incremental', label: 'Incremental' },
                                        { value: 'complete', label: 'Complete' },
                                    ]}
                                    inputProps={{
                                        endAdornment: (
                                            <Box sx={{ position: 'absolute', right: '26px', top: '18px' }}>
                                                <Tooltip
                                                    title={
                                                        <span style={{ whiteSpace: 'pre-line' }}>
                                                            {MASS_ACTION_MODE_TOOLTIP}
                                                        </span>
                                                    }
                                                >
                                                    <HelpOutline
                                                        sx={{
                                                            color: 'action.active',
                                                            fontSize: '18px',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Box>
                                        ),
                                    }}
                                    rules={{
                                        required: { message: 'Mode is required.', value: true },
                                    }}
                                    sx={{ minWidth: 216 }}
                                />
                            </Grid>
                        )}
                        {watch('field')?.includes('HoldPrice') && (
                            <Grid item xs={6}>
                                <SelectTextField
                                    name='holdPrice'
                                    label='Hold Price*'
                                    options={[
                                        { value: false, label: 'No' },
                                        { value: true, label: 'Yes' },
                                    ]}
                                    inputProps={{
                                        endAdornment: (
                                            <Box sx={{ position: 'absolute', right: '26px', top: '18px' }}>
                                                <Tooltip
                                                    title={
                                                        <span style={{ whiteSpace: 'pre-line' }}>
                                                            {MASS_ACTION_HOLD_PRICE_TOOLTIP}
                                                        </span>
                                                    }
                                                >
                                                    <HelpOutline
                                                        sx={{
                                                            color: 'action.active',
                                                            fontSize: '18px',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Box>
                                        ),
                                    }}
                                    rules={{
                                        validate: (value: string) => {
                                            if (value === '') {
                                                return 'Hold Price is required.';
                                            }
                                        },
                                    }}
                                    sx={{ minWidth: 216 }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                    <CostMassActionConfirmationDialog
                        isConfirmationOpened={isConfirmationDialogOpened}
                        onConfirmationClose={onConfirmationDialogClose}
                    />
                    <Button form='mass-action-form' type='submit' variant='outlined'>
                        Apply Mass Action
                    </Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
}
