import { useService } from '@price-for-profit/service-provider';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { QUERYKEY_SUBMISSION_HISTORY_METADATA } from 'shared/constants';

export function useGetSubmissionHistoryMetadata() {
    const { submissionHistoryService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const { data, isError } = useQuery({
        queryKey: [QUERYKEY_SUBMISSION_HISTORY_METADATA],
        queryFn: () => submissionHistoryService.getRecentHistoryMetadata(),
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Failed to fetch historical dropdown options.', { variant: 'error' });
        }
    }, [isError, enqueueSnackbar]);

    return { data };
}
