import { Clear } from '@mui/icons-material';
import { IconButton, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { GridFilterItem } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { QuickFilterRef } from 'shared/components';
import { DropdownOption } from 'shared/types';

interface QuickMultiFilterProps {
    label: string;
    column: string;
    apiRef: React.MutableRefObject<GridApiPremium>;
    options: DropdownOption[];
}

export const QuickMultiFilter = forwardRef<QuickFilterRef, QuickMultiFilterProps>(
    ({ label, column, apiRef, options }, ref) => {
        const [value, setValue] = useState<string[]>([]);
        const [filterId, setFilterId] = useState<string | undefined>(undefined);

        const onChange = (event: SelectChangeEvent<any>) => {
            const {
                target: { value },
            } = event;

            const currentId = filterId || crypto.randomUUID();
            setFilterId(currentId);
            setValue(typeof value === 'string' ? value.split(',') : value);

            var filterItem: GridFilterItem = {
                field: column,
                value: event.target.value,
                id: currentId,
                operator: 'isAnyOf',
            };
            apiRef.current.upsertFilterItem(filterItem);
        };

        const resetFilter = () => {
            var filterItem: GridFilterItem = { id: filterId, field: column, value: value, operator: 'is' };
            apiRef.current.deleteFilterItem(filterItem);
            setValue([]);
            setFilterId(undefined);
        };

        const refResetFilter = () => {
            setValue([]);
            setFilterId(undefined);
        };

        useImperativeHandle(ref, () => ({
            refResetFilter: () => {
                refResetFilter();
            },
        }));

        return (
            <TextField
                select
                variant='outlined'
                label={label}
                size='small'
                sx={{
                    width: '200px',
                }}
                SelectProps={{
                    multiple: true,
                    value,
                    onChange,
                    endAdornment: (
                        <IconButton sx={{ display: value.length ? '' : 'none' }} onClick={resetFilter}>
                            <Clear />
                        </IconButton>
                    ),
                }}
            >
                {options.map(option => (
                    <MenuItem key={option.uniqueId} value={option.value}>
                        {option.value}
                    </MenuItem>
                ))}
            </TextField>
        );
    }
);
