import { CostMasterFuturePage } from './page';
import { Loader } from 'shared/components/loader/loader';
import { useUser } from '@price-for-profit/auth';

export function CostMasterFutureResolver() {
    const user = useUser();

    if (!user) {
        return <Loader />;
    }

    return <CostMasterFuturePage user={user} />;
}
