import { HelpOutline } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip } from '@mui/material';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useToggle } from '@price-for-profit/react-hooks';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { NumberTextField, SelectTextField } from 'shared/components';
import { MASS_ACTION_FIELD_TOOLTIP, MASS_ACTION_MODE_TOOLTIP, MASS_ACTION_THRESHOLD_WARNING } from 'shared/constants';
import { useEventStatusCreate, useMassActionPriceMaster } from 'shared/mutations';
import { useEventTriggerAdfPipeline } from 'shared/mutations/event-trigger-adf-pipeline';
import { PriceMasterMassActionParameters } from 'shared/types';
import { generateCorrelationId } from 'shared/utility';
import { PriceMassActionConfirmationDialog } from './price-mass-action-confirmation-dialog';

interface PriceMassActionForm {
    field: string;
    input: number;
    mode: string;
}

interface PriceMassActionDialogProps {
    isMassActionOpened: boolean;
    onMassActionClose: () => void;
    user: drive.UserInfo;
    state: ServerSideState;
    filteredRowsCount: number;
}

export function PriceMassActionDialog({
    isMassActionOpened,
    onMassActionClose,
    user,
    state,
    filteredRowsCount,
}: PriceMassActionDialogProps) {
    const massActionMutation = useMassActionPriceMaster({ state });
    const triggerAdfMutation = useEventTriggerAdfPipeline();
    const createEventStatus = useEventStatusCreate();
    const methods = useForm<PriceMassActionForm>({
        defaultValues: {},
    });
    const { handleSubmit, getValues, watch } = methods;
    const [isConfirmationDialogOpened, toggleConfirmationDialog] = useToggle(false);

    const triggerMassAction = useCallback(
        async (params: PriceMasterMassActionParameters) => {
            params.input = params.input || 0;
            params.mode = params.mode || '';
            await massActionMutation.mutateAsync(params);
            await createEventStatus.mutateAsync({
                eventType: 'MassAction',
                eventTrigger: 'MassActionStarted',
                actor: user.email,
                correlationId: params.massActionCorrelationId,
            });
            await triggerAdfMutation.mutateAsync({
                actor: user.id,
                eventType: 'application.event.triggered',
                subject: 'Build and Submit Batch Process - PostMassAction',
                eventBody: { email: user.email, massActionCorrelationId: params.massActionCorrelationId },
                correlationId: params.massActionCorrelationId,
            });
            onMassActionClose();
        },
        [massActionMutation, createEventStatus, triggerAdfMutation, user, onMassActionClose]
    );

    const onConfirmationDialogClose = useCallback(
        async (confirm: boolean) => {
            if (confirm) {
                const massActionCorrelationId = generateCorrelationId();
                const params: PriceMasterMassActionParameters = {
                    ...getValues(),
                    updatedBy: user.id,
                    updatedByEmail: user.email,
                    massActionCorrelationId,
                };
                triggerMassAction(params);
            }
            toggleConfirmationDialog();
        },
        [getValues, user, toggleConfirmationDialog, triggerMassAction]
    );

    const openConfirmationDialog = async () => {
        toggleConfirmationDialog();
    };

    const onSubmit = async (form: PriceMassActionForm) => {
        if (filteredRowsCount > MASS_ACTION_THRESHOLD_WARNING) {
            openConfirmationDialog();
        } else {
            const massActionCorrelationId = generateCorrelationId();
            const params: PriceMasterMassActionParameters = {
                ...form,
                updatedBy: user.id,
                updatedByEmail: user.email,
                massActionCorrelationId,
            };
            triggerMassAction(params);
        }
    };

    const displayInputFields = () => {
        return (
            !watch('field') ||
            ['targetMarginNew', 'bookPriceNew', 'floorPriceNew', 'floorMarginNew', 'floorMarginAllInNew'].includes(
                watch('field')
            )
        );
    };

    return (
        <FormProvider {...methods}>
            <Dialog onClose={onMassActionClose} open={isMassActionOpened}>
                <DialogTitle sx={{ textAlign: 'center' }}>Update Price</DialogTitle>
                <DialogContent>{`${filteredRowsCount} items affected.`}</DialogContent>
                <DialogContent>
                    <Grid
                        id='mass-action-form'
                        component='form'
                        onSubmit={handleSubmit(onSubmit)}
                        container
                        spacing={2}
                    >
                        <Grid item xs={displayInputFields() ? 6 : 12}>
                            <SelectTextField
                                name='field'
                                label='Field*'
                                options={[
                                    { value: 'targetMarginNew', label: 'Target Margin % (New)' },
                                    { value: 'bookPriceNew', label: 'Book Price (New)' },
                                    { value: 'floorMarginNew', label: 'Floor Margin % (New)' },
                                    { value: 'floorPriceNew', label: 'Floor Price (New)' },
                                    { value: 'floorMarginAllInNew', label: 'Floor Margin % All In (New)' },
                                    { value: 'targetMarginNewToCurrent', label: 'Target Margin % (New) to Current' },
                                    { value: 'bookPriceNewToCurrent', label: 'Book Price (New) to Current' },
                                    { value: 'floorMarginNewToCurrent', label: 'Floor Margin % (New) to Current' },
                                    { value: 'floorPriceNewToCurrent', label: 'Floor Price (New) to Current' },
                                    {
                                        value: 'floorMarginAllInNewToCurrent',
                                        label: 'Floor Margin % All In (New) to Current',
                                    },
                                ]}
                                inputProps={{
                                    endAdornment: (
                                        <Box sx={{ position: 'absolute', right: '26px', top: '18px' }}>
                                            <Tooltip
                                                title={
                                                    <span style={{ whiteSpace: 'pre-line' }}>
                                                        {MASS_ACTION_FIELD_TOOLTIP}
                                                    </span>
                                                }
                                            >
                                                <HelpOutline
                                                    sx={{ color: 'action.active', fontSize: '18px', cursor: 'pointer' }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    ),
                                }}
                                rules={{
                                    required: { message: 'Field is required.', value: true },
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {displayInputFields() && (
                                <NumberTextField
                                    name='input'
                                    label='Input*'
                                    decimalScale={9}
                                    rules={{
                                        required: { message: 'Input is required.', value: true },
                                    }}
                                    prefix={
                                        watch('field') !== 'targetMarginNew' &&
                                        watch('field') !== 'floorMarginNew' &&
                                        watch('field') !== 'floorMarginAllInNew'
                                            ? '$'
                                            : ''
                                    }
                                    suffix={
                                        watch('field') === 'targetMarginNew' ||
                                        watch('field') === 'floorMarginNew' ||
                                        watch('field') === 'floorMarginAllInNew'
                                            ? '%'
                                            : ''
                                    }
                                />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {displayInputFields() && (
                                <SelectTextField
                                    name='mode'
                                    label='Mode*'
                                    options={[
                                        { value: 'incremental', label: 'Incremental' },
                                        { value: 'complete', label: 'Complete' },
                                    ]}
                                    inputProps={{
                                        endAdornment: (
                                            <Box sx={{ position: 'absolute', right: '26px', top: '18px' }}>
                                                <Tooltip
                                                    title={
                                                        <span style={{ whiteSpace: 'pre-line' }}>
                                                            {MASS_ACTION_MODE_TOOLTIP}
                                                        </span>
                                                    }
                                                >
                                                    <HelpOutline
                                                        sx={{
                                                            color: 'action.active',
                                                            fontSize: '18px',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Box>
                                        ),
                                    }}
                                    rules={{
                                        required: { message: 'Mode is required.', value: true },
                                    }}
                                    sx={{ minWidth: 216 }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                    <PriceMassActionConfirmationDialog
                        filteredRowsCount={filteredRowsCount}
                        isConfirmationOpened={isConfirmationDialogOpened}
                        onConfirmationClose={onConfirmationDialogClose}
                    />
                    <Button form='mass-action-form' type='submit' variant='outlined'>
                        Apply Mass Action
                    </Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
}
