import { SxProps, TextField, Theme } from '@mui/material';
import { useController } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

type NumberTextFieldProps = {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
    label: string;
    prefix?: string;
    suffix?: string;
    rules?: Object;
    readOnly?: boolean;
    defaultValue?: string | number;
    percent?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    fixedDecimalScale?: boolean;
    decimalScale?: number;
    sx?: SxProps<Theme>;
    formatNumber?: boolean;
};

export function NumberTextField({
    name,
    label,
    prefix,
    suffix,
    rules,
    readOnly,
    defaultValue,
    percent,
    fullWidth = true,
    disabled,
    fixedDecimalScale,
    decimalScale = 2,
    sx,
    formatNumber = false,
}: NumberTextFieldProps) {
    const {
        field: { ref, onChange, value, onBlur },
        fieldState: { error },
    } = useController({
        name,
        rules,
        defaultValue,
    });
    if (percent) {
        suffix = '%';
    }
    const fieldValue = percent ? value * 100 : value;
    return (
        <NumericFormat
            id={name}
            prefix={prefix}
            suffix={suffix}
            thousandSeparator={true}
            customInput={TextField}
            onValueChange={v => {
                const value = v.floatValue || v.value;
                const newValue = percent && value ? Number(value) / 100 : value;
                const formattedValue = formatNumber ? Number(newValue) : newValue;
                onChange(formattedValue);
            }}
            onBlur={onBlur}
            value={fieldValue}
            fullWidth={fullWidth}
            decimalScale={decimalScale}
            disabled={disabled}
            fixedDecimalScale={fixedDecimalScale}
            label={label}
            error={Boolean(error)}
            helperText={error?.message}
            variant='outlined'
            readOnly={readOnly}
            getInputRef={ref}
            sx={sx}
        />
    );
}
