import { GridCustomTableToolbar } from '@price-for-profit/data-grid';

interface SurchargeMasterTableToolbarProps {
    user: drive.UserInfo;
}

export function SurchargeMasterTableToolbar({ user }: SurchargeMasterTableToolbarProps) {
    return (
        <GridCustomTableToolbar
            uniqueGridName='SurchargeMaster'
            isShareAllowed={user.layoutsShare}
            canUnshareAny={user.layoutsUnshareAny}
        />
    );
}
