import { Download } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { LinearProgress, Paper, Portal, Stack } from '@mui/material';
import {
    DataGridPremium,
    GridFilterModel,
    GridPinnedColumnFields,
    GridSlots,
    GridSortModel,
    gridFilterModelSelector,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { PAGE_SIZE_OPTIONS, useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { useService } from '@price-for-profit/service-provider';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CurrencyToggle, QuickFilter, QuickFilterRef } from 'shared/components';
import { WarningsQuickFilter } from 'shared/components/warnings-quick-filter';
import {
    QUERYKEY_COST,
    WARNING_BASE_COST_ADJUSTED,
    WARNING_BASE_COST_MAX_CHANGE,
    WARNING_MARKET_MOVEMENT_MAX_CHANGE,
    WARNING_PENDING_FUTURE_EFFECTIVE_DATE_ASSOCIATED,
} from 'shared/constants';
import { useSaveCostRows } from 'shared/hooks';
import { useCostExcelExport } from 'shared/mutations';
import { useGetExchangeRates } from 'shared/queries';
import { CostSummary, FormTypeFilter, ProductTypeFilter } from 'shared/types';
import {
    CostMasterColumns,
    CostMasterTableToolbar,
    costMasterColumnVisibilityInitialState,
    useCostMasterTableState,
} from './table-def';
import { useTrackGenericEvent } from '@price-for-profit/user-activity-tracking';

interface CostMasterTableProps {
    user: drive.UserInfo;
    preferredExchangeRate: number;
    exportButtonContainerRef: React.MutableRefObject<HTMLDivElement | null>;
    apiRef: React.MutableRefObject<GridApiPremium>;
}

export function CostMasterTable({
    user,
    preferredExchangeRate,
    apiRef,
    exportButtonContainerRef,
}: CostMasterTableProps) {
    const { costService, appConfig } = useService();
    const tableState = useCostMasterTableState();
    const [editEnabled, setEditEnabled] = useState(true);
    const queryClient = useQueryClient();
    const exchangeRates = useGetExchangeRates().data;
    const productQuickFilterRef = useRef<QuickFilterRef>(null);
    const formQuickFilterRef = useRef<QuickFilterRef>(null);
    const warningsQuickFilterRef = useRef<QuickFilterRef>(null);
    const exportCostMasterMutation = useCostExcelExport();
    const trackGenericActionMutation = useTrackGenericEvent(appConfig.clientId);

    const warningOptions = [
        WARNING_BASE_COST_MAX_CHANGE,
        WARNING_BASE_COST_ADJUSTED,
        WARNING_MARKET_MOVEMENT_MAX_CHANGE,
        WARNING_PENDING_FUTURE_EFFECTIVE_DATE_ASSOCIATED,
    ];

    const costMasterColumnInit = useMemo(() => {
        return {
            user,
            exchangeRate: preferredExchangeRate,
        };
    }, [user, preferredExchangeRate]);

    const saveCostChanges = useSaveCostRows({ user });

    const setCurrencyType = (newCurrencyType: 'usd' | 'cad') => {
        const columnVisibilityModel = { ...tableState.columns.columnVisibilityModel.get() };

        costMasterColumnInit.exchangeRate = exchangeRates ? exchangeRates[newCurrencyType] : 1.0;

        apiRef.current.updateColumns(CostMasterColumns(costMasterColumnInit));
        if (columnVisibilityModel) {
            apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
        }
    };

    const costMasterColumns = useMemo(() => {
        return CostMasterColumns(costMasterColumnInit);
    }, [costMasterColumnInit]);

    const { getDataGridProps, state } = useI2pServerDataGrid<CostSummary>({
        columns: costMasterColumns,
        name: QUERYKEY_COST,
        dataGridInitialState: {
            columns: {
                columnVisibilityModel: costMasterColumnVisibilityInitialState,
            },
            pinnedColumns: tableState.pinnedColumns.value as GridPinnedColumnFields,
        },
        pageSizeOptions: [...PAGE_SIZE_OPTIONS, 200],
        initialState: {
            paginationModel: tableState.paginationModel?.value || undefined,
            filterModel: (tableState.filter?.filterModel?.value as GridFilterModel) || undefined,
            sortModel: (tableState.sorting?.sortModel?.value as GridSortModel) || undefined,
        },
        getData: async state => {
            return costService.getCostData(state);
        },
    });

    const handleFilterChange = (newFilterModel: GridFilterModel) => {
        const productFilter = newFilterModel.items.find(item => item.field === 'product');
        const formFilter = newFilterModel.items.find(item => item.field === 'form');
        const warningsFilter = newFilterModel.items.find(
            item =>
                item.field === 'baseCostMaxChangeWarning' ||
                item.field === 'baseCostAdjustedWarning' ||
                item.field === 'marketMovementMaxChangeWarning' ||
                item.field === 'pendingFutureEffectiveDateWarning'
        );

        if (!productFilter) {
            if (productQuickFilterRef.current) {
                productQuickFilterRef.current.refResetFilter();
            }
        }

        if (!formFilter) {
            if (formQuickFilterRef.current) {
                formQuickFilterRef.current.refResetFilter();
            }
        }

        if (!warningsFilter) {
            if (warningsQuickFilterRef.current) {
                warningsQuickFilterRef.current.refResetFilter();
            }
        }
    };

    useEffect(() => {
        handleFilterChange(state.filterModel);
    }, [state.filterModel]);

    const dataGridProps = getDataGridProps();

    const handleChangeCount = useCallback((newRow: CostSummary) => {
        let changeCount = 0;
        if (newRow.primaryBaseConversionNew.toFixed(2) !== newRow.primaryBaseConversionCurrent.toFixed(2))
            changeCount++;
        if (newRow.secondaryBaseConversionNew.toFixed(2) !== newRow.secondaryBaseConversionCurrent.toFixed(2))
            changeCount++;
        if (newRow.primaryAllocationNew.toFixed(2) !== newRow.primaryAllocationCurrent.toFixed(2)) changeCount++;
        if (newRow.processingNew.toFixed(2) !== newRow.processingCurrent.toFixed(2)) changeCount++;
        if (newRow.otherExtrasNew.toFixed(2) !== newRow.otherExtrasCurrent.toFixed(2)) changeCount++;
        if (newRow.inboundFreightNew.toFixed(2) !== newRow.inboundFreightCurrent.toFixed(2)) changeCount++;
        if (newRow.secondaryInboundFreightNew.toFixed(2) !== newRow.secondaryInboundFreightCurrent.toFixed(2))
            changeCount++;
        if (newRow.marketMovementAdHocNew.toFixed(2) !== newRow.marketMovementAdHocCurrent.toFixed(2)) changeCount++;

        newRow.changeCount = changeCount;
    }, []);

    const trackEditedRow = useCallback(
        async (newRow: CostSummary, oldRow: CostSummary) => {
            if (JSON.stringify(newRow) !== JSON.stringify(oldRow)) {
                handleChangeCount(newRow);
                await saveCostChanges([newRow], [oldRow]);
            }
        },
        [saveCostChanges, handleChangeCount]
    );

    const processRowUpdate = useCallback(
        (newRow: CostSummary, oldRow: CostSummary) =>
            new Promise<CostSummary>(async resolve => {
                try {
                    setEditEnabled(false);
                    await trackEditedRow(newRow, oldRow);
                    resolve(newRow);
                    queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST] });
                } catch (e) {
                    resolve(oldRow);
                } finally {
                    setEditEnabled(true);
                }
            }),
        [trackEditedRow, queryClient]
    );

    const exportCostMaster = () => {
        const costMasterFilterModel = gridFilterModelSelector(apiRef);

        exportCostMasterMutation.mutate({
            filterModel: costMasterFilterModel,
            exchangeRate: costMasterColumnInit.exchangeRate,
        });

        trackGenericActionMutation.mutateAsync({
            action: 'Download Cost Master',
            user,
        });
    };

    return (
        <Paper
            sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                },
                height: 'calc(100vh - 270px)',
                '& .MuiDataGrid-cell--editable': {
                    '& .MuiInputBase-root': {
                        height: '100%',
                    },
                },
                '& .cell-warning': {
                    backgroundColor: 'rgba(244, 67, 54, 0.3)',
                },
                '& .values-diff': {
                    backgroundColor: 'rgba(245, 213, 39, 0.5) !important',
                },
            }}
        >
            <Portal container={() => exportButtonContainerRef.current}>
                <LoadingButton
                    size='small'
                    sx={{ ml: 1 }}
                    variant='outlined'
                    color='warning'
                    startIcon={<Download />}
                    onClick={exportCostMaster}
                    loading={exportCostMasterMutation.isPending}
                    loadingPosition='start'
                >
                    Download Cost Master
                </LoadingButton>
            </Portal>

            <Stack
                direction='row'
                spacing={1}
                paddingTop={1}
                paddingBottom={1}
                alignItems='center'
                paddingLeft={1}
                data-testid='filter-toolbar'
            >
                <CurrencyToggle rates={exchangeRates} setCurrency={setCurrencyType}></CurrencyToggle>
                <QuickFilter
                    label='Product'
                    column='product'
                    apiRef={apiRef}
                    options={Object.values(ProductTypeFilter).map(x => ({ label: x.toString(), value: x.toString() }))}
                    ref={productQuickFilterRef}
                />
                <QuickFilter
                    label='Form'
                    column='form'
                    apiRef={apiRef}
                    options={Object.values(FormTypeFilter).map(x => ({ label: x.toString(), value: x.toString() }))}
                    ref={formQuickFilterRef}
                />
                <WarningsQuickFilter
                    label='Warnings'
                    column='warnings'
                    apiRef={apiRef}
                    options={warningOptions}
                    ref={warningsQuickFilterRef}
                />
            </Stack>

            <DataGridPremium
                {...dataGridProps}
                apiRef={apiRef}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: CostMasterTableToolbar as GridSlots['toolbar'],
                    loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
                }}
                slotProps={{
                    toolbar: {
                        user,
                        state,
                    },
                }}
                onStateChange={state => {
                    if (tableState.filteredRowsCount.value !== state.rows.totalRowCount) {
                        tableState.filteredRowsCount.set(state.rows.totalRowCount);
                    }
                }}
                onColumnVisibilityModelChange={model => tableState.columns.columnVisibilityModel.set(model)}
                isCellEditable={() => editEnabled}
            />
        </Paper>
    );
}
