import { StatusResponseData } from '@price-for-profit/micro-services';
import { setMassActionCorrelationId } from './mass-action-correlation-id';
import { MASS_ACTION_SUCCEEDED_STATUSES, MASS_ACTION_FAILED_STATUSES } from 'shared/constants';

export function generateCorrelationId(): string {
    const massActionCorrelationId = crypto.randomUUID().replaceAll('-', '');
    setMassActionCorrelationId(massActionCorrelationId);
    return massActionCorrelationId;
}

export function isFinalizedStatus(data: StatusResponseData[] | undefined): boolean {
    return (
        typeof data?.find(s =>
            [...MASS_ACTION_SUCCEEDED_STATUSES, ...MASS_ACTION_FAILED_STATUSES].includes(s.eventTrigger)
        ) !== 'undefined'
    );
}

export function isSuccessStatus(data: StatusResponseData[] | undefined): boolean {
    return typeof data?.find(s => MASS_ACTION_SUCCEEDED_STATUSES.includes(s.eventTrigger)) !== 'undefined';
}
