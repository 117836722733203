import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { QUERYKEY_COST_WARNING_SUMMARY } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';
import { useEffect } from 'react';

export function useGetCostMetrics() {
    const { costService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const { data, isError } = useQuery({
        queryKey: [QUERYKEY_COST_WARNING_SUMMARY],
        queryFn: () => costService.getCostWarningData(),
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Failed to fetch cost warning summary data.', { variant: 'error' });
        }
    }, [isError, enqueueSnackbar]);

    return {
        costMetrics: data,
    };
}
