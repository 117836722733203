import { Drawer, Paper, Stack, Typography } from '@mui/material';
import { InfoBars } from '@price-for-profit/shared-components';
import { useState } from 'react';
import { CostMetrics } from 'shared/types';
import { formatMoneyCompact } from 'shared/utility';
import { DecisionSupportDrawerContent } from './decision-support-drawer';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
interface CostMasterMetricsProps {
    user: drive.UserInfo;
    costMetrics: CostMetrics;
    apiRef: React.MutableRefObject<GridApiPremium>;
}

export function CostMasterMetrics({ user, costMetrics, apiRef }: CostMasterMetricsProps) {
    const canViewDecisionSupport = user.coe || user.procurement || user.canada_procurement;
    const [decisionDrawerOpen, setDecisionDrawerOpen] = useState(false);
    const toggleDecisionSupportDrawer = () => {
        setDecisionDrawerOpen(!decisionDrawerOpen);
    };

    return (
        <Paper elevation={0}>
            <Stack p={1} direction='row' spacing={1}>
                <InfoBars title='Warnings Count'>
                    <Typography align='center' variant='h6' lineHeight={1.25}>
                        <strong style={costMetrics.warningsCount > 0 ? { color: 'red' } : {}}>
                            {costMetrics.warningsCount}
                        </strong>
                    </Typography>
                </InfoBars>
                <InfoBars title='Warning $'>
                    <Typography align='center' variant='h6' lineHeight={1.25}>
                        <strong style={costMetrics.warningsTotal > 0 ? { color: 'red' } : {}}>
                            {formatMoneyCompact(costMetrics.warningsTotal)}
                        </strong>
                    </Typography>
                </InfoBars>
                {canViewDecisionSupport ? (
                    <>
                        <InfoBars title='Decision Support' onClickHandler={toggleDecisionSupportDrawer}>
                            <Typography align='center' variant='h6' lineHeight={1.25}>
                                <strong>{'-'}</strong>
                            </Typography>
                        </InfoBars>

                        <Drawer anchor='bottom' open={decisionDrawerOpen} onClose={() => setDecisionDrawerOpen(false)}>
                            <DecisionSupportDrawerContent apiRef={apiRef} />
                        </Drawer>
                    </>
                ) : (
                    <></>
                )}
            </Stack>
        </Paper>
    );
}
