import { ServerSideState, generateCollectionFilter } from '@price-for-profit/data-grid';
import { DataAccessPaginatedResponse, IDataAccessService, NoInfer, compare } from '@price-for-profit/micro-services';
import { DATASTORE_SQL, TABLE_WARNING_CONFIGURATION } from 'shared/constants';
import { UpdateWarningConfigurationParameters, WarningConfiguration, WarningConfigurationUpdate } from 'shared/types';

export interface IWarningConfigurationService {
    getWarningConfigurationData(state: ServerSideState): Promise<DataAccessPaginatedResponse<WarningConfiguration>>;
    saveRow({ newRow, oldRow, user }: UpdateWarningConfigurationParameters): Promise<void>;
    getWarningsData(): Promise<string[]>;
}
type UpdatedWarningConfigurationFields = Pick<
    WarningConfigurationUpdate,
    'value' | 'updatedBy' | 'updatedByEmail' | 'updatedDate'
>;
function warningConfigurationToUpdateWarningConfiguration(
    table: WarningConfiguration
): UpdatedWarningConfigurationFields {
    return {
        value: table.value,
        updatedDate: '',
        updatedBy: '',
        updatedByEmail: '',
    };
}
function updateWarningConfigurationMetadataFields(
    editedWarningConfigurationValues: UpdatedWarningConfigurationFields,
    user: drive.UserInfo
): WarningConfigurationUpdate {
    const today = new Date().toISOString();
    return {
        ...editedWarningConfigurationValues,
        updatedDate: today,
        updatedBy: user.displayName,
        updatedByEmail: user.email,
    };
}
export class WarningConfigurationService implements IWarningConfigurationService {
    constructor(private dasService: IDataAccessService, private clientId: string) {}

    async getWarningConfigurationData(
        state: ServerSideState
    ): Promise<DataAccessPaginatedResponse<WarningConfiguration>> {
        return await this.dasService.getCollection<WarningConfiguration, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_WARNING_CONFIGURATION,
            page: state.paginationModel.page,
            pageSize: state.paginationModel.pageSize,
            sortBy: state.sortModel[0]?.field as NoInfer<keyof WarningConfiguration>,
            sortDescending: state.sortModel[0]?.sort === 'desc' ? true : false,
            collectionFilter: generateCollectionFilter<WarningConfiguration>(state.filterModel, undefined),
        });
    }

    async saveRow({ newRow, oldRow, user }: UpdateWarningConfigurationParameters): Promise<void> {
        const [newWarningConfiguration, oldWarningConfiguration] = [newRow, oldRow].map(
            warningConfigurationToUpdateWarningConfiguration
        );
        const warningConfigurationOperations = compare(
            oldWarningConfiguration,
            updateWarningConfigurationMetadataFields(newWarningConfiguration, user)
        );
        await this.dasService.patchRow<WarningConfiguration, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_WARNING_CONFIGURATION,
            key: newRow.id,
            operations: warningConfigurationOperations,
        });
    }

    async getWarningsData(): Promise<string[]> {
        const { data } = await this.dasService.getCollection<WarningConfiguration, typeof DATASTORE_SQL>({
            clientId: this.clientId,
            databaseLabel: DATASTORE_SQL,
            tableId: TABLE_WARNING_CONFIGURATION,
            pageSize: 1000,
        });

        const warnings = data.map(warning => warning.warningMessage);

        return warnings.filter(function (item, pos) {
            return warnings.indexOf(item) === pos;
        });
    }
}
