import { useService } from '@price-for-profit/service-provider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERYKEY_COST_FUTURE_RATE } from 'shared/constants';
import { UpdateCostMasterFutureRateParameters } from 'shared/types';

export function useSaveCostMasterFutureRateRows() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { costService } = useService();

    const mutation = useMutation<void, Error, UpdateCostMasterFutureRateParameters>({
        mutationFn: request => costService.saveCostFutureRate(request),
        onSuccess: () => {
            enqueueSnackbar('Cost fields calculated and saved', { variant: 'success' });
        },
        onError: () => {
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST_FUTURE_RATE] });
            enqueueSnackbar('Cost fields failed to calculate and save', { variant: 'error' });
        },
    });

    return mutation;
}
