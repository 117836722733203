import { SideDrawerTableData } from 'shared/components';
import { PERFORMANCE_METRICS_FOOTER } from 'shared/constants';
import { PerformanceMetrics, Price, SubmissionHistory } from 'shared/types';
import { formatExchangeRate, formatPercent, formatSlashDate } from 'shared/utility';

const mapPerformanceMetrics = (metrics: PerformanceMetrics, header: string, footer: string) => {
    if (metrics) {
        return [
            {
                header: header,
                footer: footer,
                rows: [
                    {
                        label: 'Below Margin Quote Count',
                        value: `${metrics.belowBookQuoteCount} (${formatPercent(metrics.belowBookQuoteCountPct, 1)})`,
                    },
                    {
                        label: 'At Book Quote Count',
                        value: `${metrics.atBookQuoteCount} (${formatPercent(metrics.atBookQuoteCountPct, 1)})`,
                    },
                    {
                        label: 'Above Book Quote Count',
                        value: `${metrics.aboveBookQuoteCount} (${formatPercent(metrics.aboveBookQuoteCountPct, 1)})`,
                    },
                    {
                        label: 'Total Quote Count',
                        value: `${metrics.totalQuoteCount} (${metrics.quotesPerDay.toFixed(2)} / Day)`,
                    },
                    {
                        label: 'Customer Count',
                        value: `${metrics.customerCount} (${metrics.customersPerDay.toFixed(2)} / Day)`,
                    },
                    {
                        label: 'Quote Conversion Rate',
                        value: `${formatPercent(metrics.quoteConversionRate, 1)}`,
                    },
                    {
                        label: 'Tons',
                        value: `${metrics.tons.toFixed(0)} (${metrics.tonsPerDay.toFixed(0)} / Day)`,
                    },
                ],
            },
        ];
    } else {
        return [
            {
                header: header,
                footer: '',
                rows: [
                    {
                        label: 'No Data',
                        value: 'N/A',
                    },
                ],
            },
        ];
    }
};

export const mapMultiMarketProducts = (
    priceRecords: Price[],
    exchangeRate: number,
    exchangeRateFormatter: Function
) => {
    const header = 'Price by Office';
    const salesOfficeRows: { label: string; value: string }[] = [];
    if (priceRecords) {
        priceRecords.forEach(record => {
            salesOfficeRows.push({
                label: `${record.so}`,
                value: `Current: ${exchangeRateFormatter(
                    record.bookPriceCurrent,
                    exchangeRate,
                    2
                )} | New: ${exchangeRateFormatter(record.bookPriceNew, exchangeRate, 2)}`,
            });
        });

        return [
            {
                header: header,
                footer: '',
                rows: salesOfficeRows,
            },
        ];
    } else {
        return [
            {
                header: header,
                footer: '',
                rows: [
                    {
                        label: 'No Data',
                        value: 'N/A',
                    },
                ],
            },
        ];
    }
};

export const mapMonthlyPerformanceMetrics = (metrics: PerformanceMetrics) =>
    mapPerformanceMetrics(metrics, 'Trailing 20 Business Days', PERFORMANCE_METRICS_FOOTER);

export const mapWeeklyPerformanceMetrics = (metrics: PerformanceMetrics) =>
    mapPerformanceMetrics(metrics, 'Trailing 5 Business Days', '');

export const mapBookPriceLastChange = (
    submissionHistories: SubmissionHistory[],
    exchangeRate: number
): SideDrawerTableData[] => {
    return [
        {
            header: 'Last 10 updates',
            footer: '',
            rows: submissionHistories.map(submissionHistory => ({
                label: `${formatSlashDate(new Date(submissionHistory.updatedDate))} (by ${
                    submissionHistory.updatedByEmail
                })`,
                value: `Old Price: ${formatExchangeRate(
                    submissionHistory.bookPriceCurrent,
                    exchangeRate,
                    2
                )} | Changed to Price: ${formatExchangeRate(submissionHistory.finalBookPrice, exchangeRate, 2)}`,
            })),
        },
    ];
};
