import { BarChart } from '@mui/icons-material';
import { Drawer } from '@mui/material';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { SideDrawerTable, SideDrawerTableData } from 'shared/components';
import { useService } from '@price-for-profit/service-provider';
import { mapMonthlyPerformanceMetrics, mapWeeklyPerformanceMetrics } from 'shared/utility/side-drawer-maps';

interface PerformanceMetricsButtonProps {
    gridParams: GridRowParams;
}

export function PerformanceMetricsButton(props: PerformanceMetricsButtonProps) {
    const { priceService } = useService();
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [drawerData, setDrawerData] = useState<SideDrawerTableData[]>();
    const [drawerLoading, setDrawerLoading] = useState<boolean>(false);

    return (
        <>
            <Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <SideDrawerTable tableData={drawerData} loading={drawerLoading} />
            </Drawer>
            <GridActionsCellItem
                label='View Performance Metrics'
                icon={<BarChart></BarChart>}
                onClick={async () => {
                    setDrawerLoading(true);
                    setDrawerOpen(true);

                    // Need to call twice, try to fetch data from monthly and weekly tables.
                    const monthlyMetrics = priceService.getPerformanceMetrics({
                        bw: props.gridParams.row.bw,
                        so: props.gridParams.row.so,
                        monthly: true,
                    });
                    const weeklyMetrics = priceService.getPerformanceMetrics({
                        bw: props.gridParams.row.bw,
                        so: props.gridParams.row.so,
                        monthly: false,
                    });

                    await Promise.all([monthlyMetrics, weeklyMetrics])
                        .then(res => {
                            if (res) {
                                const productDetailsMapped = [
                                    {
                                        header: 'Details',
                                        footer: '',
                                        rows: [
                                            {
                                                label: 'SO',
                                                value: props.gridParams.row.so,
                                            },
                                            {
                                                label: 'BW',
                                                value: props.gridParams.row.bw,
                                            },
                                            {
                                                label: 'BW Desc',
                                                value: props.gridParams.row.bwDesc,
                                            },
                                        ],
                                    },
                                ];
                                const monthlyPerfMetricsMapped = mapMonthlyPerformanceMetrics(res[0]);
                                const weeklyPerfMetricsMapped = mapWeeklyPerformanceMetrics(res[1]);
                                setDrawerData([
                                    ...productDetailsMapped,
                                    ...weeklyPerfMetricsMapped,
                                    ...monthlyPerfMetricsMapped,
                                ]);
                            } else {
                                setDrawerData(undefined);
                            }
                        })
                        .catch(err => {
                            console.log(err); // TODO- do better
                            setDrawerData(undefined);
                        });

                    setDrawerLoading(false);
                }}
            />
        </>
    );
}
