import { Box, Stack } from '@mui/material';
import { useUser } from '@price-for-profit/auth';
import { useTitle } from '@price-for-profit/react-hooks';
import { useService } from '@price-for-profit/service-provider';
import { TitleHeader } from '@price-for-profit/shared-components';
import { useTrackPageEvent } from '@price-for-profit/user-activity-tracking';
import { useRef } from 'react';
import { Loader } from 'shared/components';
import { useCurrencyPreference } from 'shared/hooks';
import { useGetExchangeRates } from 'shared/queries';
import { DropdownOption, PriceMetrics } from 'shared/types';
import { PriceMasterTable } from './table';

interface PriceMasterPageProps {
    priceMetrics: PriceMetrics;
    salesOfficeOptions: DropdownOption[];
}

export function PriceMasterPage({ priceMetrics, salesOfficeOptions }: PriceMasterPageProps) {
    useTitle('Ryerson Price Master');

    const exportButtonContainerRef = useRef<HTMLDivElement | null>(null);
    const metricsContainerRef = useRef<HTMLDivElement | null>(null);
    const { appConfig } = useService();
    const user = useUser();
    const preferredCurrencyType = useCurrencyPreference();
    const exchangeRates = useGetExchangeRates().data;

    useTrackPageEvent({
        action: 'PriceMasterPage',
        clientId: appConfig.clientId,
        user,
    });

    if (!user || !preferredCurrencyType || !exchangeRates) {
        return <Loader />;
    }
    return (
        <Stack>
            <TitleHeader
                title={'Price Master'}
                primaryActions={
                    <Stack
                        ref={exportButtonContainerRef}
                        sx={{ height: '100%' }}
                        direction='row'
                        justifyContent='end'
                        alignItems='flex-end'
                    ></Stack>
                }
            />
            {(user.coe || user.cxm_mmgm || user.canada_procurement) && (
                <Box ref={metricsContainerRef} data-testid='MetricsContainer' />
            )}
            <Box>
                <PriceMasterTable
                    user={user}
                    metricsContainerRef={metricsContainerRef}
                    exportButtonContainerRef={exportButtonContainerRef}
                    preferredExchangeRate={exchangeRates ? exchangeRates[preferredCurrencyType] : 1.0}
                    salesOfficeOptions={salesOfficeOptions}
                    priceMetrics={priceMetrics}
                />
            </Box>
        </Stack>
    );
}
