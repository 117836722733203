import { useService } from '@price-for-profit/service-provider';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { PriceMasterExportExcelProps } from 'shared/types';

export function usePriceExcelExport() {
    const { priceService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const mutation = useMutation<void, Error, PriceMasterExportExcelProps>({
        mutationFn: priceExcelExportProps => priceService.exportPriceMaster(priceExcelExportProps),
        onSuccess: () => {
            enqueueSnackbar('Price master was successfully exported.', { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar('There was a problem exporting the price master.', { variant: 'error' });
        },
    });
    return mutation;
}
