import { MenuItem, TextField } from '@mui/material';
import { GridRenderCellParams, GridValidRowModel, useGridApiContext } from '@mui/x-data-grid-premium';
import { DropdownOption } from 'shared/types';

interface EditCellDropdownProps<T extends GridValidRowModel> {
    data: DropdownOption[];
    params: GridRenderCellParams<T, string>;
}

export function EditCellDropdown<T extends GridValidRowModel>({ data, params }: EditCellDropdownProps<T>) {
    const apiRef = useGridApiContext();

    const handleSelect = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: event.target.value });
    };

    return (
        <TextField
            id={`${params.field}-dropdown-${params.id}`}
            value={params.value}
            fullWidth={true}
            select
            onChange={handleSelect}
        >
            {data.map(option => (
                <MenuItem key={option.uniqueId} value={option.value}>
                    {option.value}
                </MenuItem>
            ))}
        </TextField>
    );
}
