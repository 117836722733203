import { CalculationPayload, CalculationResponse } from '@price-for-profit/micro-services';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import { useService } from '@price-for-profit/service-provider';

export function useCalculate<I, O>(modelId: string) {
    const { calcService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const mutation = useMutation<CalculationResponse<O>, Error, CalculationPayload<I>>({
        mutationFn: request => calcService.calculate<I, O>({ modelId, payload: request }),
        onError: (e: Error) => {
            enqueueSnackbar(`error: ${e.message}`, { variant: 'error' });
            console.log(`${e.stack}`);
        },
    });
    return mutation;
}
