import { useService } from '@price-for-profit/service-provider';
import { useSavePriceRows } from 'shared/hooks';
import { useSaveCostMasterRows } from 'shared/mutations';
import { CostSummary, PriceSummary, UpdateCostMasterParameters } from 'shared/types';

interface SaveCostRowsProps {
    user: drive.UserInfo;
}

export function useSaveCostRows({ user }: SaveCostRowsProps) {
    const savePriceChanges = useSavePriceRows({ user });
    const saveCostMutation = useSaveCostMasterRows();
    const { priceService } = useService();

    async function calculateCostRows(
        newCostRows: CostSummary[],
        oldCostRows: CostSummary[]
    ): Promise<{
        updatedCalculatedCosts: CostSummary[];
        updatedPriceRows: PriceSummary[];
        oldPriceRows: PriceSummary[];
    }> {
        const updatedPriceRows: PriceSummary[] = [];
        const updatedCalculatedCosts: CostSummary[] = [];
        const oldPriceRows: PriceSummary[] = [];

        for (const newCostRow of newCostRows) {
            const oldCostRow = oldCostRows.find(d => d.id === newCostRow.id);

            if (oldCostRow) {
                // Base / Conversion (New)
                newCostRow.baseConversionNew =
                    newCostRow.primaryBaseConversionNew * newCostRow.primaryAllocationNew +
                    newCostRow.secondaryBaseConversionNew * (1 - newCostRow.primaryAllocationNew);

                // Regional Replacement Cost (New)
                newCostRow.baseCostNew =
                    newCostRow.baseConversionNew +
                    newCostRow.surchargeIngotNew +
                    newCostRow.processingNew +
                    newCostRow.otherExtrasNew +
                    newCostRow.inboundFreightNew +
                    newCostRow.secondaryInboundFreightNew;

                // Base Cost Final
                if (newCostRow.baseCostNew !== oldCostRow.baseCostNew) {
                    newCostRow.finalBaseCost = newCostRow.baseCostNew;
                }

                // Base Cost Change
                newCostRow.baseCostChange = newCostRow.finalBaseCost / newCostRow.baseCostCurrent - 1;

                // Market Movement Change
                newCostRow.marketMovementChange = newCostRow.finalMarketMovement - newCostRow.marketMovementCurrent;

                updatedCalculatedCosts.push(newCostRow);

                const oldPrices = await priceService.getPriceCollectionFromCostMaster(newCostRow);

                for (const oldPrice of oldPrices) {
                    let newPrice: PriceSummary = {
                        baseCostCurrent: newCostRow.baseCostCurrent,
                        baseCostNew: newCostRow.baseCostNew,
                        baseCostHoldPrice: newCostRow.baseCostHoldPrice,
                        baseCostMode: newCostRow.baseCostMode,
                        finalBaseCost: newCostRow.finalBaseCost,
                        baseCostChange: newCostRow.baseCostChange,
                        baseCostLastChange: newCostRow.baseCostLastChange,
                        marketMovementCurrent: newCostRow.marketMovementCurrent,
                        marketMovementNew: newCostRow.marketMovementNew,
                        marketMovementHoldPrice: newCostRow.marketMovementHoldPrice,
                        marketMovementMode: newCostRow.marketMovementMode,
                        finalMarketMovement: newCostRow.marketMovementNew,
                        marketMovementChange: newCostRow.marketMovementChange,
                        marketMovementLastChange:
                            newCostRow.marketMovementLastChange > newCostRow.marketMovementAdHocLastChange
                                ? newCostRow.marketMovementLastChange
                                : newCostRow.marketMovementAdHocLastChange,
                        id: oldPrice.id,
                        createdBy: oldPrice.createdBy,
                        createdDate: oldPrice.createdDate,
                        bwDesc: oldPrice.bwDesc,
                        bw: oldPrice.bw,
                        itemNumber: oldPrice.itemNumber,
                        customerId: oldPrice.customerId,
                        shipToState: oldPrice.shipToState,
                        bundles: oldPrice.bundles,
                        threePc: oldPrice.threePc,
                        so: oldPrice.so,
                        multiMarket: oldPrice.multiMarket,
                        priceBook: oldPrice.priceBook,
                        product: oldPrice.product,
                        form: oldPrice.form,
                        productCategory: oldPrice.productCategory,
                        index: oldPrice.index,
                        rollingTwelveMonthsRevenue: oldPrice.rollingTwelveMonthsRevenue,
                        baseLbs: oldPrice.baseLbs,
                        baseLbsRange: oldPrice.baseLbsRange,
                        targetMarginAdj: oldPrice.targetMarginAdj,
                        targetMarginCurrent: oldPrice.targetMarginCurrent,
                        targetMarginLastChange: oldPrice.targetMarginLastChange,
                        nonMaterialCost: oldPrice.nonMaterialCost,
                        nonMaterialCostAdj: oldPrice.nonMaterialCostAdj,
                        pcpFreightCost: oldPrice.pcpFreightCost,
                        replacementCost: oldPrice.replacementCost,
                        smallOrderAdder: oldPrice.smallOrderAdder,
                        insightRecPrice: oldPrice.insightRecPrice,
                        bookPriceCurrent: oldPrice.bookPriceCurrent,
                        bookPriceLastChange: oldPrice.bookPriceLastChange,
                        bookPriceMode: oldPrice.bookPriceMode,
                        floorPriceCurrent: oldPrice.floorPriceCurrent,
                        floorPriceNew: oldPrice.floorPriceNew,
                        floorMarginCurrent: oldPrice.floorMarginCurrent,
                        floorMarginNew: oldPrice.floorMarginNew,
                        bookPriceNew: oldPrice.bookPriceNew,
                        targetMarginNew: oldPrice.targetMarginNew,
                        floorMarginAllInNew: oldPrice.floorMarginAllInNew,
                        floorMarginAllInCurrent: oldPrice.floorMarginAllInCurrent,
                        finalFloorMarginAllIn: oldPrice.finalFloorMarginAllIn,
                        changeCount: oldPrice.changeCount,
                        finalBookPrice: oldPrice.finalBookPrice,
                        finalFloorPrice: oldPrice.finalFloorPrice,
                        floorVsBookPriceDiff: oldPrice.floorVsBookPriceDiff,
                        finalFloorMargin: oldPrice.finalFloorMargin,
                        finalTargetMargin: oldPrice.finalTargetMargin,
                        marketPriceRecommendation: oldPrice.marketPriceRecommendation,
                        updatedBy: oldPrice.updatedBy,
                        updatedByEmail: oldPrice.updatedByEmail,
                        updatedDate: oldPrice.updatedDate,
                        bookPriceChange: oldPrice.bookPriceChange,
                        floorPriceChange: oldPrice.floorPriceChange,
                        targetMarginChange: oldPrice.targetMarginChange,
                        targetMarginMaxChangeWarning: oldPrice.targetMarginMaxChangeWarning,
                        targetMarginMinWarning: oldPrice.targetMarginMinWarning,
                        bookPriceMaxChangeWarning: oldPrice.bookPriceMaxChangeWarning,
                        floorPriceMinWarning: oldPrice.floorPriceMinWarning,
                        targetMarginNewBelowFloorMarginNewWarning: oldPrice.targetMarginNewBelowFloorMarginNewWarning,
                        shipToZipCode: oldPrice.shipToZipCode,
                        warnings: '',
                        marketPriceRecommendationWarning: '',
                        deletedDate: oldPrice.deletedDate,
                        revenueTier: oldPrice.revenueTier,
                        marginModelAutoload: oldPrice.marginModelAutoload,
                        marketTmRecommendation: oldPrice.marketTmRecommendation,
                        previousMarketPriceRecommendation: oldPrice.previousMarketPriceRecommendation,
                        previousMarketTMRecommendation: oldPrice.previousMarketTMRecommendation,
                    };

                    updatedPriceRows.push(newPrice);
                    oldPriceRows.push(...oldPrices);
                }
            }
        }

        return {
            updatedCalculatedCosts,
            updatedPriceRows,
            oldPriceRows,
        };
    }

    const saveCostChanges = async (newCostRows: CostSummary[], oldCostRows: CostSummary[]) => {
        const { updatedCalculatedCosts, updatedPriceRows, oldPriceRows } = await calculateCostRows(
            newCostRows,
            oldCostRows
        );
        const calculatedAndEditedCostRows: UpdateCostMasterParameters = {
            newRows: updatedCalculatedCosts,
            oldRows: oldCostRows,
            user,
        };
        await saveCostMutation.mutateAsync(calculatedAndEditedCostRows);

        await savePriceChanges(updatedPriceRows, oldPriceRows);
    };

    return saveCostChanges;
}
