import { useService } from '@price-for-profit/service-provider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERYKEY_COST_FUTURE_RATE, QUERYKEY_COST_FUTURE_RATE_METADATA } from 'shared/constants';
import { DataAccessResponse } from '@price-for-profit/micro-services';

export function useDeleteCostMasterFutureRateRows() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { costService } = useService();

    const mutation = useMutation<DataAccessResponse<string>, Error, string>({
        mutationFn: request => costService.deleteCostFutureMetadataAndRates(request),
        onSuccess: () => {
            enqueueSnackbar('Future rates discarded', { variant: 'success' });
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST_FUTURE_RATE] });
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST_FUTURE_RATE_METADATA] });
        },
        onError: () => {
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST_FUTURE_RATE] });
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST_FUTURE_RATE_METADATA] });
            enqueueSnackbar('Cost fields failed to calculate and save', { variant: 'error' });
        },
    });

    return mutation;
}
