import { Box, Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { useTitle } from '@price-for-profit/react-hooks';
import { useService } from '@price-for-profit/service-provider';
import { TitleHeader } from '@price-for-profit/shared-components';
import { useTrackPageEvent } from '@price-for-profit/user-activity-tracking';
import { useRef } from 'react';
import { Loader } from 'shared/components';
import { useCurrencyPreference } from 'shared/hooks';
import { useGetExchangeRates } from 'shared/queries';
import { CostMetrics } from 'shared/types';
import { CostMasterMetrics } from './metrics';
import { CostMasterTable } from './table';

interface CostMasterPageProps {
    costMetrics: CostMetrics;
    user: drive.UserInfo;
}

export function CostMasterPage({ user, costMetrics }: CostMasterPageProps) {
    useTitle('Ryerson Cost Master');
    const preferredCurrencyType = useCurrencyPreference();
    const exchangeRates = useGetExchangeRates().data;
    const apiRef = useGridApiRef();
    const exportButtonContainerRef = useRef<HTMLDivElement | null>(null);
    const { appConfig } = useService();

    useTrackPageEvent({
        action: 'CostMasterPage',
        clientId: appConfig.clientId,
        user,
    });

    if (!preferredCurrencyType || !exchangeRates) {
        return <Loader />;
    }

    return (
        <Stack>
            <TitleHeader
                title={'Cost Master'}
                primaryActions={
                    <Stack
                        ref={exportButtonContainerRef}
                        sx={{ height: '100%' }}
                        direction='row'
                        justifyContent='end'
                        alignItems='flex-end'
                    />
                }
            />
            <Box>
                <CostMasterMetrics user={user} apiRef={apiRef} costMetrics={costMetrics} />
            </Box>
            <Box>
                <CostMasterTable
                    user={user}
                    apiRef={apiRef}
                    exportButtonContainerRef={exportButtonContainerRef}
                    preferredExchangeRate={exchangeRates ? exchangeRates[preferredCurrencyType] : 1.0}
                />
            </Box>
        </Stack>
    );
}
