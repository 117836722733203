import { GridEditInputCell } from '@mui/x-data-grid-premium';
import {
    InsightGridColDef,
    getLimitedGridNumericOperators,
    getLimitedGridStringOperators,
} from '@price-for-profit/data-grid';
import { Surcharge } from 'shared/types';
import {
    ColumnFormatterExchangeRate,
    ColumnFormatterMoneyDecimals,
    ColumnSetterExchangeRate,
    PriceColumn,
    formatDecimal,
} from 'shared/utility';

interface SurchargeMasterColumnsProps {
    user: drive.UserInfo;
    exchangeRate: number;
}

export function SurchargeMasterColumns({
    user,
    exchangeRate,
}: SurchargeMasterColumnsProps): InsightGridColDef<Surcharge>[] {
    const editable = user.coe || user.procurement || user.canada_procurement;

    return [
        {
            field: 'surcharge',
            headerName: 'Surcharge',
            flex: 1,
            minWidth: 50,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'surchargeDescription',
            headerName: 'Surcharge Description',
            flex: 1,
            minWidth: 50,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'surchargeValueCurrent',
            headerName: 'Surcharge Value - CWT (Current)',
            minWidth: 50,
            width: 250,
            type: 'number',
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
        },
        {
            field: 'surchargeValueNew',
            headerName: 'Surcharge Value - CWT (New)',
            minWidth: 50,
            width: 250,
            type: 'number',
            editable: editable,
            valueSetter: (value, row) => ColumnSetterExchangeRate(value, row, exchangeRate, 'surchargeValueNew'),
            valueGetter: value => ColumnFormatterExchangeRate(value, exchangeRate),
            valueFormatter: value => ColumnFormatterMoneyDecimals(value, 2),
            filterOperators: getLimitedGridNumericOperators(),
            cellClassName: ({ row }) =>
                formatDecimal(row.surchargeValueCurrent, 2, 2) !== formatDecimal(row.surchargeValueNew, 2, 2)
                    ? 'values-diff'
                    : '',
            renderEditCell: params => <PriceColumn {...params} />,
        },
        {
            field: 'product',
            headerName: 'Product',
            flex: 1,
            minWidth: 50,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'form',
            headerName: 'Form',
            flex: 1,
            minWidth: 50,
            filterOperators: getLimitedGridStringOperators(),
        },
        {
            field: 'notes',
            headerName: 'Notes',
            flex: 1,
            minWidth: 50,
            type: 'string',
            editable: editable,
            filterOperators: getLimitedGridStringOperators(),
            renderEditCell: params => (
                <GridEditInputCell
                    {...params}
                    inputProps={{
                        maxLength: 300,
                    }}
                />
            ),
        },
    ];
}
