import { PriceMasterPage } from './page';
import { Loader } from 'shared/components/loader/loader';
import { useGetPriceMetrics } from 'shared/queries';
import { useGetSalesOfficeOptions } from 'shared/queries';

export function PriceMasterResolver() {
    const { priceMetrics } = useGetPriceMetrics();
    const { data } = useGetSalesOfficeOptions();

    if (priceMetrics === undefined || !data) {
        return <Loader />;
    }
    return <PriceMasterPage priceMetrics={priceMetrics} salesOfficeOptions={data} />;
}
