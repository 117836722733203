import { CalculationPayload } from '@price-for-profit/micro-services';
import { PriceSummary } from 'shared/types';
import { CalculateInput } from 'shared/types/model';

export const buildModelPayload = (
    newRow: PriceSummary,
    clCodeMatrixMode = false
): CalculationPayload<CalculateInput> => {
    return {
        modelInputs: {
            customerId: newRow.customerId,
            shipToZipCode: newRow.shipToZipCode,
            shipToState: newRow.shipToState,
            salesOffice: newRow.so,
            pricemasterflag: 'False',
            quoteLines: [
                {
                    material: newRow.bw,
                    itemNumber: newRow.itemNumber,
                    shipPlant: newRow.so,
                    stockPlant: newRow.so,
                    weight: newRow.baseLbs.toString(),
                    bundles: newRow.bundles.toString(),
                    pmtTargetMarginCurrent: newRow.targetMarginCurrent.toString(),
                    pmttargetmarginnew: newRow.targetMarginNew.toString(),
                    pmtBaseCostCurrentLB: newRow.baseCostCurrent.toString(),
                    pmtbasecostnewlb: newRow.baseCostNew.toString(),
                    pmtbasecostholdprice: newRow.baseCostHoldPrice.toString(),
                    pmtMarketMovementCurrentLB: newRow.marketMovementCurrent.toString(),
                    pmtmarketmovementnewlb: newRow.marketMovementNew.toString(),
                    pmtmarketmovementholdprice: newRow.marketMovementHoldPrice.toString(),
                    pmtBookPriceCurrent: newRow.bookPriceCurrent.toString(),
                    pmtbookpricenew: newRow.bookPriceNew.toString(),
                },
            ],
            ...(clCodeMatrixMode && { clcodematrixmode: 'final' }), // Append field if requested
        },
    };
};
