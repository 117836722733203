export const DATASTORE_SQL = 'sql_db' as const;

export const TABLE_COST_MASTER = 'CostMaster' as const;
export const TABLE_PRICE_MASTER = 'PriceMaster' as const;
export const TABLE_EXCHANGE_RATES = 'ExchangeRateLookup' as const;
export const TABLE_MONTHLY_PERFORMANCE_METRIC = 'MonthlyPerformanceMetric' as const;
export const TABLE_SURCHARGE_MASTER = 'SurchargeMaster' as const;
export const TABLE_WEEKLY_PERFORMANCE_METRIC = 'WeeklyPerformanceMetric' as const;
export const TABLE_SUBMISSION_HISTORY = 'SubmissionHistory' as const;
export const TABLE_WARNING_CONFIGURATION = 'WarningConfiguration' as const;
export const TABLE_HISTORY_METADATA = 'HistoryMetadata' as const;
export const TABLE_PRICE_MASTER_CLCODE_PRICES = 'PriceMasterClCodePrices' as const;
export const TABLE_COST_MASTER_FUTURE_RATES = 'CostMasterFutureRates' as const;
export const VIEW_BOOK_PRICE_HISTORY_SUMMARY = 'BookPriceHistorySummary' as const;
export const VIEW_COST_MASTER_SUMMARY = 'CostMasterSummary' as const;
export const VIEW_COST_MASTER_FUTURE_RATE_SUMMARY = 'CostMasterFutureRatesSummary' as const;
export const TABLE_COST_MASTER_FUTURE_RATE_METADATA = 'CostMasterFutureRatesMetadata' as const;
export const VIEW_PRICE_MASTER_SUMMARY = 'PriceMasterSummary' as const;
export const VIEW_COST_MASTER_WARNING_SUMMARY = 'CostMasterWarningSummary' as const;
export const VIEW_PRICE_MASTER_WARNING_SUMMARY = 'PriceMasterWarningSummary' as const;
export const VIEW_SALES_OFFICE_OPTIONS = 'SalesOfficeOptions' as const;
export const TABLE_MILL_SUPPLIER_NAMES = 'MillSupplierNames' as const;

export const SP_KEY_SUBMIT_WORKING_PRICE_MASTER_SUMMARY = 'SubmitWorkingPriceMasterSummary' as const;
export const SP_KEY_COST_MASTER_MASS_ACTION = 'CostMasterMassAction' as const;
export const SP_KEY_PRICE_MASTER_MASS_ACTION = 'PriceMasterMassAction' as const;
export const SP_KEY_CREATE_COST_MASTER_FUTURE_RATE = 'CreateCostMasterFutureRate' as const;
export const SP_KEY_APPLY_COST_MASTER_FUTURE_RATE = 'ApplyCostMasterFutureRate' as const;

export const PRODUCT_BATCH_SIZE = 10 as const;
