import { useCalculate, useSavePriceMasterRows } from 'shared/mutations';
import { PriceSummary, UpdatePriceMasterParameters } from 'shared/types';
import { CalculateInput, ResponseResult } from 'shared/types/model';
import { buildModelPayload } from 'shared/utility';
import { calculatePriceChangeCount } from 'shared/utility/calc-change-count';

interface SavePriceRowsProps {
    user: drive.UserInfo;
}

export function useSavePriceRows({ user }: SavePriceRowsProps) {
    const mutation = useSavePriceMasterRows();
    const calcMutation = useCalculate<CalculateInput, ResponseResult>('recommendedPrice');

    async function calculatePriceRows(newPriceRows: PriceSummary[], oldPriceRows: PriceSummary[]) {
        let updatedCalculatedPrices: PriceSummary[] = [];
        for (const editedPriceRow of newPriceRows) {
            const newPrice = { ...editedPriceRow };
            const oldPrice = oldPriceRows.find(d => d.id === newPrice.id);
            if (oldPrice) {
                const priceInput = buildModelPayload(newPrice);
                const calculatedPrice = await calcMutation.mutateAsync(priceInput);

                // Book Price Final
                newPrice.finalBookPrice = calculatedPrice.data.quoteLines[0].pmtBookPriceFinal;

                // Book Price Change
                newPrice.bookPriceChange = newPrice.finalBookPrice / newPrice.bookPriceCurrent - 1;

                // Target Margin Final
                newPrice.finalTargetMargin = calculatedPrice.data.quoteLines[0].pmtTargetMarginFinal;
                // Target Margin Change
                newPrice.targetMarginChange = newPrice.finalTargetMargin / newPrice.targetMarginCurrent - 1;

                // Floor Price Final
                if (newPrice.floorPriceNew !== newPrice.floorPriceCurrent) {
                    newPrice.finalFloorPrice = newPrice.floorPriceNew;
                } else {
                    newPrice.finalFloorPrice = newPrice.floorPriceCurrent;
                }
                // Floor Price Change
                newPrice.floorPriceChange = newPrice.finalFloorPrice / newPrice.floorPriceCurrent - 1;

                // Floor vs Book Price % Diff
                newPrice.floorVsBookPriceDiff =
                    newPrice.finalBookPrice !== 0 ? newPrice.finalFloorPrice / newPrice.finalBookPrice : 0;

                // Floor Margin Final
                if (newPrice.floorMarginNew !== newPrice.floorMarginCurrent) {
                    newPrice.finalFloorMargin = newPrice.floorMarginNew;
                } else {
                    newPrice.finalFloorMargin = newPrice.floorMarginCurrent;
                }

                // Floor Margin All In Final
                if (newPrice.floorMarginAllInNew !== newPrice.floorMarginAllInCurrent) {
                    newPrice.finalFloorMarginAllIn = newPrice.floorMarginAllInNew;
                } else {
                    newPrice.finalFloorMarginAllIn = newPrice.floorMarginAllInCurrent;
                }

                // Change Count
                newPrice.changeCount = calculatePriceChangeCount(newPrice);

                updatedCalculatedPrices.push(newPrice);
            }
        }
        return updatedCalculatedPrices;
    }

    const savePriceChanges = async (newPriceRows: PriceSummary[], oldPriceRows: PriceSummary[]) => {
        const newRows = await calculatePriceRows(newPriceRows, oldPriceRows);
        const calculatedAndEditedRows: UpdatePriceMasterParameters = {
            newRows,
            oldRows: oldPriceRows,
            user,
        };
        await mutation.mutateAsync(calculatedAndEditedRows);
    };

    return savePriceChanges;
}
