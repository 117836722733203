import { Box, Stack } from '@mui/material';
import { TitleHeader } from '@price-for-profit/shared-components';
import { useTitle } from '@price-for-profit/react-hooks';
import { WarningConfigurationTable } from 'pages/warning-configuration';

interface WarningConfigurationPageProps {
    user: drive.UserInfo;
}

export function WarningConfigurationPage({ user }: WarningConfigurationPageProps) {
    useTitle('Ryerson Warning Threshold Configuration');
    return (
        <Stack>
            <TitleHeader title={'Warning Threshold Configuration'} />
            <Box>
                <WarningConfigurationTable user={user} />
            </Box>
        </Stack>
    );
}
