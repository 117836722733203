import { useUser } from '@price-for-profit/auth';
import { Loader } from 'shared/components/loader/loader';
import { WarningConfigurationPage } from './page';

export function WarningConfigurationResolver() {
    const user = useUser();

    if (!user) {
        return <Loader />;
    }

    return <WarningConfigurationPage user={user} />;
}
