import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERYKEY_COST, QUERYKEY_COST_WARNING_SUMMARY } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';
import { CostMasterMassActionRequest } from 'shared/types';
import { setMassActionCorrelationId } from 'shared/utility';

export function useMassActionCostMaster() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { costService } = useService();

    const mutation = useMutation<void, Error, CostMasterMassActionRequest>({
        mutationFn: request => costService.massAction(request),
        onSuccess: () => {
            enqueueSnackbar('Cost fields will take some time to update, please grab a cup of coffee...', {
                variant: 'info',
            });
        },
        onError: () => {
            enqueueSnackbar('Cost fields failed to save', { variant: 'error' });
            setMassActionCorrelationId('');
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST] });
            queryClient.invalidateQueries({ queryKey: [QUERYKEY_COST_WARNING_SUMMARY] });
        },
    });

    return mutation;
}
