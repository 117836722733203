import { Stack, Switch, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { setCurrencyPreference, useCurrencyPreference } from 'shared/hooks/use-currency-preference';
import { ExchangeRate } from 'shared/types';

interface CurrencyToggleProps {
    setCurrency: Function;
    rates: ExchangeRate | undefined;
}

export function CurrencyToggle(props: CurrencyToggleProps) {
    const currencyPref = useCurrencyPreference();
    const [checked, setChecked] = useState(currencyPref === 'cad');

    const handleChange = (e: ChangeEvent, checked: boolean) => {
        let currency: 'cad' | 'usd' = checked ? 'cad' : 'usd';
        setCurrencyPreference(currency);
        props.setCurrency(currency);
        setChecked(currency === 'cad');
    };

    return (
        <Stack direction='row' spacing={1} alignItems='center' paddingLeft={1}>
            <Typography>USD</Typography>
            <Switch data-testid='currency-toggle' checked={checked} onChange={handleChange} color='warning' />
            <Typography>CAD ({props.rates?.cad})</Typography>
        </Stack>
    );
}
