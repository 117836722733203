import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { Loader } from '../loader';
import { formatExchangeRate } from 'shared/utility';
import { PriceMasterClCodeWeightPrices } from 'shared/types';
import React from 'react';

export interface CLPriceSideDrawerTableProps {
    currentTableData: PriceMasterClCodeWeightPrices[]; // Represents 'Current' CL WC Prices
    finalTableData: PriceMasterClCodeWeightPrices[]; // Represents 'Current' CL WC Prices
    sharedMetrics: {
        floorCurrent: number;
        floorFinal: number;
        exchangeRate: number;
        decimalPlaces: number;
    };
    loading?: boolean;
}

const headerStyle = {
    backgroundColor: '#FEF6ED',
    borderBottom: '1px solid lightgray',
    color: '#F08B1D',
    fontSize: '1.1em',
    padding: '10px 0px',
    textAlign: 'center',
};

const alertRowStyle = {
    backgroundColor: '#FEF6ED',
    color: '#F08B1D',
};

const showAlert = (rowData: PriceMasterClCodeWeightPrices, floorCurrent: number, floorFinal: number) => {
    return (
        rowData.recPriceWC1 < floorCurrent ||
        rowData.recPriceWC200 < floorCurrent ||
        rowData.recPriceWC500 < floorCurrent ||
        rowData.recPriceWC1000 < floorCurrent ||
        rowData.recPriceWC2000 < floorCurrent ||
        rowData.recPriceWC5000 < floorCurrent ||
        rowData.recPriceWC6500 < floorCurrent ||
        rowData.recPriceWC10000 < floorCurrent ||
        rowData.recPriceWC20000 < floorCurrent ||
        rowData.recPriceWC24000 < floorCurrent ||
        rowData.recPriceWC40000 < floorCurrent
    );
};

export function CLPriceSideDrawerTable({
    currentTableData,
    finalTableData,
    sharedMetrics,
    loading,
}: CLPriceSideDrawerTableProps) {
    return (
        <>
            {loading ? (
                <Stack justifyContent='center' sx={{ height: '100vh', minWidth: 500 }}>
                    <Loader />
                </Stack>
            ) : currentTableData ? (
                <Box style={{ padding: 20 }}>
                    <Box sx={headerStyle}>Pricing Matrix Order Size Bucket</Box>
                    <Table
                        size='small'
                        sx={{
                            '& .MuiTableCell-head': {
                                fontWeight: 'bold',
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow key={'floorPrice'}>
                                <TableCell>{'Floor Price'}</TableCell>
                                <TableCell>
                                    {formatExchangeRate(
                                        sharedMetrics.floorCurrent,
                                        sharedMetrics.exchangeRate,
                                        sharedMetrics.decimalPlaces
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Weight Class</TableCell>
                                <TableCell colSpan={2}>1 to 200</TableCell>
                                <TableCell colSpan={2}>200 to 500</TableCell>
                                <TableCell colSpan={2}>500 to 1000</TableCell>
                                <TableCell colSpan={2}>1000 to 2000</TableCell>
                                <TableCell colSpan={2}>2000 to 5000</TableCell>
                                <TableCell colSpan={2}>5000 to 6500</TableCell>
                                <TableCell colSpan={2}>6500 to 10000</TableCell>
                                <TableCell colSpan={2}>10000 to 20000</TableCell>
                                <TableCell colSpan={2}>20000 to 24000</TableCell>
                                <TableCell colSpan={2}>24000 to 40000</TableCell>
                                <TableCell colSpan={2}>&gt; 40000</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>CL Code</TableCell>
                                {[...Array(11)].map((e, i) =>
                                <React.Fragment key={i}>
                                    <TableCell>Current</TableCell>
                                    <TableCell>Final</TableCell>
                                </React.Fragment>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentTableData.map((rowData, i) => {
                                return (
                                    <TableRow
                                        key={rowData.uniqueid}
                                        sx={
                                            showAlert(rowData, sharedMetrics.floorCurrent, sharedMetrics.floorFinal)
                                                ? alertRowStyle
                                                : null
                                        }
                                    >
                                        <TableCell align='center'>{rowData.clCode}</TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                currentTableData[i].recPriceWC1,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                finalTableData[i].recPriceWC1,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                currentTableData[i].recPriceWC200,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                finalTableData[i].recPriceWC200,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                currentTableData[i].recPriceWC500,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                finalTableData[i].recPriceWC500,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                currentTableData[i].recPriceWC1000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                finalTableData[i].recPriceWC1000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                currentTableData[i].recPriceWC2000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                finalTableData[i].recPriceWC2000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                currentTableData[i].recPriceWC5000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                finalTableData[i].recPriceWC5000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                currentTableData[i].recPriceWC6500,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                finalTableData[i].recPriceWC6500,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                currentTableData[i].recPriceWC10000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                finalTableData[i].recPriceWC10000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                currentTableData[i].recPriceWC20000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                finalTableData[i].recPriceWC20000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                currentTableData[i].recPriceWC24000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                finalTableData[i].recPriceWC24000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                currentTableData[i].recPriceWC40000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {formatExchangeRate(
                                                finalTableData[i].recPriceWC40000,
                                                sharedMetrics.exchangeRate,
                                                sharedMetrics.decimalPlaces
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Box>
            ) : (
                <Box sx={{ padding: 40 }}>Information not found</Box>
            )}
        </>
    );
}
