import { InfoOutlined } from '@mui/icons-material';
import { Box, Drawer, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useService } from '@price-for-profit/service-provider';
import { useState } from 'react';
import { SideDrawerTable, SideDrawerTableData } from 'shared/components';
import { formatExchangeRate } from 'shared/utility';
import { mapMultiMarketProducts } from 'shared/utility/side-drawer-maps';

interface SalesOfficeColProps {
    gridParams: GridRenderCellParams;
    exchangeRate: number;
}

export function SalesOfficeCol(props: SalesOfficeColProps) {
    const { priceService } = useService();
    const gridParams = props.gridParams as GridRenderCellParams;
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [drawerData, setDrawerData] = useState<SideDrawerTableData[]>();
    const [drawerLoading, setDrawerLoading] = useState<boolean>(false);

    return (
        <>
            <Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <SideDrawerTable tableData={drawerData} loading={drawerLoading} />
            </Drawer>
            <Stack flexDirection='row' alignItems='center'>
                <InfoOutlined
                    sx={{ color: 'darkgray', marginRight: 1, cursor: 'pointer' }}
                    onClick={async () => {
                        setDrawerLoading(true);
                        setDrawerOpen(true);

                        await priceService
                            .getProductsForMultimarket({
                                bw: props.gridParams.row.bw,
                                multiMarket: props.gridParams.row.multiMarket,
                            })
                            .then(res => {
                                if (res) {
                                    const multiMarketProductsMapped = mapMultiMarketProducts(
                                        res,
                                        props.exchangeRate,
                                        formatExchangeRate
                                    );
                                    setDrawerData(multiMarketProductsMapped);
                                } else {
                                    setDrawerData(undefined);
                                }
                            })
                            .catch(() => setDrawerData(undefined));

                        setDrawerLoading(false);
                    }}
                />
                <Box mt='1px'>{gridParams.value}</Box>
            </Stack>
        </>
    );
}
